/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Colors,
	Dropdown,
	Flex,
	Pill,
	presets,
	Spacings,
	Text,
	TextInput,
	Tooltip,
} from '@steadybit/ui-components-lib';
import { ReactElement, useMemo, useState } from 'react';
import { Skeletons, Stack } from 'components';
import { IconTeam } from 'components/icons';
import { includes } from 'utils/string';
import { TeamSummaryVO } from 'ui-api';
import { useField } from 'formik';

import { DataStreamResult } from '../../../../utils/hooks/stream/result';

interface SidebarTeamsModuleProps {
	addFakeAdminTeam?: boolean;
	teams: DataStreamResult<TeamSummaryVO[]>;
}

export default function SidebarTeamsModule({ addFakeAdminTeam, teams }: SidebarTeamsModuleProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<string[]>('teamIds');

	return (
		<SidebarTeamsModuleRenderer
			addFakeAdminTeam={addFakeAdminTeam}
			teams={teams.value ?? []}
			addTeam={(teamId) => {
				setValue([...field.value, teamId]);
				setTouched(true);
			}}
			removeTeam={(teamId) => {
				setValue(field.value.filter((t) => t !== teamId));
				setTouched(true);
			}}
			addedTeamIds={field.value}
			isLoading={teams.loading}
		/>
	);
}

interface SidebarTeamsModuleRendererProps {
	removeTeam: (teamId: string) => void;
	addTeam: (teamId: string) => void;
	addFakeAdminTeam?: boolean;
	teams: TeamSummaryVO[];
	addedTeamIds: string[];
	isLoading?: boolean;
	disabled?: boolean;
}

function SidebarTeamsModuleRenderer({
	addFakeAdminTeam,
	addedTeamIds,
	removeTeam,
	isLoading,
	disabled,
	addTeam,
	teams,
}: SidebarTeamsModuleRendererProps): ReactElement {
	const [searchterm, setSearchterm] = useState('');

	const adminTeamId = useMemo(() => {
		if (!isLoading && teams) {
			const admTeam = teams.find((t) => t.key === 'ADM');
			if (admTeam) {
				return admTeam.id;
			}
		}
		return undefined;
	}, [teams]);

	const selectedTeams: Record<string, string> = useMemo(() => {
		const result: Record<string, string> = {};
		// The admin team is added in the backend, when saving the environment,
		// so we need to add it here as well on not yet saved environments
		if (adminTeamId && addFakeAdminTeam) {
			result[adminTeamId] = 'Administrators';
		}
		if (!isLoading && teams) {
			teams
				.filter((t) => addedTeamIds.indexOf(t.id) >= 0)
				.forEach((t) => {
					result[t.id] = t.name;
				});
		}
		return result;
	}, [addedTeamIds, teams]);

	const teamsOptions = useMemo(() => {
		if (!isLoading && teams) {
			return teams
				.filter((t) => t.key !== 'ADM')
				.filter((t) => addedTeamIds.indexOf(t.id) < 0)
				.filter((t) => includes(t.name, searchterm))
				.map((t) => ({
					id: t.id,
					label: t.name,
				}));
		}
		return [];
	}, [teams, searchterm, addedTeamIds]);

	return (
		<Stack>
			<Stack size="xxSmall">
				<Stack direction="horizontal" size="xxSmall">
					<IconTeam />
					<Text type="mediumStrong">Add Teams</Text>
				</Stack>
				<Text type="small">
					Add the Teams that will be able to do Experiments within the scope of this Environment.
				</Text>
			</Stack>

			<Flex spacing="xSmall">
				{disabled ? null : isLoading ? (
					<Skeletons height={34} widths={['100%']} />
				) : (
					<Dropdown<HTMLInputElement>
						renderDropdownContent={({ width, close }) =>
							teamsOptions.length === 0 ? null : (
								<presets.dropdown.DropdownContentFrame
									width={width}
									maxHeight="400px"
									style={{
										padding: Spacings.xSmall,
									}}
								>
									<Flex direction="horizontal" wrap spacing="xxSmall">
										{teamsOptions.map((team) => (
											<presets.pill.Tag
												key={team.id}
												onClick={() => {
													addTeam(team.id);
													close();
												}}
											>
												{team.label}
											</presets.pill.Tag>
										))}
									</Flex>
								</presets.dropdown.DropdownContentFrame>
							)
						}
					>
						{({ setRefElement, isOpen, setOpen }) => {
							return (
								<TextInput
									ref={setRefElement}
									syntheticFocussed={isOpen}
									placeholder="Select teams"
									withLeftIcon="search"
									value={searchterm}
									onClear={() => setSearchterm('')}
									onClick={() => setOpen(!isOpen)}
									onChange={setSearchterm}
								/>
							);
						}}
					</Dropdown>
				)}

				<Flex direction="horizontal" wrap spacing="xxSmall">
					{Object.keys(selectedTeams).map((teamId) =>
						teamId === adminTeamId ? (
							<AdminTag key={teamId} />
						) : (
							<presets.pill.Tag
								key={teamId}
								small
								onDelete={!disabled && teamId !== adminTeamId ? () => removeTeam(teamId) : undefined}
							>
								{selectedTeams[teamId]}
							</presets.pill.Tag>
						),
					)}
				</Flex>
			</Flex>
		</Stack>
	);
}

function AdminTag(): ReactElement {
	return (
		<Tooltip<HTMLDivElement> content="The administrator team always has access to all environments and cannot be removed.">
			{({ setRefElement }) => (
				<Pill
					ref={setRefElement}
					style={{
						backgroundColor: Colors.neutral100,
						height: '20px',
						borderRadius: 'xxSmall',
					}}
				>
					<Text type="xSmallStrong" style={{ color: Colors.neutral500 }}>
						Administrator
					</Text>
				</Pill>
			)}
		</Tooltip>
	);
}
