/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, presets } from '@steadybit/ui-components-lib';
import { TextField } from 'components';
import { ReactElement } from 'react';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface OptionType {
	label: string;
	value: string;
}

interface StringOptionsProps {
	optionsOnly?: boolean;
	options: OptionType[];
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function StringOptions({
	optionsOnly = false,
	hasErrors,
	disabled,
	options,
	value,
	setValue,
}: StringOptionsProps): ReactElement {
	const resolvedOption = options.find((option) => option.value === value);
	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<presets.dropdown.SingleChoiceList
						items={options.map(({ label, value }) => ({
							id: value,
							label,
							isSelected: value === resolvedOption?.value,
						}))}
						onSelect={(id) => {
							setValue(id);
							close();
						}}
					/>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(id) => {
							setValue(id);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				let component;
				if (!isOpen || optionsOnly) {
					component = (
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setOpen(true)}
						/>
					);
				} else {
					component = (
						<TextField
							value={value}
							type="text"
							onChange={(e) => setValue(e.target.value)}
							hasError={hasErrors}
							disabled={disabled}
							autoFocus
							height="40px"
						/>
					);
				}
				return (
					<FocussableWrapper ref={setRefElement} onFocus={() => setOpen(true)} onBlur={() => {}} disabled={disabled}>
						{component}
					</FocussableWrapper>
				);
			}}
		</Dropdown>
	);
}
