/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
} from 'pages/templates/UseTemplateModal/types';
import { UseTemplateFormData } from 'pages/templates/UseTemplateModal/UseTemplateForm';
import { ActionVO, BaseExperimentStepVOUnion, OccuranceVO } from 'ui-api';
import { TemplateFormValues } from 'pages/templates/TemplateEditor/types';
import { Label, getStepStyle } from 'pages/experimentsV2/Workspace/Step';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { findStep } from 'pages/experimentsV2/utils';
import { ActionIcon } from 'hocs/ActionIcon';
import { useFormikContext } from 'formik';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import { Icon } from '../../FromTemplateModal/TemplateDetails/common';
import ActionParameters from './ActionParameters';
import WaitParameters from './WaitParameters';

interface StepsPropsCommon {
	actionsResult: DataStreamResult<ActionVO[]>;
	steps: BaseExperimentStepVOUnion[];
	environmentVariable?: string;
	errorneousSteps: Set<string>;
	occurances: OccuranceVO[];
}
interface StepsPropsWithEnvironmentVariable extends StepsPropsCommon {
	environmentVariable: string;
}

export default function Steps({
	environmentVariable,
	errorneousSteps,
	actionsResult,
	occurances,
	steps,
}: StepsPropsCommon | StepsPropsWithEnvironmentVariable): ReactElement {
	return (
		<>
			{steps.map((step) =>
				actionsResult.value ? (
					<Step
						key={step.id}
						step={step}
						actions={actionsResult.value}
						hasErrors={errorneousSteps.has(step.id)}
						environmentVariable={environmentVariable}
						stepOccurances={occurances.filter(
							(o) =>
								(isOccuranceActionPredicateVO(o) || isOccuranceStepLabelVO(o) || isOccuranceStepParameterVO(o)) &&
								o.stepId === step.id,
						)}
					/>
				) : (
					<>loading</>
				),
			)}
		</>
	);
}

interface StepProps {
	step: BaseExperimentStepVOUnion;
	stepOccurances: OccuranceVO[];
	environmentVariable?: string;
	hasErrors: boolean;
	actions: ActionVO[];
}

function Step({ step, hasErrors, environmentVariable, stepOccurances, actions }: StepProps): ReactElement {
	const style = getStepStyle(step, false, false, actions);
	const action = step.type === 'action' ? actions.find((action) => action.id === step.actionId) : null;

	const {
		values: { lanes },
	} = useFormikContext<UseTemplateFormData | TemplateFormValues>();
	const [, stepPath] = findStep(lanes, step.id);

	return (
		<Collapsible
			key={step.id}
			title={
				<Stack direction="horizontal" alignItems="center">
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '32px',
							height: '32px',
							borderRadius: '4px',
							backgroundColor: style.backgroundColor,
						}}
					>
						<StepIcon type={step.type} action={action} />
					</div>

					<Stack size="none">
						<Stack direction="horizontal" size="xxSmall" alignItems="center">
							{action?.kind && (
								<Text variant="xSmall" color="neutral700" sx={{ textTransform: 'capitalize' }}>
									{action?.kind} /
								</Text>
							)}
							<Text variant="xSmall" color="neutral700">
								{action ? action.name : step.type}
							</Text>
						</Stack>
						<Label step={step} />
					</Stack>
				</Stack>
			}
			backgroundColor="neutral000"
			borderColor={hasErrors ? theme.colors.experimentWarning : undefined}
			borderColorExpanded={hasErrors ? theme.colors.experimentWarning : undefined}
			backgroundColorExpanded="neutral000"
		>
			<ErrorBoundary>
				{step.type === 'action' ? (
					<ActionParameters
						key={step.id}
						environmentVariable={environmentVariable}
						stepOccurances={stepOccurances}
						stepPath={stepPath}
						actionStep={step}
					/>
				) : (
					<WaitParameters
						key={step.id}
						environmentVariable={environmentVariable}
						stepOccurances={stepOccurances}
						stepPath={stepPath}
						waitStep={step}
					/>
				)}
			</ErrorBoundary>
		</Collapsible>
	);
}

function StepIcon({ action, type }: { type: 'action' | 'wait'; action: ActionVO | null | undefined }): ReactElement {
	const style = {
		minWidth: '20px',
		maxWidth: '20px',
		minHeight: '20px',
		maxHeight: '20px',
		color: theme.colors.neutral000,
	};
	if (action) {
		return <ActionIcon id={action.id} color="neutral000" style={style} />;
	}
	return <Icon type={type} style={style} />;
}
