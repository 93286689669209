/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { Checkbox } from 'components';
import { ReactElement } from 'react';

import DropDownItemWrapper from './DropDownItemWrapper';
import NotFoundResult from './NotFoundResult';

interface EnvironmentsProps {
	environments: EnvironmentSummaryVO[];
	width: string | number | undefined;
	selectedEnvironmentIds: string[];
	value: string;
	deselectIds: (envIds: string[]) => void;
	selectIds: (envIds: string[]) => void;
	toggleId: (envId: string) => void;
}

export default function Environments({
	selectedEnvironmentIds,
	environments,
	width,
	value,
	deselectIds,
	selectIds,
	toggleId,
}: EnvironmentsProps): ReactElement {
	const filteredEnvs = environments.filter((env) => {
		if (!value) {
			return true;
		}
		const matcher = value.toLowerCase();
		return env.name.toLowerCase().includes(matcher);
	});

	if (filteredEnvs.length === 0) {
		return <NotFoundResult title="No environments found" width={width} />;
	}

	const allEnvsSelected = environments.every((env) => selectedEnvironmentIds.indexOf(env.id) !== -1);

	return (
		<>
			{filteredEnvs.length > 0 && (
				<>
					<Flex
						direction="horizontal"
						align="center"
						justify="end"
						spacing="xSmall"
						style={{ width: '100%', py: 'xSmall', px: 'xSmall' }}
					>
						<Text
							type="smallStrong"
							style={{ color: Colors.slate, onHover: { cursor: 'pointer', textDecoration: 'underline' } }}
							onClick={() =>
								allEnvsSelected
									? deselectIds(environments.map((env) => env.id))
									: selectIds(filteredEnvs.map((env) => env.id))
							}
						>
							{allEnvsSelected ? 'Deselect All' : 'Select All'}
						</Text>
					</Flex>
					{filteredEnvs.map((env) => {
						return (
							<EnvironmentItem
								key={env.id}
								selected={selectedEnvironmentIds.indexOf(env.id) !== -1}
								environment={env}
								onSelect={(env) => toggleId(env.id)}
							/>
						);
					})}
				</>
			)}
		</>
	);
}

interface EnvironmentItemProps {
	environment: EnvironmentSummaryVO;
	selected: boolean;
	onSelect: (environment: EnvironmentSummaryVO) => void;
}
function EnvironmentItem({ selected, environment, onSelect }: EnvironmentItemProps): ReactElement {
	const Icon = environment?.global ? IconEnvironmentGlobal : IconEnvironment;

	return (
		<DropDownItemWrapper onClick={() => onSelect(environment)}>
			<Checkbox checked={selected} onChange={() => {}} />
			<Icon size="small" />
			<Text
				type={selected ? 'smallStrong' : 'smallMedium'}
				style={{ color: selected ? Colors.slate : Colors.neutral600 }}
			>
				{environment.name}
			</Text>
		</DropDownItemWrapper>
	);
}
