/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets, Dropdown, Flex, TextInput } from '@steadybit/ui-components-lib';
import { includes, localeCompareIgnoreCase } from 'utils/string';
import { Li, Skeletons, Stack, Text, Ul } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { useUrlState } from 'url/useUrlState';
import { AdviceListItemVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

import { selectedAdviceIdParam, UrlState } from '../urlParams';
import AdviceListItem from './AdviceListItem';
import { ampli } from '../../../ampli';

interface AdviceListProps {
	adviceList: AdviceListItemVO[];
	selectedAdviceId: string | null;
}

export default function AdviceList({ adviceList, selectedAdviceId }: AdviceListProps): ReactElement {
	const [, , updateUrlState] = useUrlState<UrlState>([selectedAdviceIdParam]);

	// if nothing is selected, select the first advice
	useEffect(() => {
		if (selectedAdviceId || adviceList.length === 0) {
			return;
		}

		updateUrlState({ selectedAdviceId: adviceList.sort((a, b) => a.label.localeCompare(b.label))[0].type });
	}, [selectedAdviceId, adviceList]);
	const [searchTermTags, setSearchTermTags] = useState<string>('');
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	useEffect(() => {
		ampli.explorerAdviceListViewed({
			advice_ids_listed: adviceList.map((advice) => advice.type),
			advice_id_shown: selectedAdviceId ?? undefined,
			filter_tag: selectedTags,
			advice_context: 'explorer_advice',
		});
	}, [adviceList, selectedAdviceId, selectedTags]);

	const tags = adviceList
		.reduce((acc, advice) => {
			(advice.tags || []).forEach((tag) => {
				if (acc.indexOf(tag) === -1) {
					acc.push(tag);
				}
			});
			return acc;
		}, [] as string[])
		.sort(localeCompareIgnoreCase)
		.map((tag) => ({ value: tag, label: tag }));

	let filteredAdviceList = adviceList.sort((a, b) => a.label.localeCompare(b.label));
	if (selectedTags.length > 0) {
		filteredAdviceList = filteredAdviceList.filter((advice) =>
			selectedTags.find((tag) => advice.tags.indexOf(tag) !== -1),
		);
	}

	return (
		<Stack
			size="none"
			sx={{
				borderRight: '1px solid ' + theme.colors.neutral300,
				overflowY: 'auto',
			}}
		>
			{tags.length > 0 && (
				<Flex spacing="xSmall" style={{ p: 'small', backgroundColor: theme.colors.neutral100 }}>
					<Text variant="smallStrong">Filter by tags:</Text>
					<Dropdown<HTMLInputElement>
						placement="bottom-start"
						renderDropdownContent={({ width }) => {
							const filteredTags = tags
								.filter((tag) => includes(tag.value, searchTermTags))
								.filter((tag) => !selectedTags.includes(tag.value));
							return (
								<presets.dropdown.DropdownContentFrame width={width}>
									<Flex direction="horizontal" spacing="xSmall" wrap style={{ p: 'small' }}>
										{filteredTags.map((tag) => (
											<presets.pill.Tag key={tag.value} onClick={() => setSelectedTags([...selectedTags, tag.value])}>
												{tag.label}
											</presets.pill.Tag>
										))}
										{filteredTags.length === 0 && (
											<Text variant="small" color="neutral500">
												{searchTermTags ? 'No tags found' : 'You have selected all tags'}
											</Text>
										)}
									</Flex>
								</presets.dropdown.DropdownContentFrame>
							);
						}}
					>
						{({ setRefElement, isOpen, setOpen }) => {
							return (
								<TextInput
									ref={setRefElement}
									placeholder="Type to search for tags"
									syntheticFocussed={isOpen}
									value={searchTermTags}
									withLeftIcon="search"
									prevInputContent={
										<>
											{selectedTags.map((tag) => (
												<presets.pill.Tag
													key={tag}
													onDelete={() => setSelectedTags(selectedTags.filter((t) => t !== tag))}
												>
													{tag}
												</presets.pill.Tag>
											))}
										</>
									}
									wrap
									onClick={() => setOpen(true)}
									onChange={setSearchTermTags}
								/>
							);
						}}
					</Dropdown>
				</Flex>
			)}

			<Ul
				sx={{
					p: 'small',
					height: '100%',
				}}
			>
				{filteredAdviceList.map((advice) => {
					const isSelected = selectedAdviceId === advice.type;
					return <AdviceListItem key={advice.type} advice={advice} isSelected={isSelected} />;
				})}
			</Ul>
		</Stack>
	);
}

export function LoadingList(): ReactElement {
	return (
		<Ul
			sx={{
				p: 'small',
				borderRight: '1px solid ' + theme.colors.neutral300,
				height: '100%',
				overflowY: 'auto',
			}}
		>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
			<Li mb="small">
				<Skeletons height={56} widths={[390]} pb="xxSmall" mt={1} />
			</Li>
		</Ul>
	);
}
