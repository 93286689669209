/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ActionSummaryVO,
	AdviceDefinitionSummaryVO,
	TargetAttributeDescriptionVO,
	TargetEnrichmentRuleSummaryVO,
	TargetTypeSummaryVO,
} from 'ui-api';
import { localeCompareIgnoreCase } from 'utils/string';

export function sort(items: TargetAttributeDescriptionVO[]): TargetAttributeDescriptionVO[] {
	return items.slice().sort((a, b) => {
		return localeCompareIgnoreCase(a.attribute, b.attribute);
	});
}

export function sortTargetTypes(items: TargetTypeSummaryVO[]): TargetTypeSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.label.one, b.label.one);
		}
	});
}

export function sortActions(items: ActionSummaryVO[]): ActionSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if ((a.reportedByAgents || a.alwaysPermittedAndReported) && !b.reportedByAgents && !b.alwaysPermittedAndReported) {
			return 1;
		} else if (
			!a.reportedByAgents &&
			!a.alwaysPermittedAndReported &&
			(b.reportedByAgents || b.alwaysPermittedAndReported)
		) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.name, b.name);
		}
	});
}

export function sortTargetEnrichmentRules(items: TargetEnrichmentRuleSummaryVO[]): TargetEnrichmentRuleSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.label, b.label);
		}
	});
}

export function sortAdviceDefinition(items: AdviceDefinitionSummaryVO[]): AdviceDefinitionSummaryVO[] {
	// first, sort by reportedByAgents
	// second, sort by reportedByAgentsWithUnknownVersion
	// third, sort by name

	return items.slice().sort((a, b) => {
		if (a.reportedByAgents && !b.reportedByAgents) {
			return 1;
		} else if (!a.reportedByAgents && b.reportedByAgents) {
			return -1;
		} else if (a.reportedByAgentsWithUnknownVersion && !b.reportedByAgentsWithUnknownVersion) {
			return -1;
		} else if (!a.reportedByAgentsWithUnknownVersion && b.reportedByAgentsWithUnknownVersion) {
			return 1;
		} else {
			return localeCompareIgnoreCase(a.label, b.label);
		}
	});
}
