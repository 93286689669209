/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Code, Container, Label, Link, ModalContentV2, ModalHeaderV2, ModalOverlay, ModalV2, Stack } from 'components';
import CodeTypeSelection, { codeTypeOptions } from 'pages/dashboard/components/CodeTypeSelection';
import ScaleSelection, { scaleOptions } from 'pages/dashboard/components/ScaleSelection';
import { getBaseHref } from 'utils/getBaseHref';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { useTeam } from 'services/useTeam';

type ExperimentBadgeModalProps = {
	experimentKey: string;
	onClose: () => void;
};

export default function ExperimentBadgeModal({ experimentKey, onClose }: ExperimentBadgeModalProps): ReactElement {
	const tenant = useTenant();
	const team = useTeam();
	const [codeType, setCodeType] = useState<string>(codeTypeOptions[0].id);
	const [scale, setScale] = useState<string>(scaleOptions[0].id);

	const imageUrl = `${getBaseHref()}api/experiments/${experimentKey}/badge.svg?tenantKey=${tenant.key}&scale=${scale}`;
	const experimentUrl = `${getBaseHref()}experiments/edit/${experimentKey}?tenant=${tenant.key}&team=${team.key}`;
	const html = `<a href='${experimentUrl}' target='_blank'><img alt='${experimentKey}' src='${imageUrl}'></a>`;
	const markdown = `[![${experimentKey}](${imageUrl})](${experimentUrl})`;

	const content = codeType === 'HTML' ? html : codeType === 'MARKDOWN' ? markdown : imageUrl;

	return (
		<ModalOverlay open onClose={onClose} centerContent>
			{({ close }) => (
				<ModalV2>
					<ModalHeaderV2 title={`Create Experiment Badge for ${experimentKey}`} onClose={close} />
					<ModalContentV2>
						<Stack size={'medium'}>
							<Stack direction={'horizontal'} sx={{ gap: 'small' }}>
								<Label>
									Format
									<CodeTypeSelection selectedCodeType={codeType} selectCodeType={setCodeType} />
								</Label>
								<Label>
									Scale
									<ScaleSelection selectedScale={scale} setSelectScale={setScale} />
								</Label>
							</Stack>
							<Label>
								Result
								<Container>
									<Link href={experimentUrl} target={'_blank'}>
										<img src={imageUrl} />
									</Link>
								</Container>
							</Label>
							<Code
								lang={codeType === 'HTML' || codeType === 'MARKDOWN' ? 'markdown' : undefined}
								sx={{ whiteSpace: 'normal' }}
								withCopyToClipboard
							>
								{content}
							</Code>
						</Stack>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}
