/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { SelectOptions } from './SelectOptionTypes';
import * as Types from './SelectOptionTypes';

export function find<OptionType extends Types.OptionTypeBase>(
	options: SelectOptions<OptionType>[],
	filter?: (o: OptionType) => boolean,
): OptionType | null {
	if (filter) {
		for (const option of options) {
			if (isGroup(option)) {
				const found = find(option.options, filter);
				if (found) {
					return found;
				}
			} else {
				if (filter(option)) {
					return option;
				}
			}
		}
	}
	return null;
}

function isGroup(
	optionOrGroup: Types.SelectOptions<Types.OptionTypeBase> | null | undefined,
): optionOrGroup is Types.SelectOptionGroup<Types.OptionTypeBase> {
	if (!optionOrGroup) return false;
	return (optionOrGroup as Types.SelectOptionGroup<Types.OptionTypeBase>).options !== undefined;
}
