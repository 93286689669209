/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { UrlParam } from 'url/useUrlState';

import { DirectionVO } from '../../ui-api';

export interface UrlState {
	tags: string[];
	targetTypes: string[];
	actions: string[];
	freeTextPhrases: string[];
	environmentIds: string[];
	page: number;
	size: number;
	sort: string;
	direction: DirectionVO;
}

export const sortParam: UrlParam<string> = {
	pathSegment: '/experiments',
	name: 'sort',
	defaultValue: 'experimentNumber',
};

export const directionParam: UrlParam<DirectionVO> = {
	pathSegment: '/experiments',
	name: 'direction',
	defaultValue: 'DESC',
};
