/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { FieldInput, getDefaultValue } from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Field';
import StringOptions from 'pages/experimentsV2/StepConfigurationSidebar/Fields/Controls/StringOptions';
import { Divider, LoadingIndicator, smellsLikeTemplatePlaceholder, TextField } from 'components';
import DropdownInputFilterable from 'components/PredicateBuilder/DropdownInputFilterable';
import { Colors, Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import PredicateEditor from 'components/PredicateEditor/PredicateEditor';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { FieldTypeVO, OccuranceVO, VariableVO } from 'ui-api';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { includes } from 'utils/string';
import { useField } from 'formik';
import { debounce } from 'lodash';

import {
	isOccuranceActionPredicateVO,
	isOccuranceStepLabelVO,
	isOccuranceStepParameterVO,
	isOccuranceTemplateFieldVO,
} from '../types';
import VariablesAndPlaceholders from '../../../experimentsV2/StepConfigurationSidebar/Fields/Controls/VariablesAndPlaceholders';

interface OccuranceValueProps {
	occurances: OccuranceVO[];
	hasWarnings?: boolean;
	targetType?: string;
	disabled?: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function OccurrenceValue({
	value: externalValue,
	hasWarnings = false,
	disabled = false,
	targetType,
	occurances,
	setValue: setValueExternal,
}: OccuranceValueProps): ReactElement {
	const [debouncedSetValueExternal] = useState(() => debounce(setValueExternal, 500));

	const [value, setLocalCopiedValue] = useState(externalValue);
	const setValue = (_v: unknown, type?: FieldTypeVO): void => {
		let valueToSet: string = '';
		if (type && typeof _v === 'number' && isNaN(Number(_v))) {
			valueToSet = String(getDefaultValue(type));
		}
		if (Array.isArray(_v)) {
			valueToSet = _v.join(',');
		} else {
			valueToSet = String(_v);
		}

		setLocalCopiedValue(valueToSet);
		debouncedSetValueExternal(valueToSet);
	};

	const typesOfUsage = new Set();
	occurances.forEach((occurance) => {
		if (isOccuranceTemplateFieldVO(occurance)) {
			typesOfUsage.add('string');
		} else if (isOccuranceActionPredicateVO(occurance)) {
			if (occurance.usedInKey) {
				typesOfUsage.add('predicate-attribute-key');
			} else if (occurance.usedAsFullQueryReplacement) {
				typesOfUsage.add('full-predicate-replacement');
			} else {
				typesOfUsage.add('predicate-attribute-value_' + occurance.attribute);
			}
		} else if (isOccuranceStepLabelVO(occurance)) {
			typesOfUsage.add('string');
		} else if (isOccuranceStepParameterVO(occurance)) {
			typesOfUsage.add(occurance.field.type);
		} else {
			console.error('Unknown occurance type', occurance);
		}
	});

	// Special case: There are 2 types and one of them is string.
	// String can render everything, so we can take the other type as a special input field.
	if (typesOfUsage.size === 2 && typesOfUsage.has('string')) {
		typesOfUsage.delete('string');
	}

	if (typesOfUsage.size !== 1 || typesOfUsage.has('string')) {
		const firstStringField = occurances
			.filter(isOccuranceStepParameterVO)
			.filter((o) => o.field.type === 'string')
			.map((occurance) => occurance.field)[0];
		if (firstStringField && firstStringField.options) {
			return (
				<div style={{ maxWidth: '350px' }}>
					<StringOptions
						options={firstStringField.options}
						hasErrors={hasWarnings}
						disabled={disabled}
						value={value}
						setValue={(_v) => setValue(_v, firstStringField.type)}
					/>
				</div>
			);
		}
		return (
			<div style={{ maxWidth: '350px' }}>
				<TextField value={value} type="text" onChange={(e) => setValue(e.target.value)} placeholder="Enter value" />
			</div>
		);
	}

	const typeOfUsage: string = typesOfUsage.values().next().value as string;
	if (typeOfUsage === 'predicate-attribute-key') {
		return (
			<div style={{ maxWidth: '450px' }}>
				<AttributeKeys attributeKey={value} setValue={setValue} hasErrors={hasWarnings} disabled={disabled} />
			</div>
		);
	}
	if (typeOfUsage.startsWith('predicate-attribute-value_')) {
		const attributeKey = typeOfUsage.substring('predicate-attribute-value_'.length);
		if (smellsLikeTemplatePlaceholder(attributeKey)) {
			return (
				<div style={{ maxWidth: '450px' }}>
					<AttributeValueForTemplatePlaceholderKey
						hasErrors={hasWarnings}
						targetType={targetType}
						disabled={disabled}
						placeholder={attributeKey.substring(2, attributeKey.length - 2)}
						value={value}
						setValue={setValue}
					/>
				</div>
			);
		}

		return (
			<div style={{ maxWidth: '450px' }}>
				<AttributeValues
					attributeKey={attributeKey}
					targetType={targetType}
					hasErrors={hasWarnings}
					disabled={disabled}
					value={value}
					setValue={setValue}
				/>
			</div>
		);
	}

	if (typeOfUsage === 'full-predicate-replacement') {
		return (
			<div style={{ maxWidth: '700px' }}>
				<PredicateEditor
					predicate={toTargetPredicate(value)}
					setPredicate={(predicate) => {
						const predicateString = toPredicateString(predicate);
						setValueExternal(predicateString);
					}}
					disabled={disabled}
					autoFocus={false}
				/>
			</div>
		);
	}

	// Since all fields now should have the same type, we can just use the first one
	const firstField = occurances.filter(isOccuranceStepParameterVO).map((occurance) => occurance.field)[0];

	return (
		<div style={{ maxWidth: '350px' }}>
			<FieldInput
				hasWarnings={hasWarnings}
				hasErrors={false}
				disabled={disabled}
				field={firstField}
				value={value}
				setValue={(_v) => setValue(_v, firstField.type)}
			/>
		</div>
	);
}

interface AttributeKeysProps {
	attributeKey: string;
	hasErrors: boolean;
	disabled: boolean;
	setValue: (value: string) => void;
}

function AttributeKeys({ attributeKey, hasErrors, disabled, setValue }: AttributeKeysProps): ReactElement {
	const attributeKeysResult = usePromise(() => Services.editorApi.fetchTargetAttributeKeys(), []);

	return (
		<DropdownInputFilterable
			attributeKeys={attributeKeysResult.value || []}
			attributeKeysLoading={false}
			hasError={hasErrors}
			value={attributeKey}
			disabled={disabled}
			small={false}
			onValueChanged={setValue}
		/>
	);
}

interface AttributeValueForTemplatePlaceholderKeyProps {
	targetType?: string;
	placeholder: string;
	hasErrors: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

function AttributeValueForTemplatePlaceholderKey({
	placeholder,
	targetType,
	hasErrors,
	disabled,
	value,
	setValue,
}: AttributeValueForTemplatePlaceholderKeyProps): ReactElement {
	const [field] = useField<Map<string, string>>('placeholderValuesMap');
	const valueFromPlaceholder = field.value.get(placeholder);
	const attributeKey = valueFromPlaceholder || '';

	if (!attributeKey) {
		return <TextField value={value} type="text" onChange={(e) => setValue(e.target.value)} placeholder="Enter value" />;
	}

	return (
		<AttributeValues
			attributeKey={attributeKey}
			targetType={targetType}
			hasErrors={hasErrors}
			disabled={disabled}
			value={value}
			setValue={setValue}
		/>
	);
}

interface AttributeValuesProps {
	attributeKey: string;
	targetType?: string;
	hasErrors: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

function AttributeValues({
	attributeKey,
	targetType,
	hasErrors,
	disabled,
	value,
	setValue,
}: AttributeValuesProps): ReactElement {
	const [field] = useField<string>('environmentId');
	const [{ value: variables }] = useField<VariableVO[] | string[]>('variables');

	const attributeValuesResult = usePromise(
		() => Services.editorApi.fetchTargetAttributeValues({ key: attributeKey, environmentId: field.value, targetType }),
		[attributeKey, field.value],
	);

	return (
		<Dropdown<HTMLInputElement>
			placement="bottom-start"
			renderDropdownContent={({ width, close }) => {
				if (!attributeValuesResult.value) {
					return (
						<presets.dropdown.DropdownContentFrame maxHeight="200px">
							<LoadingIndicator variant="medium" color="slate" sx={{ ml: 'xSmall', my: 'xxSmall' }} />
						</presets.dropdown.DropdownContentFrame>
					);
				}
				return (
					<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="240px" maxWidth="420px">
						<presets.dropdown.SingleChoiceList
							batchSize={50}
							items={attributeValuesResult.value
								.filter((label) => includes(label, value))
								.map((label) => ({
									id: label,
									label,
								}))}
							onSelect={(id) => {
								setValue(id);
								close();
							}}
						/>
						{variables && variables.length > 0 && (
							<>
								<Divider />
								<VariablesAndPlaceholders
									width={340}
									selectItem={(_v) => {
										setValue(_v);
										close();
									}}
								/>
							</>
						)}
					</presets.dropdown.DropdownContentFrame>
				);
			}}
		>
			{({ setRefElement, setOpen }) => {
				return (
					<TextInput
						ref={setRefElement}
						placeholder="Value"
						disabled={disabled}
						autoComplete="off"
						value={value}
						style={
							hasErrors
								? {
										border: '1px solid ' + Colors.coral,
										outline: '1px solid ' + Colors.coral,
									}
								: {}
						}
						onClick={() => setOpen(true)}
						onChange={setValue}
					/>
				);
			}}
		</Dropdown>
	);
}
