/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useIsExperimentDisabled from 'pages/experimentsV2/useIsExperimentDisabled';
import { ReactElement } from 'react';
import { FieldVO } from 'ui-api';

import Field from './Field';

interface FieldProps {
	fields: FieldVO[];
	stepPath: string;
	fallbackHandler?: (field: FieldVO) => ReactElement | null;
}

export default function Fields({ fields, stepPath, fallbackHandler }: FieldProps): ReactElement | null {
	const disabled = useIsExperimentDisabled();

	return (
		<>
			{fields.map((field, i) => {
				return (
					<Field
						key={i}
						field={field}
						path={`${stepPath}.parameters['${field.name}']`}
						disabled={disabled}
						fallbackHandler={fallbackHandler}
					/>
				);
			})}
		</>
	);
}
