/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Heading, RouterButton, RouterLink, Text } from 'components';
import { Flex } from '@steadybit/ui-components-lib';
import { useTenant } from 'tenancy/useTenant';
import { EnvironmentSummaryVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

import Environment from './Environment';

type EnvironmentsDropdownContentProps = {
	selectedEnvironmentId: string | undefined;
	environments: EnvironmentSummaryVO[];
	allowedEnvironments: string[];
	isLandscapeView: boolean;
	disabled: boolean;
	selectEnvironmentId: (v: string) => void;
};

export default function EnvironmentsDropdownContent({
	selectedEnvironmentId,
	allowedEnvironments,
	isLandscapeView,
	environments,
	disabled,
	selectEnvironmentId,
}: EnvironmentsDropdownContentProps): ReactElement {
	const isAdmin = useTenant().user.role === 'ADMIN';

	const isEnvironmentPermitted = (environmentId: string): boolean => allowedEnvironments.includes(environmentId);

	const selectableEnvironmentsWithoutGlobal = environments.filter((environment) => !environment.global);
	const globalEnvironment = environments.find((environment) => environment.global);
	const onlyGlobalEnvironmentAvailable = globalEnvironment && selectableEnvironmentsWithoutGlobal.length === 0;

	const selectedEnvironment = environments.find((environment) => environment.id === selectedEnvironmentId);

	return (
		<Flex style={{ maxWidth: '400px' }}>
			{globalEnvironment && (
				<Environment
					key={globalEnvironment.id}
					isSelected={selectedEnvironmentId === globalEnvironment.id}
					isPermitted={isEnvironmentPermitted(globalEnvironment.id)}
					environment={globalEnvironment}
					disabled={disabled}
					onClick={() => selectEnvironmentId(globalEnvironment.id)}
				/>
			)}
			<Divider />

			<Flex direction="horizontal" align="center" justify="spread" style={{ width: '100%', py: 'xSmall', px: 'small' }}>
				<Heading variant="small">Environments</Heading>
				{isAdmin && (
					<RouterButton variant="secondarySmall" to="/settings/environments">
						Manage
					</RouterButton>
				)}
			</Flex>

			{!selectedEnvironment && selectedEnvironmentId && (
				<Environment
					key={selectedEnvironmentId}
					isSelected
					isPermitted={false}
					environment={createUnknownEnvironment(selectedEnvironmentId)}
					disabled
					onClick={() => {}}
				/>
			)}

			{selectableEnvironmentsWithoutGlobal.map((environment) => (
				<Environment
					key={environment.id}
					isSelected={selectedEnvironmentId === environment.id}
					isPermitted={isEnvironmentPermitted(environment.id)}
					environment={environment}
					disabled={disabled}
					onClick={() => selectEnvironmentId(environment.id)}
				/>
			))}

			{!onlyGlobalEnvironmentAvailable && (
				<HelpText
					hasMultipleEnvironments={environments.length > 1}
					isLandscapeView={isLandscapeView}
					isAdmin={isAdmin}
				/>
			)}
		</Flex>
	);
}

function Divider(): ReactElement {
	return (
		<div
			style={{
				height: 1,
				width: '100%',
				backgroundColor: theme.colors.neutral300,
			}}
		/>
	);
}

function HelpText({
	hasMultipleEnvironments,
	isLandscapeView,
	isAdmin,
}: {
	hasMultipleEnvironments: boolean;
	isLandscapeView: boolean;
	isAdmin: boolean;
}): ReactElement | null {
	if (hasMultipleEnvironments && isLandscapeView) {
		return null;
	}

	if (hasMultipleEnvironments && !isLandscapeView) {
		return (
			<>
				<Divider />
				<Text px="small" pt="small" variant="small" color="neutral600">
					Unsure which environment is the right one?
				</Text>
				<Text px="small" pb="small" variant="small" color="neutral600">
					Explore your environments in the
					<RouterLink to="/landscape/explore">
						<Text as="span" variant="smallStrong" ml="xxSmall">
							Landscape
						</Text>
					</RouterLink>
				</Text>
			</>
		);
	}

	return (
		<>
			<Divider />
			<Text px="small" pt="small" variant="small" color="neutral600">
				Overwhelmed by the size of your system?
			</Text>
			<Text px="small" pb="small" variant="small" color="neutral600">
				{isAdmin ? (
					<>
						<RouterLink to={'/settings/environments'}>
							<Text as="span" variant="smallStrong">
								Create an environment
							</Text>
						</RouterLink>{' '}
						to divide it into smaller pieces.
					</>
				) : (
					<>
						Ask your admin to{' '}
						<RouterLink to={'/settings/environments'}>
							<Text as="span" variant="smallStrong">
								create an environment
							</Text>
						</RouterLink>
						.
					</>
				)}
			</Text>
		</>
	);
}

function createUnknownEnvironment(id: string): EnvironmentSummaryVO {
	return {
		_actions: [],
		id,
		name: 'Unknown',
		teams: [],
		predicate: '',
		global: false,
	};
}
