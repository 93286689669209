/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconComponent, IconTarget, IconTemplate } from 'components/icons';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { Flex, Spacings, Text } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { Tooltip } from 'components';

import CollapsedEntry from './CollapsedEntry';

export type Section = 'actions' | 'templates';

interface SectionsProps {
	templatesEnabled: boolean;
	numTemplates: number;
	collapsed?: boolean;
	numActions: number;
	section: Section;
	setSection: (section: Section) => void;
}

export default function Sections({
	templatesEnabled,
	numTemplates,
	numActions,
	collapsed,
	section,
	setSection,
}: SectionsProps): ReactElement {
	if (collapsed) {
		return (
			<Flex
				spacing="xSmall"
				align="center"
				style={{ width: '100%', backgroundColor: theme.colors.neutral200, py: 'xSmall', borderRadius: 'xxSmall' }}
			>
				<CollapsedEntry onClick={() => setSection('actions')} tooltip="Actions">
					<CollapsedSectionWrapper isSelected={section === 'actions'}>
						<IconTarget variant="medium" color={section === 'actions' ? 'neutral800' : 'slate'} />
					</CollapsedSectionWrapper>
				</CollapsedEntry>
				<CollapsedEntry
					onClick={() => setSection('templates')}
					tooltip={templatesEnabled ? 'Templates' : <LicenseTooltipContent />}
					disabled={!templatesEnabled}
				>
					<CollapsedSectionWrapper isSelected={section === 'templates'} disabled={!templatesEnabled}>
						<IconTemplate
							variant="medium"
							color={!templatesEnabled ? 'neutral400' : section === 'templates' ? 'neutral800' : 'slate'}
						/>
					</CollapsedSectionWrapper>
				</CollapsedEntry>
			</Flex>
		);
	}

	return (
		<Flex direction="horizontal" style={{ width: '100%' }}>
			<SectionsItem
				selected={section === 'actions'}
				Icon={IconTarget}
				title={numActions >= 0 ? `Actions (${numActions})` : 'Actions'}
				first
				onClick={() => setSection('actions')}
			/>
			<SectionsItem
				disabled={!templatesEnabled}
				selected={section === 'templates'}
				Icon={IconTemplate}
				title={numTemplates >= 0 ? `Templates (${numTemplates})` : 'Templates'}
				onClick={() => setSection('templates')}
			/>
		</Flex>
	);
}

interface SectionItemProps {
	Icon: IconComponent;
	disabled?: boolean;
	selected: boolean;
	first?: boolean;
	title: string;
	onClick: () => void;
}

function SectionsItem({ title, Icon, selected, first, disabled, onClick }: SectionItemProps): ReactElement {
	const { neutral000, neutral150, neutral300 } = theme.colors;

	return (
		<Tooltip content={<LicenseTooltipContent />} disabled={!disabled}>
			<Flex
				direction="horizontal"
				align="center"
				style={{
					flexGrow: 1,
					padding: `${Spacings.small} ${Spacings.xSmall} ${Spacings.small} ${Spacings.small}`,
					backgroundColor: selected ? neutral000 : neutral150,
					borderRight: first ? `1px solid ${neutral300}` : 'none',
					borderBottom: !selected ? `1px solid ${neutral300}` : 'none',
					onHover: disabled
						? undefined
						: {
								backgroundColor: selected ? neutral000 : neutral300,
							},
				}}
				onClick={disabled ? undefined : onClick}
			>
				<Icon variant="large" color={disabled ? 'neutral400' : selected ? 'neutral800' : 'slate'} />
				<Text
					type="mediumStrong"
					style={{
						marginLeft: Spacings.xxSmall,
						whiteSpace: 'nowrap',
						color: disabled ? theme.colors.neutral400 : theme.colors.neutral800,
					}}
				>
					{title}
				</Text>
			</Flex>
		</Tooltip>
	);
}

function CollapsedSectionWrapper({
	isSelected,
	disabled,
	children,
}: {
	children: ReactElement;
	isSelected: boolean;
	disabled?: boolean;
}): ReactElement {
	return (
		<Flex
			direction="horizontal"
			align="center"
			justify="center"
			style={{
				padding: '10px 12px',
				borderRadius: 'xxSmall',
				backgroundColor: isSelected ? theme.colors.neutral000 : undefined,
				onHover: disabled
					? undefined
					: {
							backgroundColor: theme.colors.neutral000,
							cursor: 'pointer',
						},
			}}
		>
			{children}
		</Flex>
	);
}
