/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { ReactElement, ReactNode, useEffect } from 'react';
import { ExperimentExecutionVO } from 'ui-api';
import { Services } from 'services/services';

import { isExperimentStepExecutionActionVO } from '../experiments/components/experimentExecutionLog';
import { isNotEnded } from '../experiments/components/utils';
import { PageParams } from '../../utils/hooks/usePage';

interface ExperimentExecutionLoaderProps {
	experimentExecutionId?: string;
	experimentKey?: string;
	children: ({
		experimentExecution,
	}: {
		experimentExecution: AsyncState<ExperimentExecutionVO | undefined>;
	}) => ReactNode;
}

export default function ExperimentExecutionLoader({
	experimentExecutionId,
	experimentKey,
	children,
}: ExperimentExecutionLoaderProps): ReactElement {
	const experimentExecution = useExperimentRun(experimentExecutionId, experimentKey);
	return <>{children({ experimentExecution: experimentExecution })}</>;
}

function useExperimentRun(
	experimentExecutionId?: string,
	experimentKey?: string,
): AsyncState<ExperimentExecutionVO | undefined> {
	const [experimentExecution, fetch] = useAsyncState(async () => {
		if (!experimentExecutionId) {
			const currentRun = await Services.experiments.fetchExperimentRuns(
				new URLSearchParams(),
				new PageParams(0, 1),
				experimentKey,
			);
			if (currentRun.totalElements > 0) {
				experimentExecutionId = String(currentRun.content[0].id);
			}
		}
		if (!experimentExecutionId) {
			return undefined;
		}
		return Services.experiments.fetchExperimentRun(Number(experimentExecutionId));
	}, [experimentKey, experimentExecutionId]);

	useEffect(() => {
		if (
			experimentExecution.loading ||
			(!experimentExecutionId && !experimentKey) ||
			!runningOrEndedInLast10seconds(experimentExecution.value)
		) {
			return;
		}
		const timeout = setTimeout(() => {
			fetch();
		}, 1000);
		return () => clearTimeout(timeout);
	}, [experimentExecution.value]);

	return experimentExecution;
}

function hasRunningTargetExecution(execution: ExperimentExecutionVO): boolean {
	for (const lane of execution.lanes) {
		for (const step of lane.steps) {
			if (!isExperimentStepExecutionActionVO(step)) {
				continue;
			}
			if (step.targetExecutions.some((t) => isNotEnded(t.state))) {
				return true;
			}
		}
	}
	return false;
}

function runningOrEndedInLast10seconds(execution: ExperimentExecutionVO | undefined): boolean {
	if (!execution) {
		return true;
	}
	if (!execution.ended) {
		return true;
	}
	if (execution.ended.getTime() + 10_000 > Date.now()) {
		return true;
	}
	if (hasRunningTargetExecution(execution)) {
		return true;
	}
	return false;
}
