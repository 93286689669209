/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { Button, presets } from '@steadybit/ui-components-lib';
import { Container } from 'components';

import { TextField, TextFieldProps } from '../TextField';

export interface UnitFieldUnit {
	label: string;
	id: string;
}

interface UnitFieldProps extends TextFieldProps {
	unitSelectorDisabled?: boolean;
	units?: UnitFieldUnit[];
	unit: UnitFieldUnit;
	min?: number;
	max?: number;
	onUnitChange?: (unit: string) => void;
}

export default function UnitField({
	unitSelectorDisabled,
	disabled,
	value,
	units,
	unit,
	onUnitChange,
	...props
}: UnitFieldProps): ReactElement {
	return (
		<Container display={'flex'} flex={'0 0 auto'} justifyContent="flex-end">
			<TextField
				disabled={disabled}
				sx={{
					borderRadius: '4px 0 0 4px',
					height: '40px',
				}}
				type="number"
				{...props}
				value={value ?? ''}
			/>

			{units && units.length > 1 && onUnitChange ? (
				<presets.dropdown.SingleChoiceButton
					placement="bottom-end"
					items={units}
					selectedId={unit.id}
					disabled={disabled || unitSelectorDisabled}
					onSelect={onUnitChange}
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
				>
					{unit.label}
				</presets.dropdown.SingleChoiceButton>
			) : (
				<Button
					type="secondary"
					style={{
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					}}
				>
					{unit.label}
				</Button>
			)}
		</Container>
	);
}
