/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { Button, Dropdown, Flex, Grid, presets, TextInput } from '@steadybit/ui-components-lib';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { ChangeAgentLogLevelRequest } from 'ui-api';
import { Services } from 'services/services';
import React, { useState } from 'react';
import { includes } from 'utils/string';
import { Form, Formik } from 'formik';

const ChangeAgentLogLevel: React.VFC<{ agentId?: string; close: () => void }> = ({ agentId, close }) => {
	const [agent, fetch] = useAsyncState(
		async () => (agentId ? Services.agents.fetchAgent(agentId) : undefined),
		[agentId],
	);
	const [submitError, setSubmitError] = React.useState<Error | null>();

	const [options, setOptions] = useState([
		{ label: 'com.steadybit', id: 'com.steadybit' },
		{ label: 'com.steadybit.communication', id: 'com.steadybit.communication' },
		{ label: 'com.steadybit.actions', id: 'com.steadybit.actions' },
		{ label: 'com.steadybit.attacks', id: 'com.steadybit.attacks' },
		{ label: 'com.steadybit.discovery', id: 'com.steadybit.discovery' },
	]);

	useEventEffect(
		React.useCallback(
			(event) => {
				if (agentId === event.agentId) {
					fetch();
				}
			},
			[fetch, agentId],
		),
	);

	const handleSubmit = async (values: ChangeAgentLogLevelRequest): Promise<void> => {
		try {
			if (agentId) {
				await Services.agents.setLogLevel(agentId, values);
			} else {
				await Services.agents.setLogLevelAll(values);
			}
			Snackbar.dark('Log Level Change requested.', { toastId: 'level-saved' });
			close();
		} catch (error) {
			setSubmitError(error);
		}
	};

	const [loggerQuery, setLoggerQuery] = useState('');

	return (
		<Formik
			initialValues={{
				loggerName: 'com.steadybit',
				level: 'INFO',
			}}
			onSubmit={handleSubmit}
		>
			{({ values, setFieldValue, submitForm, isSubmitting }) => (
				<Form noValidate>
					<ModalV2 withFooter slick>
						<ModalHeaderV2 title={`Change Agent Log Level ${agentId ? agent.value?.hostname : ''}`} onClose={close} />
						<ModalContentV2>
							{agentId && agent.loading ? (
								<Stack>
									<Spinner variant={'xxLarge'} color={'neutral200'} />
								</Stack>
							) : (
								<Stack size={'large'} pb="2px">
									{agent.value?.logLevel ? (
										<Container>
											<Text variant={'mediumStrong'}>Current Settings</Text>
											{agent.value?.logLevel?.split('\n').map((value, index) => (
												<React.Fragment key={index}>
													{value}
													<br />
												</React.Fragment>
											))}
										</Container>
									) : null}

									<Text muted>
										Log-Level-Changes are requested from the agent and processed asynchronous. Every Change will be
										automatically reverted after 10 minutes.
									</Text>

									<Grid cols="1fr 212px" spacing="small">
										<Dropdown<HTMLInputElement>
											renderDropdownContent={({ width, close }) => {
												const items = options
													.filter((option) => includes(option.label, loggerQuery))
													.map((option) => ({
														...option,
														isSelected: values.loggerName === option.id,
													}));

												return (
													<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="400px">
														<presets.dropdown.SingleChoiceList
															items={items}
															onSelect={(id) => {
																setFieldValue('loggerName', id);
																close();
															}}
														/>
														{loggerQuery && !items.some((item) => item.label === loggerQuery) && (
															<Button
																type="chromeless"
																withLeftIcon="plus"
																onClick={() => {
																	setOptions([...options, { label: loggerQuery, id: loggerQuery }]);
																	setFieldValue('loggerName', loggerQuery);
																	setLoggerQuery('');
																	close();
																}}
															>
																{`Add custom logger "${loggerQuery}"`}
															</Button>
														)}
													</presets.dropdown.DropdownContentFrame>
												);
											}}
										>
											{({ setRefElement, isOpen, setOpen }) => {
												return (
													<TextInput
														ref={setRefElement}
														withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
														value={loggerQuery}
														placeholder={values.loggerName}
														onClick={() => setOpen(!isOpen)}
														onChange={setLoggerQuery}
													/>
												);
											}}
										</Dropdown>

										<presets.dropdown.SingleChoiceButton
											selectedId={values.level}
											items={[
												{ label: 'INFO', id: 'INFO' },
												{ label: 'DEBUG', id: 'DEBUG' },
												{ label: 'TRACE', id: 'TRACE' },
											]}
											onSelect={(id) => setFieldValue('level', id)}
										>
											{values.level}
										</presets.dropdown.SingleChoiceButton>
									</Grid>
								</Stack>
							)}
							{agent.error && <div>Error loading Agent: {agent.error.message}</div>}
						</ModalContentV2>
						<ModalFooterV2>
							<>
								{submitError ? <Container flex={'1 0 auto'}> {submitError.toString()}</Container> : null}
								<Flex direction="horizontal" spacing="small">
									<Button
										type="secondary"
										onClick={() => {
											setFieldValue('loggerName', 'com.steadybit');
											setFieldValue('level', '');
											submitForm();
										}}
									>
										Request Log-Level Reset
									</Button>
									<Button submit={!isSubmitting}>Request Log-Level Change</Button>
								</Flex>
							</>
						</ModalFooterV2>
					</ModalV2>
				</Form>
			)}
		</Formik>
	);
};

export default ChangeAgentLogLevel;
