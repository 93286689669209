/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { createContext, useContext } from 'react';

type Mode = undefined | 'experiment' | 'templateEditor' | 'templateUsage' | 'templatePreview' | 'templateInExperiment';
type EditorSettingsContextType = {
	mode: Mode;
};

export const EditorSettingsContext = createContext<EditorSettingsContextType>({ mode: undefined });
export const useEditorSettings = (): EditorSettingsContextType => {
	return useContext(EditorSettingsContext);
};
