/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';
import Sidebar from 'targets/Sidebar';

interface SidebarWrapperProps {
	children: ReactNode | ReactNode[];
}

export default function SidebarWrapper({ children }: SidebarWrapperProps): ReactElement {
	return (
		<Sidebar
			title=""
			width={512}
			sx={{
				bg: 'neutral000',
				px: 'medium',
				minHeight: '100%',
				height: 'unset',
			}}
		>
			{(collapsed) => {
				if (collapsed) {
					return null;
				}

				return (
					<Flex spacing="large" style={{ maxWidth: '100%' }}>
						{children}
					</Flex>
				);
			}}
		</Sidebar>
	);
}
