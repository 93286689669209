/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { Dropdown, presets } from '@steadybit/ui-components-lib';
import { TextField } from 'components';
import { ReactElement } from 'react';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';

interface TextAreaProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function TextArea({ value, hasErrors, disabled, setValue }: TextAreaProps): ReactElement {
	const { mode } = useEditorSettings();

	if (mode === 'experiment') {
		return (
			<TextField
				as="textarea"
				value={value || ''}
				onChange={(e) => {
					setValue(e.target.value);
				}}
				hasError={hasErrors}
				disabled={disabled}
			/>
		);
	}

	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							setValue(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, setOpen }) => {
				return (
					<div ref={setRefElement} onFocus={() => setOpen(true)} style={{ width: '100%' }}>
						<TextField
							as="textarea"
							value={value}
							onChange={(e) => setValue(e.target.value)}
							hasError={hasErrors}
							disabled={disabled}
						/>
					</div>
				);
			}}
		</Dropdown>
	);
}
