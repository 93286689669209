/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	Divider,
	Form,
	FormikCheckbox,
	FormikTextField,
	Label,
	LoadingIndicator,
	ModalOverlay,
	Stack,
} from 'components';
import { EnvironmentSummaryVO, ExperimentVO, TeamSummaryVO } from 'ui-api';
import { Button, Flex, presets } from '@steadybit/ui-components-lib';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { Formik, useField, useFormikContext } from 'formik';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import React, { ReactElement, useEffect } from 'react';
import { suppressSubmitOnEnter } from 'utils/form';
import { Services } from 'services/services';
import { useObservable } from 'react-use';
import { theme } from 'styles.v2/theme';
import { ensure } from 'utils/ensure';
import { ReplaySubject } from 'rxjs';

type ConfirmOptions = {
	originalExperiment: ExperimentVO;
};

type DuplicateExperimentFormValues = {
	environmentId?: string;
	clearTargets?: boolean;
	hypothesis?: string;
	teamId?: string;
	name?: string;
};

export type DuplicateExperimentResult = DuplicateExperimentFormValues & {
	result: string;
};
type OutstandingConfirmation = ConfirmOptions & {
	resolve: (result: DuplicateExperimentResult) => void;
};

const outstandingConfirmation$ = new ReplaySubject<OutstandingConfirmation | null>(1);

export const DuplicateExperimentConfirm: React.VFC = () => {
	const confirmation = useObservable(outstandingConfirmation$);
	const { environments } = useEnvironments();

	const teamId = confirmation?.originalExperiment.teamId;
	const environmentId = confirmation?.originalExperiment.environmentId || environments[0]?.id || '';

	const [teams] = useAsyncState(() => Services.teams.findTeams('', true), [], []);
	const isLoading = !teamId || !environmentId || teams.loading || !confirmation || environments.length === 0;

	return (
		<ModalOverlay
			open={Boolean(confirmation)}
			onClose={() => confirmation?.resolve?.({ result: 'cancel' })}
			zIndex={40}
		>
			{({ close }) => {
				const { originalExperiment } = { ...ensure(confirmation) };
				const initialTeamId: string = teams.value.find((t) => t.id === teamId)?.id ?? teams.value[0]?.id ?? teamId;
				const environmentsOfSelectedTeam = environments.filter(
					(environment) => !!environment.teams.find((ts) => ts.id === teamId),
				);
				const initialEnvironmentId: string =
					environmentsOfSelectedTeam.find((environment) => environment.id === environmentId)?.id ??
					environmentsOfSelectedTeam[0]?.id ??
					environmentId;

				return (
					<Formik
						initialValues={
							{
								...originalExperiment,
								name: `Copy of ${originalExperiment.name}`,
								teamId: initialTeamId,
								environmentId: initialEnvironmentId,
							} as DuplicateExperimentFormValues
						}
						validateOnBlur={false}
						enableReinitialize
						onSubmit={(values) => {
							confirmation?.resolve?.({ result: 'duplicate', ...values });
						}}
					>
						{({ submitForm, values }) => {
							return (
								<Form noValidate sx={{ display: 'contents' }} onKeyDown={suppressSubmitOnEnter}>
									<Dialog>
										<DialogHeader title={'Duplicate Experiment'} onClose={close} />
										<Divider my={'small'} />
										<DialogContent>
											{isLoading ? (
												<LoadingContent />
											) : (
												<DuplicateExperimentForm
													originalEnvironmentId={originalExperiment.environmentId}
													teams={teams.value}
													environments={environments}
												/>
											)}
										</DialogContent>
										<Divider mt={'small'} />
										<DialogFooter my={'small'}>
											<Flex direction="horizontal" justify="spread" style={{ width: '100%' }}>
												<Button
													type="secondary"
													onClick={() => {
														confirmation?.resolve({ result: 'cancel', ...values });
													}}
												>
													Cancel
												</Button>

												<Button type="primary" onClick={submitForm} disabled={isLoading} data-cy="duplicateExperiment">
													Duplicate
												</Button>
											</Flex>
										</DialogFooter>
									</Dialog>
								</Form>
							);
						}}
					</Formik>
				);
			}}
		</ModalOverlay>
	);
};

function LoadingContent(): ReactElement {
	return (
		<Container
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				py: 128,
			}}
		>
			<LoadingIndicator variant="xxLarge" color="slate" />
		</Container>
	);
}

const DuplicateExperimentForm: React.VFC<{
	originalEnvironmentId: string;
	teams: TeamSummaryVO[];
	environments: EnvironmentSummaryVO[];
}> = ({ originalEnvironmentId, teams, environments }) => {
	const formik = useFormikContext<DuplicateExperimentFormValues>();
	const { clearTargets, teamId, environmentId } = formik.values;

	const showWarning = environmentId && environmentId !== originalEnvironmentId && !clearTargets;

	return (
		<Stack>
			<FormikTextField label={'Name'} name={'name'} maxLength={255} autoComplete={'off'} />
			<FormikTextField
				label={'Description (optional)'}
				name={'hypothesis'}
				maxLength={255}
				autoComplete={'off'}
				as="textarea"
			/>
			<Flex spacing="xxSmall">
				<Label>Team</Label>
				<TeamSelection teams={teams} />
			</Flex>

			<Flex spacing="xxSmall">
				<Label>Environment</Label>
				<EnvironmentSelection environments={environments} teamId={teamId} />
				{showWarning && (
					<Container bg={'coral100'} sx={{ borderRadius: '3px', borderLeft: '4px solid', borderLeftColor: 'coral700' }}>
						<Container mx={'small'} mt="xSmall">
							<Label variant={'medium'} color={'neutral700'}>
								Changing the environment may prevent the experiment from running, because inconsistent targets would not
								be reachable.
							</Label>
						</Container>
					</Container>
				)}
			</Flex>

			<Label>
				Clear Targets
				<FormikCheckbox
					mt={'xSmall'}
					name={'clearTargets'}
					label={'Remove all target information when duplicating'}
					variant={'small'}
					sx={{
						'&& > label': {
							...theme.text.medium,
						},
					}}
				/>
			</Label>
		</Stack>
	);
};

export function duplicateExperimentConfirm(originalExperiment: ExperimentVO): Promise<DuplicateExperimentResult> {
	return new Promise<DuplicateExperimentResult>((resolve) => {
		outstandingConfirmation$.next({
			resolve: (result: DuplicateExperimentResult) => {
				outstandingConfirmation$.next(null);
				resolve(result);
			},
			originalExperiment,
		});
	});
}

interface TeamSelectionProps {
	teams: TeamSummaryVO[];
}

function TeamSelection({ teams }: TeamSelectionProps): ReactElement {
	const [{ value }, , { setValue }] = useField('teamId');

	const teamOptions = teams.map(({ id, name }) => ({
		id: id,
		label: name,
	}));

	return (
		<presets.dropdown.SingleChoiceButton
			placement="bottom-start"
			selectedId={value}
			maxContentHeight="400px"
			items={teamOptions}
			onSelect={setValue}
			style={{ width: '100%' }}
		>
			{teams.find((team) => team.id === value)?.name || value}
		</presets.dropdown.SingleChoiceButton>
	);
}

interface EnvironmentSelectionProps {
	environments: EnvironmentSummaryVO[];
	teamId: string | undefined;
}

function EnvironmentSelection({ environments, teamId }: EnvironmentSelectionProps): ReactElement {
	const [{ value }, , { setValue }] = useField('environmentId');

	const teamEnvironments = environments.filter((environment) => !!environment.teams.find((ts) => ts.id === teamId));

	const selectedEnvironment = teamEnvironments.find((environment) => environment.id === value);
	useEffect(() => {
		if (!selectedEnvironment) {
			// If the selected environment is not in the selected team, take the first environment of the team
			setValue(teamEnvironments[0]?.id);
		}
	}, [teamId]);

	const environmentOptions = teamEnvironments.map((environment) => ({
		id: environment.id,
		label: environment.name,
	}));

	return (
		<presets.dropdown.SingleChoiceButton
			placement="bottom-start"
			selectedId={value}
			maxContentHeight="400px"
			items={environmentOptions}
			onSelect={setValue}
			style={{ width: '100%' }}
		>
			{selectedEnvironment?.name || value}
		</presets.dropdown.SingleChoiceButton>
	);
}
