/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Divider, Link, Stack, Text, userConfirmV2 } from 'components';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import VariableInputsForm from 'components/Variables/VariableInputsForm';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import Variables from 'components/Variables/Variables';
import { IconAdd } from 'components/icons';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';
import { useField } from 'formik';

interface EnvironmentAndExperimentVariablesProps {
	width: string | number | undefined;
	allowAddNew?: boolean;
	onVariableSelected: (variable: string) => void;
}

export default function EnvironmentAndExperimentVariables({
	allowAddNew = true,
	width,
	onVariableSelected,
}: EnvironmentAndExperimentVariablesProps): ReactElement | null {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>('variables');
	const [{ value: experimentVariables = [] }, , { setValue, setTouched }] =
		useField<VariableVO[]>('experimentVariables');

	const { mode: editorMode } = useEditorSettings();

	return (
		<Stack size="none">
			<Stack size="xSmall" py="small" style={{ width, flexGrow: 1 }}>
				<Variables
					scope="experiment"
					variables={experimentVariables}
					onVariableClick={(key) => onVariableSelected(`{{${key}}}`)}
				/>
				<Variables
					scope="environment"
					variables={environmentVariables.filter(
						(envVar) => !experimentVariables.some((expVar) => expVar.key === envVar.key),
					)}
					onVariableClick={(key) => onVariableSelected(`{{${key}}}`)}
				/>
			</Stack>
			<Divider />
			{allowAddNew && (
				<div style={{ display: 'flex' }}>
					<Button
						variant="chromelessSmall"
						onClick={async () => {
							await userConfirmV2({
								title: 'Add new experiment variable',
								width: '900px',
								message: ({ setDisabled }) => (
									<AddExperimentVariableContent
										variables={experimentVariables}
										environmentId={environmentId}
										setDisabled={setDisabled}
									/>
								),
								actions: [
									{
										value: 'confirm',
										label: 'Add variable',
										variant: 'primary',
										dataCy: 'create-env-var',
										action: async () => {
											const key = (document.getElementById('variable-key') as HTMLInputElement)?.value;
											const value = (document.getElementById('variable-value') as HTMLInputElement)?.value;

											if (key && value) {
												if (editorMode === 'experiment') {
													setValue([...experimentVariables, { key, value }]);
													setTouched(true);
												}
												onVariableSelected(`{{${key}}}`);
											}
										},
									},
								],
								secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
							});
						}}
						data-cy="add-env-var"
					>
						<IconAdd variant="small" mr="xxSmall" />
						<span style={{ ...textEllipsis }}>Add experiment variable</span>
					</Button>
				</div>
			)}
		</Stack>
	);
}

function AddExperimentVariableContent({
	environmentId,
	variables,
	setDisabled,
}: {
	variables: VariableVO[];
	environmentId: string;
	setDisabled: (disabled: boolean) => void;
}): ReactElement {
	return (
		<Stack size="small">
			<Text as="span">
				You can use this variable within the scope of this{' '}
				<Text variant="mediumStrong" as="span">
					experiment
				</Text>
				.
			</Text>
			<VariableInputsForm existingVariables={variables} variableValue="" variableKey="" setHasErrors={setDisabled} />
			<Text as="span" variant="medium" color="neutral600">
				Do you need to create an environment variable? You can create one directly from the
				<Link href={`/settings/environments/${environmentId}/variables`} ml="xxSmall" external dontResolve>
					<Text as="span" variant="mediumStrong" color="slate">
						environment settings
					</Text>
				</Link>
				.
			</Text>
		</Stack>
	);
}
