/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	FormikCheckbox,
	hasError,
	Label,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	SettingsGroup,
	SettingsGroupItem,
	SettingsGroupProps,
	Stack,
	Text,
	Tooltip,
} from 'components';
import ReadOnlyEnvironmentDetails from 'pages/settings/environments/config/ReadOnlyEnvironmentDetails';
import { IconAdd, IconEnvironment, IconEnvironmentGlobal } from 'components/icons';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { EnvironmentSummaryVO, TeamVO } from 'ui-api';
import React, { ReactNode } from 'react';
import { useField } from 'formik';

import CreateNewEnvironmentInsideTeam from './CreateNewEnvironmentInsideTeam';
import ListSelectButton from './ListSelectButton';

type EnvironmentChooserProps = SettingsGroupProps & {
	environments: EnvironmentSummaryVO[];
	fieldName: string;
	addEnvironmentEnabled?: boolean;
	onlyPermittedByTeam?: boolean;
	disabledExplanation?: ReactNode;
	team?: TeamVO;
	loading?: boolean;
};

const EnvironmentSelectorMultiSelect: React.VFC<EnvironmentChooserProps> = ({
	environments,
	fieldName,
	addEnvironmentEnabled = false,
	onlyPermittedByTeam = false,
	team,
	disabled,
	disabledExplanation,
	loading = false,
	...props
}) => {
	const [showDetails, setShowDetails] = React.useState<EnvironmentSummaryVO>();
	const [showAddEnvironment, setShowAddEnvironment] = React.useState(false);
	const [field, meta, fieldHelpers] = useField(fieldName);
	const permissions = useGlobalPermissions();
	const selectedEnvironmentIds: string[] = field.value || [];

	const isEnvironmentSelected = (environmentId: string): boolean =>
		selectedEnvironmentIds.some((value) => value === environmentId);
	const isEnvironmentPermitted = (environmentId: string): boolean =>
		!!team && team.allowedEnvironments.includes(environmentId);
	const showPermissionWarning = (environmentId: string): boolean =>
		onlyPermittedByTeam && !isEnvironmentPermitted(environmentId) && !loading;

	const handleChangeCheck = (environmentId: string): void => {
		if (isEnvironmentSelected(environmentId)) {
			fieldHelpers.setValue(selectedEnvironmentIds.filter((selected) => selected !== environmentId));
		} else {
			fieldHelpers.setValue([...selectedEnvironmentIds, environmentId]);
		}
	};
	const selectableEnvironments = environments.filter(
		(environment) =>
			!onlyPermittedByTeam || isEnvironmentSelected(environment.id) || isEnvironmentPermitted(environment.id),
	);

	const isAllSelected = selectableEnvironments.length === selectedEnvironmentIds.length;
	const isSomeSelected = selectedEnvironmentIds.length > 0;

	return selectableEnvironments.length > 0 ? (
		<>
			{showDetails && (
				<ModalOverlay open centerContent onClose={() => setShowDetails(undefined)}>
					{({ close }) => (
						<ModalV2 slick width="90vw" minHeight="calc(100vh - 4rem)">
							<ModalHeaderV2 title={showDetails.name} onClose={close} />
							<ModalContentV2>
								<ReadOnlyEnvironmentDetails environment={showDetails} />
							</ModalContentV2>
						</ModalV2>
					)}
				</ModalOverlay>
			)}
			<Stack size="none">
				<Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Stack direction="vertical" size="xxSmall" mr={'small'}>
						<Label>Select Allowed Environments</Label>
						<Text variant={'small'} color={'neutral600'}>
							Environments define the target set that this team is allowed to use in an Experiment or the Explorer.
						</Text>
					</Stack>

					{!disabled && (
						<Stack direction="horizontal" size="xSmall" sx={{ whiteSpace: 'nowrap', alignItems: 'end' }}>
							{!isAllSelected && (
								<ListSelectButton
									label="Select all"
									onClick={() => fieldHelpers.setValue(environments.map((env) => env.id))}
								/>
							)}
							{!isAllSelected && isSomeSelected && <Text>/</Text>}
							{isSomeSelected && <ListSelectButton label="Clear all" onClick={() => fieldHelpers.setValue([])} />}
						</Stack>
					)}
				</Container>
				<SettingsGroup {...props} hasError={hasError(meta)}>
					{selectableEnvironments.map((environment) => {
						return (
							<SettingsGroupItem
								key={environment.id}
								sx={{
									'& + [data-settings-group-item], [data-settings-group-item] + &, [data-settings-group-header] + &': {
										borderTop: '1px solid',
										borderColor: 'neutral200',
									},
								}}
								onClick={
									!disabled
										? (e) => {
												e.preventDefault();
												e.stopPropagation();
												handleChangeCheck(environment.id);
											}
										: undefined
								}
							>
								<Container display={'flex'} alignItems={'center'}>
									<Tooltip disabled={!disabledExplanation} content={disabledExplanation}>
										<FormikCheckbox name={fieldName} label="" value={environment.id} disabled={disabled} />
									</Tooltip>
									<Container mx={'small'} bg={'neutral200'} height={40} width={'1px'} />
									<EnvironmentLabel environment={environment} />
									{showPermissionWarning(environment.id) ? (
										<Text ml={'small'} variant={'mediumStrong'} color={'coral'}>
											No Permissions for Environment!
										</Text>
									) : null}
									<Button
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											setShowDetails(environment);
										}}
										color={'purple700'}
										variant={'chromeless'}
										ml={'auto'}
									>
										<Text variant={'smallStrong'}>Details</Text>
									</Button>
								</Container>
							</SettingsGroupItem>
						);
					})}
					{addEnvironmentEnabled && (
						<SettingsGroupItem
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShowAddEnvironment(true);
							}}
						>
							<Tooltip
								content={'You reached your maximum number of environments.'}
								disabled={permissions.environments.canCreate}
							>
								<Button
									variant={'chromeless'}
									disabled={!(addEnvironmentEnabled && permissions.environments.canCreate)}
									color={'neutral600'}
								>
									<IconAdd mr={'xSmall'} />
									<Text variant="mediumStrong">Add new Environment</Text>
								</Button>
							</Tooltip>
						</SettingsGroupItem>
					)}
				</SettingsGroup>
			</Stack>
			<ModalOverlay open={showAddEnvironment} onClose={() => setShowAddEnvironment(false)}>
				{({ close }) => (
					<ModalV2 width="90vw" slick withFooter>
						<ModalHeaderV2 onClose={close} title={undefined} />
						<ModalContentV2>
							<CreateNewEnvironmentInsideTeam
								onCreated={(createdEnv) => {
									close();
									fieldHelpers.setValue([...selectedEnvironmentIds, createdEnv.id]);
								}}
							/>
						</ModalContentV2>
					</ModalV2>
				)}
			</ModalOverlay>
		</>
	) : loading ? null : (
		<Text variant={'mediumStrong'} color={'coral'} mt={'xSmall'}>
			No Environments allowed for this Team.
		</Text>
	);
};

export default EnvironmentSelectorMultiSelect;

const EnvironmentLabel: React.VFC<{ environment: EnvironmentSummaryVO }> = ({ environment }) => {
	return (
		<>
			{environment.global ? <IconEnvironmentGlobal /> : <IconEnvironment />}
			<Label ml={'xxSmall'} variant={'mediumMedium'}>
				{environment.name}
			</Label>
		</>
	);
};
