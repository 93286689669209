/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { ExperimentExecutionPreflightVO } from 'ui-api';
import { formatTime } from 'utils/dateFns';
import { ReactElement } from 'react';
import { Text } from 'components';

import { getColorForState, getIconForState } from '../utils';

interface LogLinePreflightProps {
	preflight: ExperimentExecutionPreflightVO;
}

export default function LogLinePreflight({ preflight }: LogLinePreflightProps): ReactElement {
	const { name, state, checkedTimestamp } = preflight;

	const successful = state === 'SUCCESSFUL';
	const Icon = getIconForState(state);
	const isBold = !successful;
	const color = successful ? 'neutral700' : getColorForState(state);

	function getState(): string | null {
		switch (state) {
			case 'CREATED':
				return ' created';
			case 'SUCCESSFUL':
				return ' succeeded';
			case 'FAILED':
				return ' failed';
			case 'ERRORED':
				return ' errored';
		}
		return null;
	}

	return (
		<Flex direction="horizontal" align="center" spacing="xxSmall">
			<Icon variant="small" minWidth={16} maxWidth={16} color={color} sx={{ mr: 'xSmall' }} />

			{checkedTimestamp && (
				<Text
					variant={isBold ? 'smallStrong' : 'small'}
					sx={{
						fontFamily: 'code',
						color,
						whiteSpace: 'nowrap',
						mr: 'small',
						minWidth: 'fit-content',
					}}
				>
					{formatTime(new Date(checkedTimestamp))}
				</Text>
			)}

			<Text variant={isBold ? 'smallStrong' : 'small'} sx={{ color }}>
				preflight check{preflight.type === 'com.steadybit.experiment.execution.preflight.webhook' && ' webhook'}
			</Text>

			<presets.pill.Tag small>{name}</presets.pill.Tag>

			<Text variant={isBold ? 'smallStrong' : 'small'} sx={{ color }}>
				{getState()}
				{preflight.reason ? `: ${preflight.reason}` : null}
			</Text>
		</Flex>
	);
}
