/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode } from 'react';

interface DropDownItemWrapperProps {
	children: ReactNode | ReactNode[];
	highlighted?: boolean;
	onClick: () => void;
	vertical?: boolean;
}

export default function DropDownItemWrapper({
	vertical,
	highlighted,
	children,
	onClick,
}: DropDownItemWrapperProps): ReactElement {
	return (
		<Flex
			direction={vertical ? 'vertical' : 'horizontal'}
			align={vertical ? 'start' : 'center'}
			spacing="xSmall"
			style={{
				py: 'xSmall',
				px: 'xSmall',
				borderRadius: 'xxSmall',
				width: '100%',
				backgroundColor: highlighted ? Colors.purple100 : 'transparent',
				onHover: {
					cursor: 'pointer',
					backgroundColor: Colors.purple100,
				},
			}}
			onClick={onClick}
		>
			{children}
		</Flex>
	);
}
