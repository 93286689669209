/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Pill, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface VersionTagProps {
	multipleVersions?: boolean;
	unversioned?: boolean;
}

export default function VersionTag({ multipleVersions, unversioned }: VersionTagProps): ReactElement {
	return (
		<Pill
			withLeftIcon="error"
			style={{
				backgroundColor: Colors.feedbackWarningLightPill,
				color: Colors.feedbackWarningDark,
			}}
		>
			<Text type="smallStrong">{multipleVersions ? 'Multiple Versions' : unversioned ? 'Unversioned' : ''}</Text>
		</Pill>
	);
}
