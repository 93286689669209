/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Link,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Snackbar,
	Stack,
	Text,
} from 'components';
import HubSynchronization, { WaitingContent } from 'templates/components/CreateTemplateModal/HubSynchronization';
import { TemplatesTable } from 'templates/components/CreateTemplateModal/ChooseTemplatesFromHub';
import { templateImportConflictConfirm } from 'templates/importConflictQuestion';
import { HubLine } from 'templates/components/CreateTemplateModal/ChooseHub';
import { useLicenseFeature } from 'services/licenseApi';
import { HubVO, TemplateSummaryVO } from 'ui-api';
import { IconArrowLeft } from 'components/icons';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { isUserAdmin } from 'utils/user';

interface ImportTemplatesModalProps {
	description?: string;
	cancelText: string;
	pathname: string;
	title: string;
	hub: HubVO;
	onGoBackOnError?: () => void;
	onClose: () => void;
}

export default function ImportTemplatesModal({
	description,
	cancelText,
	pathname,
	title,
	hub,
	onGoBackOnError,
	onClose,
}: ImportTemplatesModalProps): ReactElement {
	const [selectedTemplates, setSelectedTemplates] = useState<TemplateSummaryVO[]>([]);
	const templatesEnabled = useLicenseFeature('TEMPLATES');

	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	if (!templatesEnabled) {
		return (
			<ModalOverlay open centerContent onClose={onClose}>
				<ModalV2 slick withFooter width="90vw" maxWidth="1080px">
					<ModalHeaderV2 title={title} subTitle={description} onClose={onClose} />

					<ModalContentV2>
						<Stack my="small" size="large">
							<HubLine hub={hub} first isSelected isSingle />
							<Text color="neutral600" textAlign="center">
								Templates are not included in your current plan.
								<br />
								{isAdmin ? (
									<Link href="https://steadybit.com/contact-us/" external>
										Contact us
									</Link>
								) : (
									'Contact your admin'
								)}{' '}
								to request this feature.
							</Text>
						</Stack>
					</ModalContentV2>

					<ModalFooterV2>
						<Stack direction="horizontal" size="small" justifyContent="flex-end" width="100%">
							<Button variant="primary" onClick={onClose}>
								Done
							</Button>
						</Stack>
					</ModalFooterV2>
				</ModalV2>
			</ModalOverlay>
		);
	}

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			<HubSynchronization hubId={hub.id}>
				{([syncState, refresh]) => {
					if (!syncState.completed) {
						return (
							<ModalV2 slick withFooter width="90vw" maxWidth="1080px">
								<ModalHeaderV2 title="" onClose={onClose} />

								<ModalContentV2>
									<WaitingContent />
								</ModalContentV2>

								<ModalFooterV2>
									<Stack direction="horizontal" size="small" justifyContent="space-between" width="100%">
										<Button variant="secondary" onClick={onClose}>
											Cancel
										</Button>
										<Button variant="primary" disabled>
											Import Selection
										</Button>
									</Stack>
								</ModalFooterV2>
							</ModalV2>
						);
					}
					return (
						<ModalV2 slick withFooter width="90vw" maxWidth="1080px">
							<ModalHeaderV2 title={title} subTitle={description} onClose={onClose} />
							<ModalContentV2>
								<Stack my="small">
									<HubLine hub={hub} syncError={syncState.error} first isSelected isSingle />
									<TemplatesTable
										hubId={hub.id}
										pathname={pathname}
										syncError={syncState.error}
										selectedTemplates={selectedTemplates}
										onRefresh={refresh}
										setSelectedTemplates={setSelectedTemplates}
									/>
								</Stack>
							</ModalContentV2>

							<ModalFooterV2>
								<Stack direction="horizontal" size="small" justifyContent="space-between" width="100%" mx="small">
									<Button variant="secondary" onClick={syncState.error && onGoBackOnError ? onGoBackOnError : onClose}>
										{syncState.error && onGoBackOnError ? (
											<>
												<IconArrowLeft mr="xSmall" ml="-xxSmall" />
												Back
											</>
										) : (
											cancelText
										)}
									</Button>
									<Button
										variant="primary"
										disabled={selectedTemplates.length === 0}
										onClick={async () => {
											try {
												await Services.templatesApi.importTemplatesFromHub(
													{
														hubId: hub.id,
														templateIds: selectedTemplates.map((t) => t.id),
													},
													false,
												);
												onClose();
											} catch (err) {
												if (err?.response.status === 409) {
													if (await templateImportConflictConfirm(selectedTemplates.length)) {
														await Services.templatesApi.importTemplatesFromHub(
															{
																hubId: hub.id,
																templateIds: selectedTemplates.map((t) => t.id),
															},
															true,
														);
														onClose();
													}
												} else {
													Snackbar.error('Templates could not be imported', {
														toastId: 'file-upload-error',
													});
													onClose();
												}
											}
										}}
									>
										Import Selection
									</Button>
								</Stack>
							</ModalFooterV2>
						</ModalV2>
					);
				}}
			</HubSynchronization>
		</ModalOverlay>
	);
}
