/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentLaneVO } from 'ui-api';
import { cloneDeep } from 'lodash';

export function replaceStringMarker(value: string | undefined, placeholder: string, newValue: string): string {
	if (!value) {
		return '';
	}

	return value.replaceAll(placeholder, newValue);
}

export function replaceLaneMarker(
	lanes: ExperimentLaneVO[],
	placeholder: string,
	newValue: string,
): ExperimentLaneVO[] {
	const copy = cloneDeep(lanes);
	return copy.map((lane) => {
		lane.steps = lane.steps.map((step) => {
			const copiedStep = { ...step };

			if (step.customLabel) {
				copiedStep.customLabel = step.customLabel.replaceAll(placeholder, newValue);
			}

			if (step.parameters) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				copiedStep.parameters = recursivelyReplace({ ...step.parameters }, placeholder, newValue);
			}

			if (step.metricQueries) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				copiedStep.metricQueries = step.metricQueries.map((query) => {
					return {
						...query,
						parameters: recursivelyReplace({ ...query.parameters }, placeholder, newValue),
					};
				});
			}

			if (step.type === 'action') {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				copiedStep.blastRadius = recursivelyReplace({ ...step.blastRadius }, placeholder, newValue);
			}

			return copiedStep;
		});
		return lane;
	});
}

// exported for testing
export function recursivelyReplace(value: unknown, str: string, replacer: string): unknown {
	if (!value) {
		return value;
	} else if (typeof value === 'string') {
		return value.replaceAll(str, replacer);
	} else if (Array.isArray(value)) {
		return value.map((v) => recursivelyReplace(v, str, replacer));
	} else if (typeof value === 'object') {
		const copy = {};
		Object.keys(value).map((key) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			copy[key] = recursivelyReplace(value[key], str, replacer);
		});
		return copy;
	}
	return value;
}

export function wrapWithPlaceholderMarkers(str: string): string {
	if (!str.startsWith('[[')) {
		str = `[[${str}`;
	}
	if (!str.endsWith(']]')) {
		str = `${str}]]`;
	}
	return str;
}

export function wrapWithEnvironmentVariableMarkers(str: string): string {
	if (!str.startsWith('{{')) {
		str = `{{${str}`;
	}
	if (!str.endsWith('}}')) {
		str = `${str}}}`;
	}
	return str;
}
