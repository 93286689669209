/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { ActionVO } from 'ui-api';

import Labeled from './Labeled';

interface ActionsProps {
	actionDefinitions: ActionVO[];
	actions: string[];
}

export default function Actions({ actions, actionDefinitions }: ActionsProps): ReactElement | null {
	if (!actions || actions.length === 0) {
		return null;
	}

	return (
		<Labeled title="Actions">
			<Flex direction="horizontal" spacing="xxSmall" wrap>
				{actions.map((actionId) => {
					const action = actionDefinitions.find((def) => def.id === actionId);
					return (
						<presets.pill.ActionTag key={actionId} kind={action?.kind} icon={action?.icon}>
							{action?.name || actionId}
						</presets.pill.ActionTag>
					);
				})}
			</Flex>
		</Labeled>
	);
}
