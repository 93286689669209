/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import { Colors, Dropdown, Pill, presets } from '@steadybit/ui-components-lib';
import { Stack, TutorialTooltip } from 'components';
import { useField, useFormikContext } from 'formik';
import { useUpdateEffect } from 'react-use';
import React, { useState } from 'react';
import { TUTORIALS } from 'tutorials';

import ExperimentAndEnvironmentVariables from './ExperimentAndEnvironmentVariables';
import { ExperimentFormValues } from '../../experiment';

export const ExperimentSubHeaderEnvironments: React.VFC<{
	disabled: boolean;
}> = ({ disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { teamId, environmentId, experimentVariables = [], variables = [] } = formik.values;
	const { setFieldValue } = formik;

	// when teamId changes reset environmentId
	useUpdateEffect(() => {
		setFieldValue('environmentId', '');
	}, [teamId, setFieldValue]);

	const [, environmentIdMeta] = useField('environmentId');
	const hasEnvironmentError = Boolean(environmentIdMeta.error);

	const hasVariablesError: boolean =
		Boolean(formik.errors.overallExperimentVariables) ||
		Boolean(formik.errors.experimentVariables) ||
		experimentVariables.some((variable) => !variable.value);

	const [isDeletingVariable, setIsDeletingVariable] = useState(false);
	const totalNumberOfVariables = variables.length + experimentVariables.length;

	return (
		<Stack flexShrink={0} direction={'horizontal'}>
			<Stack direction="horizontal" size="none">
				<EnvironmentSelector
					selectedEnvironmentId={environmentId}
					hasError={hasEnvironmentError}
					disabled={disabled}
					teamId={teamId}
					selectEnvironmentId={(_environmentId) => formik.setFieldValue('environmentId', _environmentId)}
				/>

				{!environmentId && (
					<TutorialTooltip
						light
						hideIcon
						hideSkip
						showAlways
						placement="right"
						{...TUTORIALS.experiments.noEnvironmentSelected}
					/>
				)}
			</Stack>

			<Dropdown<HTMLDivElement>
				disabledCloseOnClickAway={isDeletingVariable}
				placement="bottom-start"
				renderDropdownContent={() => (
					<presets.dropdown.DropdownContentFrame maxHeight="500px" style={{ borderRadius: 'xxSmall' }}>
						<ExperimentAndEnvironmentVariables
							environmentVariables={variables}
							setIsDeletingVariable={setIsDeletingVariable}
						/>
					</presets.dropdown.DropdownContentFrame>
				)}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<Pill
							ref={setRefElement}
							withLeftIcon="function"
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							type={hasVariablesError && !hasEnvironmentError ? 'error' : 'default'}
							disabled={disabled || hasEnvironmentError}
							style={
								totalNumberOfVariables > 0 && !hasVariablesError
									? {
											backgroundColor: isOpen ? Colors.purple500 : Colors.purple300,
											color: isOpen ? Colors.neutral000 : Colors.neutral800,
											onHover: {
												backgroundColor: Colors.purple500,
												color: Colors.neutral000,
											},
										}
									: undefined
							}
							onClick={disabled ? undefined : () => setOpen(!isOpen)}
						>
							{totalNumberOfVariables === 0 ? 'No variables' : `Variables (${totalNumberOfVariables})`}
						</Pill>
					);
				}}
			</Dropdown>
		</Stack>
	);
};
