/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { newExperimentTagsParam } from 'pages/templates/FromTemplateModal/urlParams';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { useLicenseFeature } from 'services/licenseApi';
import { ReactElement, Suspense, lazy } from 'react';
import { useUrlState } from 'url/useUrlState';
import { useHistory } from 'url/hooks';

import { UrlState } from '../urlParams';
import Card from './Card';

const Image = lazy(() => import('./images/Image'));

interface FromTemplateProps {
	newExperimentTags?: string[];
}
export default function FromTemplate({ newExperimentTags }: FromTemplateProps): ReactElement {
	const [, getUrlWithState] = useUrlState<UrlState>([newExperimentTagsParam]);
	const history = useHistory();

	const templatesEnabled = useLicenseFeature('TEMPLATES');

	return (
		<Card
			title="From template"
			onClick={
				templatesEnabled
					? () =>
							history.push(
								getUrlWithState(
									{
										newExperimentTags: newExperimentTags,
									},
									(location) => {
										location.pathname = '/experiments/from_templates';
									},
								),
							)
					: undefined
			}
			disabled={!templatesEnabled}
			tooltip={templatesEnabled ? undefined : <LicenseTooltipContent />}
			cypressTag="from-template"
		>
			<Suspense fallback={<div />}>
				<Image type="fromTemplate" />
			</Suspense>
		</Card>
	);
}
