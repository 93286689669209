/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory as ActionCategoryA, Grouping } from 'experiment/actions/useActionHierarchy';
import { Box, Button, Dropdown, Flex, presets, Text } from '@steadybit/ui-components-lib';
import { ReactElement, useEffect, useState } from 'react';
import { getGlobal, setGlobal } from 'utils/localStorage';
import { theme } from 'styles.v2/theme';
import { ActionVO } from 'ui-api';
import { ampli } from 'ampli';

import { ActionCategory, ActionCategoryItem } from './types';
import ActionGroup from './ActionGroup';

interface ActionsContentProps {
	selectedActionId?: string;
	isSearchDefined: boolean;
	collapsed?: boolean;
	actions: ActionVO[];
	onActionClick: (action: ActionCategoryItem | null) => void;
}

export default function ActionsContent({
	selectedActionId,
	isSearchDefined,
	collapsed,
	actions,
	onActionClick,
}: ActionsContentProps): ReactElement {
	const [selectedGrouping, setSelectedGrouping] = useState<Grouping>(
		getGlobal('experimentActionGrouping') === 'targetType' ? 'targetType' : 'technology',
	);
	const actionCategories = useActionHierarchy({ actions, grouping: selectedGrouping });

	useEffect(() => {
		ampli.experimentSidebarGrouped({
			group_by: selectedGrouping === 'targetType' ? 'legacy' : 'technology',
		});
	}, [selectedGrouping]);

	return (
		<Flex
			className="experiment-collapsed-sidebar"
			style={{
				overflowY: 'auto',
				py: 'xSmall',
				px: collapsed ? 'none' : 'xSmall',
				width: collapsed ? 'calc(100% + 16px)' : '100%',
			}}
		>
			{actions.length === 0 ? (
				<Box style={{ p: 'small' }}>
					<Text type="medium" style={{ color: theme.colors.neutral500 }}>
						{isSearchDefined ? 'No actions matched your query.' : 'No actions found.'}
					</Text>
				</Box>
			) : (
				<Flex style={{ width: '100%' }}>
					{!collapsed && (
						<Dropdown<HTMLButtonElement>
							renderDropdownContent={({ width, close }) => (
								<presets.dropdown.DropdownContentFrame width={width}>
									<presets.dropdown.SingleChoiceList
										items={[
											{
												id: 'technology',
												label: 'Technology',
												isSelected: selectedGrouping === 'technology',
											},
											{
												id: 'targetType',
												label: 'Target Type',
												isSelected: selectedGrouping === 'targetType',
											},
										]}
										onSelect={(v) => {
											setSelectedGrouping(v as Grouping);
											setGlobal('experimentActionGrouping', v);
											ampli.experimentSidebarGrouped({
												group_by: v === 'targetType' ? 'legacy' : 'technology',
											});
											close();
										}}
									/>
								</presets.dropdown.DropdownContentFrame>
							)}
						>
							{({ setRefElement, isOpen, setOpen }) => {
								return (
									<Button
										ref={setRefElement}
										withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
										withLeftIcon="advanced"
										type="chromeless"
										style={{
											width: '100%',
											marginBottom: '12px',
											border: '1px solid ' + theme.colors.neutral200,
										}}
										onClick={() => setOpen(!isOpen)}
									>
										{selectedGrouping === 'targetType' ? 'Target Type' : 'Technology'}
									</Button>
								);
							}}
						</Dropdown>
					)}

					{actionCategories.map((category) => (
						<ActionGroup
							selectedActionId={selectedActionId}
							group={mapCategory(category)}
							collapsed={collapsed}
							key={category.label}
							onActionClick={onActionClick}
						/>
					))}
				</Flex>
			)}
		</Flex>
	);
}

function mapCategory(category: ActionCategoryA): ActionCategory {
	return {
		...category,
		actions: category.actions
			? category.actions.map((a) => ({
					id: a.action.id,
					label: a.label,
				}))
			: undefined,
		subCategories: category.subCategories?.map((subCategory) => ({
			...subCategory,
			actions: subCategory.actions.map((a) => ({
				id: a.action.id,
				label: a.label,
			})),
		})),
	};
}
