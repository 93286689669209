/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { TargetTypeDescriptionVO } from 'ui-api';
import { ReactElement } from 'react';

import Labeled from './Labeled';

interface TargetsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targets: string[];
}

export default function Targets({ targets, targetDefinitions }: TargetsProps): ReactElement | null {
	if (!targets || targets.length === 0) {
		return null;
	}

	return (
		<Labeled title="Targets">
			<Flex direction="horizontal" spacing="xxSmall" wrap>
				{targets.map((targetId, i) => {
					const targetDefinition = targetDefinitions.find((def) => def.id === targetId);
					return (
						<presets.pill.TargetTypeTag key={i} icon={targetDefinition?.icon}>
							{targetDefinition?.label.one || targetId}
						</presets.pill.TargetTypeTag>
					);
				})}
			</Flex>
		</Labeled>
	);
}
