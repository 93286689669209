/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Flex, Text } from '@steadybit/ui-components-lib';
import TeamIcon from 'pages/settings/teams/components/TeamIcon';
import { useUser } from 'services/usersApi';
import { Checkbox } from 'components';
import { ReactElement } from 'react';
import { TeamVO } from 'ui-api';

import DropDownItemWrapper from './DropDownItemWrapper';
import NotFoundResult from './NotFoundResult';

interface TeamsProps {
	width: string | number | undefined;
	selectedTeamIds: string[];
	teams: TeamVO[];
	value: string;
	deselectIds: (teamIds: string[]) => void;
	selectIds: (teamIds: string[]) => void;
	toggleId: (teamId: string) => void;
}

export default function Teams({
	selectedTeamIds,
	width,
	teams,
	value,
	deselectIds,
	toggleId,
	selectIds,
}: TeamsProps): ReactElement {
	const user = useUser();
	const filteredTeams = teams.filter((team) => {
		if (!value) {
			return true;
		}
		const matcher = value.toLowerCase();
		return team.name.toLowerCase().includes(matcher) || team.key.toLowerCase().includes(matcher);
	});

	if (filteredTeams.length === 0) {
		return <NotFoundResult title="No teams found" width={width} />;
	}

	const myTeams: TeamVO[] = [];
	const otherTeams: TeamVO[] = [];
	filteredTeams.forEach((team) => {
		if (team.members.some((member) => member.username === user.username)) {
			myTeams.push(team);
		} else {
			otherTeams.push(team);
		}
	});

	const allMyTeamsSelected = myTeams.every((team) => selectedTeamIds.indexOf(team.id) !== -1);
	const allOtherTeamsSelected = otherTeams.every((team) => selectedTeamIds.indexOf(team.id) !== -1);

	return (
		<>
			{myTeams.length > 0 && (
				<>
					<Flex
						direction="horizontal"
						align="center"
						justify="spread"
						spacing="xSmall"
						style={{ width: '100%', py: 'xSmall', px: 'xSmall' }}
					>
						<Text type="small" style={{ color: Colors.neutral600 }}>
							YOUR TEAMS
						</Text>
						<Text
							type="smallStrong"
							style={{ color: Colors.slate, onHover: { cursor: 'pointer', textDecoration: 'underline' } }}
							onClick={() =>
								allMyTeamsSelected
									? deselectIds(myTeams.map((team) => team.id))
									: selectIds(myTeams.map((team) => team.id))
							}
						>
							{allMyTeamsSelected ? 'Deselect All' : 'Select All'}
						</Text>
					</Flex>
					{myTeams.map((team) => {
						return (
							<TeamItem
								key={team.id}
								selected={selectedTeamIds.indexOf(team.id) !== -1}
								team={team}
								onSelect={(team) => toggleId(team.id)}
							/>
						);
					})}
				</>
			)}
			{otherTeams.length > 0 && (
				<>
					<Flex
						direction="horizontal"
						align="center"
						justify="spread"
						spacing="xSmall"
						style={{ width: '100%', py: 'xSmall', px: 'xSmall' }}
					>
						<Text type="small" style={{ color: Colors.neutral600 }}>
							OTHER TEAMS (view only)
						</Text>
						<Text
							type="smallStrong"
							style={{ color: Colors.slate, onHover: { cursor: 'pointer', textDecoration: 'underline' } }}
							onClick={() =>
								allOtherTeamsSelected
									? deselectIds(otherTeams.map((team) => team.id))
									: selectIds(otherTeams.map((team) => team.id))
							}
						>
							{allOtherTeamsSelected ? 'Deselect All' : 'Select All'}
						</Text>
					</Flex>
					{otherTeams.map((team) => {
						return (
							<TeamItem
								key={team.id}
								selected={selectedTeamIds.indexOf(team.id) !== -1}
								team={team}
								onSelect={(team) => toggleId(team.id)}
							/>
						);
					})}
				</>
			)}
		</>
	);
}

interface TeamItemProps {
	selected: boolean;
	team: TeamVO;
	onSelect: (team: TeamVO) => void;
}

function TeamItem({ selected, team, onSelect }: TeamItemProps): ReactElement {
	const user = useUser();
	return (
		<DropDownItemWrapper onClick={() => onSelect(team)}>
			<Checkbox checked={selected} onChange={() => {}} />

			<TeamIcon
				userIsMember={!!team.members.find((member) => member.username === user.username)}
				color={team.logoColor}
				logoId={team.logoId}
				variant="small"
			/>

			<Text
				type={selected ? 'smallStrong' : 'smallMedium'}
				style={{ color: selected ? Colors.slate : Colors.neutral600 }}
			>
				{team.name} ({team.key})
			</Text>
		</DropDownItemWrapper>
	);
}
