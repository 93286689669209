/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useCallback, useEffect } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { TemplateFormValues } from './types';

/**
 * We want to have the backend the only source of truth for extracting variables and placeholders.
 * Since some places rely on the metadata, I put this into a separate component and enrich the form with it.
 */
export default function MetadataEnrichment(): null {
	const { values, setFieldValue } = useFormikContext<TemplateFormValues>();

	const extractMetadata = useCallback(
		debounce(
			async (v: TemplateFormValues) => {
				const metadata = await Services.templatesApi.getTemplateMetadata({ id: '', ...v });
				setFieldValue('metadata', metadata);
			},
			500,
			{ leading: true },
		),
		[],
	);

	const [stableId, stableData] = useStableInstance<TemplateFormValues>({
		...values,
		variables: [],
		placeholders: [],
		metadata: undefined,
	});
	useEffect(() => {
		extractMetadata(stableData);
	}, [stableId, extractMetadata]);

	return null;
}
