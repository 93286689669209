/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { TextInput } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

interface ListHeaderSearchProps {
	title: string;
	value: string;
	setValue: (value: string) => void;
}
export default function ListHeaderSearch({ value, title, setValue, ...props }: ListHeaderSearchProps): ReactElement {
	return (
		<TextInput
			// copy props to keep data- attributes
			{...props}
			value={value}
			placeholder={title}
			withLeftIcon="search"
			onChange={setValue}
			style={{ width: '300px' }}
		/>
	);
}
