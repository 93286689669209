/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { CreateExperimentRequest, ExperimentLaneVO, ExperimentStepActionVO, TrackingCreationMethodVO } from 'ui-api';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

import {
	replaceLaneMarker,
	replaceStringMarker,
	wrapWithEnvironmentVariableMarkers,
	wrapWithPlaceholderMarkers,
} from '../utils';
import { UseTemplateFormData } from './UseTemplateForm';

interface ReplaceMarkersProps {
	formData: UseTemplateFormData;
	replaceEmptyValues: boolean;
	replacePlaceholders: boolean;
	replaceVariables: boolean;
}

export function replaceMarkers({
	formData,
	replaceEmptyValues,
	replacePlaceholders,
	replaceVariables,
}: ReplaceMarkersProps): UseTemplateFormData {
	const { __originalExperimentName, __originalHypothesis, placeholderValuesMap, variableValuesMap } = formData;
	let lanes = cloneDeep(formData.__originalLanes);
	let experimentName = __originalExperimentName;
	let hypothesis = __originalHypothesis;

	if (replacePlaceholders) {
		for (const [key, value] of placeholderValuesMap.entries()) {
			if (!replaceEmptyValues && !value) {
				continue;
			}
			let valueToUse = value;
			if (!value) {
				valueToUse = '';
			}

			lanes = replaceLaneMarker(lanes, wrapWithPlaceholderMarkers(key), valueToUse);
			experimentName = replaceStringMarker(experimentName, wrapWithPlaceholderMarkers(key), valueToUse);
			hypothesis = replaceStringMarker(hypothesis, wrapWithPlaceholderMarkers(key), valueToUse);
		}
	}
	if (replaceVariables) {
		for (const [key, value] of variableValuesMap.entries()) {
			if (replaceEmptyValues && !value) {
				continue;
			}
			lanes = replaceLaneMarker(lanes, wrapWithEnvironmentVariableMarkers(key), value);
			experimentName = replaceStringMarker(experimentName, wrapWithPlaceholderMarkers(key), value);
			hypothesis = replaceStringMarker(hypothesis, wrapWithPlaceholderMarkers(key), value);
		}
	}

	return {
		...formData,
		lanes,
		experimentName,
		hypothesis,
	};
}

export function createExperimentRequestFromTemplate({
	capNameAndHypothesis = false,
	experimentCreationMethod,
	formData,
	teamId,
	tags,
}: {
	experimentCreationMethod?: TrackingCreationMethodVO;
	capNameAndHypothesis?: boolean;
	formData: UseTemplateFormData;
	tags?: string[];
	teamId: string;
}): CreateExperimentRequest {
	formData = replaceMarkers({ formData, replaceEmptyValues: true, replacePlaceholders: true, replaceVariables: false });

	reassignNewStepIds(formData.lanes);

	const experimentName = formData.experimentName || '';
	const hypothesis = formData.hypothesis || '';

	return {
		name: capNameAndHypothesis ? experimentName.substring(0, 255) : experimentName,
		hypothesis: capNameAndHypothesis ? hypothesis.substring(0, 20_000) : hypothesis,
		teamId,
		environmentId: formData.environmentId,
		templateId: formData.id,
		templateTitle: formData.templateTitle,
		externalId: '',
		lanes: formData.lanes,
		webhookIds: [],
		tags: tags ? tags : [],
		experimentVariables: [],
		creationMethod: experimentCreationMethod || 'UI_TEMPLATE',
	};
}

export function reassignNewStepIds(lanes: ExperimentLaneVO[]): void {
	for (let i = 0; i < lanes.length; i++) {
		const lane = lanes[i];
		for (let j = 0; j < lane.steps.length; j++) {
			const step = lane.steps[j];
			step.id = uuidv4();
		}
	}
}

// exported for testing
export function getUniqueTargetTypeIfPossible(
	selectedStepIds: Set<string>,
	lanes: ExperimentLaneVO[],
): string | undefined {
	const involvedTargetTypes: Set<string> = new Set(
		Array.from(selectedStepIds)
			.map((stepId) => {
				const steps = lanes.flatMap((lane) => lane.steps);
				const step = steps.find((step) => step.id === stepId);
				if (step && step.type === 'action') {
					const action = step as ExperimentStepActionVO;
					return action.blastRadius?.targetType;
				}
			})
			.filter((type) => type !== undefined) as string[],
	);

	return involvedTargetTypes.size === 1 ? involvedTargetTypes.values().next().value : undefined;
}
