/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	dateAsUserTimezone,
	dateInTimeZone,
	formatDateWithTime,
	formatDateWithTimeInTimeZone,
	getDateAsLocale,
	Timezone,
	timeZones,
} from 'utils/dateFns';
import {
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Snackbar,
	Stack,
	Text,
	Toggle,
} from 'components';
import TimezoneSelection from 'components/Select/Dropdown/presets/TimezoneSelection';
import { presets, SingleChoiceListItem } from '@steadybit/ui-components-lib';
import { CreateExperimentScheduleVO, ExperimentScheduleVO } from 'ui-api';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useUser } from 'services/usersApi';
import cronstrue from 'cronstrue';

import DeleteScheduleExperimentModal from './DeleteScheduleExperimentModal';
import ScheduleCronTab from './ScheduleCronTab';
import ScheduleFooter from './ScheduleFooter';
import CronExamples from './CronExamples';
import ScheduleOnce from './ScheduleOnce';

interface ScheduleExperimentModalProps {
	schedule?: ExperimentScheduleVO;
	experimentKey?: string;
	disabled: boolean;
	title?: string;
	onClose: () => void;
}

export default function ScheduleExperimentModal({
	title = 'Decide When to Run This Experiment',
	experimentKey,
	schedule,
	disabled,
	onClose,
}: ScheduleExperimentModalProps): ReactElement {
	const onceOption: SingleChoiceListItem = { id: 'once', label: 'Only once' };
	const cronOption: SingleChoiceListItem = { id: 'cron', label: 'Recurrently' };

	const user = useUser();
	const browserDefaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const usersTimeZone = timeZones.find((t) => t.timeZone === (user.timeZone || browserDefaultTimezone)) || timeZones[0];
	const [scheduleIsActivated, setScheduleIsActivated] = useState<boolean>(schedule?.enabled ?? true);
	const [allowParallel, setAllowParallel] = useState<boolean>(schedule?.allowParallel ?? true);
	const [scheduleIdToDelete, setScheduleIdToDelete] = useState<string | undefined>(undefined);

	const [cron, setCron] = useState<string>(schedule?.cron ?? '');
	const [timeZone, setTimeZone] = useState<Timezone>(
		timeZones.find((t) => t.timeZone === (schedule?.timezone || usersTimeZone?.timeZone)) || timeZones[0],
	);
	const [pickedDate, setPickedDate] = useState<Date>(() => {
		if (schedule?.startAt) {
			return dateAsUserTimezone(schedule.startAt);
		}

		const now = new Date();
		now.setMinutes(now.getMinutes() + 60);
		return now;
	});

	const [selectedOption, setSelectedOption] = useState<SingleChoiceListItem>(schedule?.cron ? cronOption : onceOption);

	const readableCron: string | undefined = useMemo(() => {
		try {
			return cronstrue.toString(cron);
		} catch {
			return undefined;
		}
	}, [cron]);

	const readableCronUserTimeZone: string | undefined = useMemo(() => {
		try {
			const dateUserTimezone = dateInTimeZone(new Date(), usersTimeZone.timeZone);
			const dateScheduleTimeZone = dateInTimeZone(new Date(), timeZone.timeZone);
			const diffInMillis = dateUserTimezone.getTime() - dateScheduleTimeZone.getTime();
			const tzOffset = diffInMillis / 1000 / 3600;
			return cronstrue.toString(cron, { tzOffset });
		} catch {
			return undefined;
		}
	}, [cron, timeZone, usersTimeZone]);

	const result = usePromise(() => {
		if (!readableCron) {
			return Promise.resolve(undefined);
		}
		return Services.experiments.validateCronExpression({ cron, timeZone: timeZone?.timeZone });
	}, [cron, timeZone?.timeZone]);
	const cronNextRun = result.value?.nextExecutions?.[0];
	const userTimezoneNow = dateAsUserTimezone(new Date());
	const isValidDateSelected =
		(selectedOption.id === 'once' && pickedDate > userTimezoneNow) ||
		(selectedOption.id === 'cron' &&
			(result.value?.valid || false) &&
			!result.loading &&
			!result.error &&
			!!readableCron);

	if (scheduleIdToDelete) {
		return <DeleteScheduleExperimentModal id={scheduleIdToDelete} onClose={onClose} />;
	}

	return (
		<ModalOverlay open centerContent onClose={onClose}>
			{({ close }) => (
				<ModalV2 slick withFooter width={750}>
					<ModalHeaderV2 title={title} onClose={close} />
					<ModalContentV2>
						<Stack size="medium" mb="medium">
							<ScheduleContentBlock
								title="Activate Schedule"
								description="Should this schedule be active or paused."
								setting={
									<Toggle
										type="radio"
										checked={scheduleIsActivated}
										disabled={disabled}
										onChange={async (e) => {
											setScheduleIsActivated(e.target.checked);
										}}
									/>
								}
							/>

							<ScheduleContentBlock
								title="Run this Experiment"
								description="Should the schedule run the experiment only once or recurrently, based on a crontab expression?"
								setting={
									<presets.dropdown.SingleChoiceButton
										selectedId={selectedOption.id}
										items={[onceOption, cronOption]}
										onSelect={(id) => setSelectedOption(id === 'once' ? onceOption : cronOption)}
									>
										{selectedOption.label}
									</presets.dropdown.SingleChoiceButton>
								}
							>
								{selectedOption.id === 'once' ? (
									<ScheduleOnce pickedDate={pickedDate} setPickedDate={setPickedDate} disabled={disabled} />
								) : (
									<ScheduleCronTab
										cron={cron}
										setCron={setCron}
										cronError={result.value?.error}
										cronValid={isValidDateSelected}
										disabled={disabled}
									/>
								)}
							</ScheduleContentBlock>

							{selectedOption.id === 'cron' && (
								<Stack direction="horizontal">
									<ScheduleTimezoneBlock
										title="Schedule Time zone"
										timezone={
											<TimezoneSelection
												selectedTimezone={timeZone.timeZone}
												selectTimezone={(timeZone) => {
													const match = timeZones.find((t) => timeZone === t.timeZone);
													setTimeZone(match || timeZones[0]);
												}}
											/>
										}
										userReadableTimezone={isValidDateSelected ? readableCron : undefined}
										nextRun={
											isValidDateSelected && cronNextRun
												? formatDateWithTimeInTimeZone(cronNextRun, timeZone.timeZone)
												: undefined
										}
									/>

									<ScheduleTimezoneBlock
										title="Schedule Converted to Your Time Zone"
										timezone={
											<Text variant="largeStrong" color="neutral700" sx={{ lineHeight: '42px' }}>
												{usersTimeZone.timeZone.replace('_', ' ')}
											</Text>
										}
										userReadableTimezone={isValidDateSelected ? readableCronUserTimeZone : undefined}
										nextRun={isValidDateSelected && cronNextRun ? formatDateWithTime(cronNextRun) : undefined}
									/>
								</Stack>
							)}
							{selectedOption.id === 'cron' && <CronExamples />}

							<ScheduleContentBlock
								title="Run Experiments in Parallel"
								description="At the configured time, should the experiment run even when another experiment is already running?"
								setting={
									<Toggle
										type="radio"
										checked={allowParallel}
										onChange={async (e) => {
											setAllowParallel(e.target.checked);
										}}
										disabled={disabled}
									/>
								}
							/>
						</Stack>
					</ModalContentV2>

					<ModalFooterV2>
						<ScheduleFooter
							isValidDateSelected={isValidDateSelected}
							schedule={schedule}
							disabled={disabled}
							setScheduleIdToDelete={setScheduleIdToDelete}
							onSave={async () => {
								try {
									if (schedule) {
										const scheduleToSave: CreateExperimentScheduleVO = {
											...schedule,
											allowParallel,
											enabled: scheduleIsActivated,
										};
										delete scheduleToSave.startAt;
										delete scheduleToSave.cron;
										delete scheduleToSave.timezone;
										if (selectedOption.id === 'once') {
											scheduleToSave.startAt = userDateAsBrowserDate(pickedDate);
										} else {
											scheduleToSave.timezone = timeZone?.timeZone;
											scheduleToSave.cron = cron;
										}
										await Services.experiments.updateExperimentSchedule(scheduleToSave);
									} else {
										const scheduleToSave: CreateExperimentScheduleVO = {
											experimentKey: experimentKey || '',
											enabled: scheduleIsActivated,
											allowParallel,
										};
										if (selectedOption.id === 'once') {
											scheduleToSave.startAt = userDateAsBrowserDate(pickedDate);
										} else {
											scheduleToSave.cron = cron;
											scheduleToSave.timezone = timeZone?.timeZone;
										}
										await Services.experiments.scheduleExperiment(scheduleToSave);
									}
									close();
								} catch {
									Snackbar.error('Failed to schedule experiment');
								}
							}}
						/>
					</ModalFooterV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function ScheduleContentBlock({
	description,
	children,
	setting,
	title,
}: {
	children?: ReactNode;
	description: string;
	setting: ReactNode;
	title: string;
}): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				alignItems: 'stretch',
				p: 'small',
				background: 'neutral100',
				borderRadius: '8px',
			}}
		>
			<Stack
				size="medium"
				direction="horizontal"
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Stack size="xxSmall">
					<Text variant="mediumStrong" color="neutral800">
						{title}
					</Text>
					<Text variant="small" color="neutral600">
						{description}
					</Text>
				</Stack>
				{setting}
			</Stack>
			{children}
		</Stack>
	);
}

function ScheduleTimezoneBlock({
	userReadableTimezone,
	timezone,
	nextRun,
	title,
}: {
	userReadableTimezone: string | undefined;
	timezone: ReactNode;
	nextRun?: string;
	title: string;
}): ReactElement {
	return (
		<Stack
			size="xSmall"
			sx={{
				alignItems: 'stretch',
				p: 'small',
				background: 'neutral100',
				borderRadius: '8px',
				flex: '1',
			}}
		>
			<Text variant="medium" as="span" color="neutral600">
				{title}
			</Text>
			{timezone}
			<Text variant="medium" as="span" color="neutral800">
				{userReadableTimezone ? userReadableTimezone : <>&nbsp;</>}
			</Text>
			<Text variant="medium" as="span" color="neutral800">
				{nextRun ? (
					<>
						<Text variant="medium" fontWeight="bold" as="span" color="neutral800">
							Next run:
						</Text>
						&nbsp;{nextRun}
					</>
				) : (
					<>&nbsp;</>
				)}
			</Text>
		</Stack>
	);
}

function userDateAsBrowserDate(userDate: Date): Date {
	const dateNow = new Date();
	const now = getDateAsLocale(dateNow);
	const userNow = dateAsUserTimezone(dateNow);
	const diffInMillis = now.getTime() - userNow.getTime();
	return new Date(userDate.getTime() + diffInMillis);
}
