/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { VariableVO } from 'ui-api';

export const EnvVarRegex = /\{\{([A-Za-z0-9-_.]+?)\}\}/g;
export const EnvVarRegexKeepingBrackets = /(\{\{([A-Za-z0-9-_.]+?)\}\})/g;

export function quoteForVariables(value: string): string {
	return value.replaceAll(EnvVarRegex, '\\$&');
}

export function mergeAndSort(v1: VariableVO[], v2: VariableVO[]): VariableVO[] {
	const merged = new Map<string, VariableVO>();
	v1.forEach((v) => merged.set(v.key, v));
	v2.forEach((v) => merged.set(v.key, v));
	return Array.from(merged.values()).sort((a, b) => a.key.localeCompare(b.key));
}
