/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useObservable } from 'utils/hooks/useObservable';
import { map } from 'rxjs/operators';

import { useTenant } from '../tenancy/useTenant';
import { NotificationVO } from '../ui-api';
import { Services } from './services';

export function useGlobalNotifications(keys: string[]): NotificationVO[] | undefined {
	const tenantKey = useTenant().key;
	const permissionsResult = useObservable(
		() =>
			Services.notificationsApi
				.getGlobalNotifications$()
				.pipe(map((notifications) => notifications.filter((notification) => keys.includes(notification.key)))),
		[tenantKey],
	);
	return permissionsResult.value;
}

export function useGlobalNotificationWithFilter(filterFunc: (n: NotificationVO) => boolean): NotificationVO[] {
	const tenantKey = useTenant().key;
	const permissionsResult = useObservable(
		() =>
			Services.notificationsApi
				.getGlobalNotifications$()
				.pipe(map((notifications) => notifications.filter(filterFunc))),
		[tenantKey],
	);
	return permissionsResult.value || Services.notificationsApi.emptyNotifications;
}
