/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	RouterButton,
	SettingsGroup,
	SettingsGroupItem,
	ShortenedText,
	Spinner,
	Stack,
	Text,
	Tooltip,
} from 'components';
import { IconCheck, IconChevronDown, IconChevronUp, IconView } from 'components/icons';
import { Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import textEllipsis2Lines from 'utils/styleSnippets/textEllipsis2Lines';
import TeamIcon from 'pages/settings/teams/components/TeamIcon';
import { useTenantSwitcher } from 'tenancy/useTenantSwitcher';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { AnimatePresence, motion } from 'framer-motion';
import { usePromise } from 'utils/hooks/usePromise';
import { TeamSummaryVO, TenantVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { isUserAdmin } from 'utils/user';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import { debounce } from 'lodash';
import { ampli } from 'ampli';

export default function TenantAndTeamSwitcher({
	expanded,
	setShowTeamSwitcher,
}: {
	expanded: boolean;
	setShowTeamSwitcher: (v: boolean) => void;
}): ReactElement {
	const [disabledCloseOnClickAway, setDisabledCloseOnClickAway] = useState(false);
	const tenants = usePromise(() => Services.tenants.fetchTenants(), []);
	const multiTenant = useMemo(() => tenants.value && tenants.value.length > 1, [tenants.value]);

	const [teams, fetch] = useAsyncState(() => Services.teams.findTeams('', false, 0, 100), []);
	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if ('team.created' === event.type || 'team.deleted' === event.type || 'team.updated' === event.type) {
					debouncedFetch();
				}
			},
			[debouncedFetch, teams],
		),
		[],
		debouncedFetch.cancel,
		[teams.value],
	);

	const currentTenant = useTenant();
	const currentTeam = useTeam();

	const userIsMemberOfCurrentTeam = useMemo(() => {
		return (
			teams.loading ||
			(teams.value && !!teams.value.filter((team) => team.userIsTeamMember).find((team) => team.id === currentTeam.id))
		);
	}, [currentTeam, teams]);

	const [hovered, setHovered] = useState(false);

	return (
		<Dropdown<HTMLDivElement>
			placement="bottom-start"
			disabledCloseOnClickAway={disabledCloseOnClickAway}
			onOpen={() => setShowTeamSwitcher(true)}
			onClose={() => setShowTeamSwitcher(false)}
			renderDropdownContent={() => (
				<DropdownContent
					tenants={tenants.value || []}
					teams={teams.value || []}
					setShowTenants={setDisabledCloseOnClickAway}
				/>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Stack
						ref={setRefElement}
						direction="horizontal"
						size="xxSmall"
						onClick={() => setOpen(!isOpen)}
						sx={{
							position: 'relative',
							alignItems: 'center',
							padding: '0px 12px 4px 0px',
							height: '60px',

							backgroundColor: isOpen ? 'purple600' : undefined,
							borderRadius: '4px',
							overflow: 'hidden',

							cursor: 'pointer',
							'&:hover': {
								backgroundColor: 'purple600',
							},
						}}
						data-cy="team"
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
					>
						<div
							style={{
								position: 'absolute',
								top: '26px',
								left: '9px',
								width: 30,
								height: 30,
								backgroundColor: hovered ? theme.colors.purple600 : theme.colors.purple700,
								borderRadius: '4px',
							}}
						/>
						<div
							style={{
								position: 'absolute',
								top: '18px',
								left: '3px',
								width: 34,
								height: 34,
								backgroundColor: theme.colors.purple600,
								borderRadius: '4px',
							}}
						/>

						<Container sx={{ zIndex: 1, minWidth: '40px' }}>
							<TeamIcon
								userIsMember={!!userIsMemberOfCurrentTeam}
								color={currentTeam.logoColor}
								logoId={currentTeam.logoId}
							/>
						</Container>

						{expanded && (
							<AnimatePresence initial>
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									transition={{ duration: 0.1, delay: 0.3 }}
								>
									<Stack size="none" width="100%">
										{multiTenant && (
											<Stack direction="horizontal" size="xxSmall">
												<Text
													as="span"
													variant="small"
													color="purple300"
													sx={{ whiteSpace: 'nowrap', lineHeight: '12px' }}
													data-private
												>
													{currentTenant.name}
												</Text>
												<Text
													as="span"
													variant="small"
													color="purple300"
													sx={{ whiteSpace: 'nowrap', lineHeight: '12px' }}
												>
													/
												</Text>
											</Stack>
										)}
										<Stack direction="horizontal" alignItems="center" justifyContent="space-between">
											<Text
												variant="mediumStrong"
												color="neutral000"
												sx={{
													...textEllipsis2Lines,
													lineHeight: '14px',
												}}
											>
												{currentTeam.name} {userIsMemberOfCurrentTeam ? null : <ViewOnly />}
											</Text>
											{isOpen ? <IconChevronUp color="purple300" /> : <IconChevronDown color="purple300" />}
										</Stack>
									</Stack>
								</motion.div>
							</AnimatePresence>
						)}
					</Stack>
				);
			}}
		</Dropdown>
	);
}

interface DropdownContentProps {
	setShowTenants: (v: boolean) => void;
	teams: TeamSummaryVO[];
	tenants: TenantVO[];
}

function DropdownContent({ teams, tenants, setShowTenants }: DropdownContentProps): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const currentTeam = useTeam();

	const tenantSwitcher = useTenantSwitcher();

	const handleTenantSelect = useCallback((_tenant: TenantVO) => tenantSwitcher(_tenant.key), []);

	const handleTeamSelect = useCallback((team: TeamSummaryVO) => {
		Services.teams.setTeam(tenant.key, team.key);
		const pathSplit = window.location.pathname.split('/');
		const targetPath = pathSplit.length > 0 ? pathSplit[1] : '';
		ampli.teamSwitched({
			team_id_switched_to: team.id,
		});
		window.location.assign('/' + targetPath);
	}, []);

	const userTeams = useMemo(() => (teams ? teams.filter((team) => team.userIsTeamMember) : []), [teams]);
	const otherTeams = useMemo(() => (teams ? teams.filter((team) => !team.userIsTeamMember) : []), [teams]);

	const userIsNotPartOfAnyTeam = userTeams.length === 0;
	const userHasOtherTeams = otherTeams.length > 0;
	const [showOtherTeams, setShowOtherTeams] = useState(false);

	const [userTenantQuery, setUserTenantQuery] = useState<string>(tenant.name);
	const [filterTenants, setFilterTenants] = useState<boolean>(false);

	return (
		<presets.dropdown.DropdownContentFrame
			maxHeight="calc(80vh - 100px)"
			maxWidth="380px"
			style={{
				py: 'small',
				borderRadius: 'xxSmall',
				overflow: 'auto',
				minWidth: '380px',
			}}
		>
			<>
				{tenants && tenants.length > 1 ? (
					<Container
						sx={{
							px: 'small',
							pt: 'xSmall',
							pb: 'small',
							borderBottom: '1px solid',
							borderBottomColor: 'neutral200',
						}}
					>
						<Dropdown<HTMLInputElement>
							onOpen={() => setShowTenants(true)}
							onClose={() => setShowTenants(false)}
							renderDropdownContent={({ width, close }) => {
								const filteredTenants = tenants.filter((_tenant) => {
									if (!filterTenants) {
										return true;
									}
									return includes(_tenant.name, userTenantQuery);
								});
								if (filteredTenants.length === 0) {
									return <></>;
								}
								return (
									<presets.dropdown.DropdownContentFrame width={width}>
										<presets.dropdown.SingleChoiceList
											items={filteredTenants.map((_tenant) => ({
												label: _tenant.name,
												id: _tenant.key,
												isSelected: _tenant.key === tenant.key,
											}))}
											onSelect={(tenantKey) => {
												const selectedTenant = tenants.find((t) => t.key === tenantKey);
												if (selectedTenant && selectedTenant.key !== tenant.key) {
													handleTenantSelect(selectedTenant);
												}
												close();
											}}
										/>
									</presets.dropdown.DropdownContentFrame>
								);
							}}
						>
							{({ setRefElement, isOpen, setOpen }) => {
								return (
									<TextInput
										ref={setRefElement}
										value={userTenantQuery}
										onChange={(v) => {
											setUserTenantQuery(v);
											setFilterTenants(true);
										}}
										withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
										onClick={() => setOpen(!isOpen)}
									/>
								);
							}}
						</Dropdown>
					</Container>
				) : null}
				{isAdmin && (
					<Container
						display={'flex'}
						px={'small'}
						py={'xSmall'}
						flexDirection={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Text variant={'smallStrong'}>Teams</Text>
						<RouterButton to={'/settings/teams'} variant={'secondarySmall'}>
							Manage Teams
						</RouterButton>
					</Container>
				)}
			</>

			<SettingsGroup sx={{ border: 'none', maxWidth: 400 }}>
				{userTeams.map((team) => (
					<TeamEntry
						key={team.id}
						team={team}
						checked={currentTeam.id === team.id}
						onClick={() => handleTeamSelect(team)}
					/>
				))}

				{userIsNotPartOfAnyTeam && (
					<Text variant="small" color="neutral600" px="small" pt="small">
						Your user is not assigned to any team
					</Text>
				)}

				{userHasOtherTeams && (
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							pb: 'small',
							pt: 'medium',
							px: 'small',
							cursor: 'pointer',
						}}
						onClick={() => setShowOtherTeams(!showOtherTeams)}
					>
						<Text variant="smallStrong">Other Teams (view only)</Text>
						{showOtherTeams ? (
							<IconChevronUp variant="small" color="neutral800" />
						) : (
							<IconChevronDown variant="small" color="neutral800" />
						)}
					</Container>
				)}

				{showOtherTeams && (
					<OtherTeams currentTeam={currentTeam} otherTeams={otherTeams} handleTeamSelect={handleTeamSelect} />
				)}
			</SettingsGroup>
		</presets.dropdown.DropdownContentFrame>
	);
}

const ViewOnly: React.VFC = () => {
	return (
		<Tooltip content={'View only - you are not a member of this team'} placement={'bottom'}>
			<span>
				<IconView color="neutral300" ml={'xxSmall'} />
			</span>
		</Tooltip>
	);
};

interface OtherTeamsProps {
	currentTeam: TeamSummaryVO;
	otherTeams: TeamSummaryVO[];
	handleTeamSelect: (team: TeamSummaryVO) => void;
}

function OtherTeams({ currentTeam, otherTeams, handleTeamSelect }: OtherTeamsProps): ReactElement {
	const [teams] = useAsyncState(() => Services.teams.findTeams('', true, 0, 100), [], []);

	return (
		<>
			{otherTeams.map((team) => (
				<TeamEntry
					key={team.id}
					team={team}
					checked={currentTeam.id === team.id}
					onClick={() => handleTeamSelect(team)}
				/>
			))}
			{teams.loading && (
				<Container display={'flex'} px={12} pb={'xxSmall'} alignItems={'center'}>
					<Spinner color={'neutral400'} />
				</Container>
			)}
		</>
	);
}

const TeamEntry: React.VFC<{ team: TeamSummaryVO; checked: boolean; onClick: () => void }> = ({
	team,
	checked,
	onClick,
}) => {
	return (
		<SettingsGroupItem
			sx={{
				cursor: 'pointer',
				':hover': {
					bg: 'neutral100',
				},
			}}
			py={'xSmall'}
			px={'small'}
			key={team.id}
			onClick={onClick}
		>
			<Stack direction="horizontal" alignItems="center" justifyContent="space-between">
				<TeamIcon userIsMember={team.userIsTeamMember} color={team.logoColor} logoId={team.logoId} />

				<ShortenedText
					as={'label'}
					variant={checked ? 'mediumStrong' : 'medium'}
					color={checked ? 'neutral800' : 'neutral700'}
					sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
					flexGrow={1}
				>
					{team.name} ({team.key})
				</ShortenedText>
				{checked && <IconCheck flex={'0 0 auto'} />}
			</Stack>
		</SettingsGroupItem>
	);
};
