/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	DraggingStyle,
	Droppable,
	NotDraggingStyle,
} from 'react-beautiful-dnd';
import {
	SearchbarActionMaker,
	WorkspaceLaneFalloffCatcherMaker,
	WorkspaceLaneMaker,
} from 'pages/experimentsV2/DragAndDropHandler';
import { ActionKindVO, ActionVO, EnvironmentSummaryVO, SearchObjectsVO, TargetTypeDescriptionVO } from 'ui-api';
import { createFilterParams, UrlState } from 'pages/templates/FromTemplateModal/urlParams';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import Action from 'pages/experimentsV2/StepsSidebar/Action';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { presets } from '@steadybit/ui-components-lib';
import { Li, Stack, Text, Ul } from 'components';
import { useUrlState } from 'url/useUrlState';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';

interface SearchObjectsProps {
	mode: 'experimentList' | 'templatePreviews' | 'templateList';
	searchObjectsResult: DataStreamResult<SearchObjectsVO>;
	environemntIdToEnv: Map<string, EnvironmentSummaryVO>;
	targetDefinitions: TargetTypeDescriptionVO[];
	width: string | number | undefined;
	allowActionDragAndDrop: boolean;
	actionsData: ActionVO[];
	pathname: string;
	query: string;
	clearQuery: () => void;
}

export default function SearchObjects({
	allowActionDragAndDrop,
	searchObjectsResult,
	environemntIdToEnv,
	targetDefinitions,
	actionsData,
	pathname,
	query,
	width,
	mode,
	clearQuery,
}: SearchObjectsProps): ReactElement {
	const [
		{ environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, kindsParam, pageParam },
	] = useState(() => createFilterParams(pathname));
	const [
		{
			environmentIds: environmentIdsFromUrl,
			targetTypes: targetTypesFromUrl,
			actions: actionsFromUrl,
			kinds: kindsFromUrl,
			tags: tagsFromUrl,
			freeTextPhrases,
		},
		,
		updateUrlState,
	] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		kindsParam,
		pageParam,
		tagsParam,
	]);

	const wrapperRef = useRef<HTMLDivElement>(null);

	const tags = (searchObjectsResult.value?.tags || []).filter((v) => !tagsFromUrl.includes(v) && includes(v, query));

	const environments = (searchObjectsResult.value?.environmentIds || [])
		.filter((id) => !environmentIdsFromUrl.includes(id))
		.map((id) => ({ id, env: environemntIdToEnv.get(id) }))
		.filter(({ env }) => env && env?.name !== '' && includes(env.name, query));

	const targetTypes: TargetTypeDescriptionVO[] = (searchObjectsResult.value?.targetTypes || [])
		.filter((targetType) => !targetTypesFromUrl.includes(targetType))
		.map((targetType) => targetDefinitions.find((t) => t.id === targetType))
		.filter((tt) => tt !== undefined && includes(tt.label.one, query)) as TargetTypeDescriptionVO[];

	const actions: ActionVO[] = (searchObjectsResult.value?.actions || [])
		.filter((action) => !actionsFromUrl.includes(action))
		.map((action) => actionsData.find((actionData) => actionData.id === action))
		.filter((action) => action !== undefined && includes(action.name, query)) as ActionVO[];

	const kinds: string[] = (searchObjectsResult.value?.actionKinds || []).filter(
		(kind) => !kindsFromUrl.includes(kind) && includes(kind, query),
	);

	const MAX_ITEMS_PER_CATEGORY = 10;

	const renderedTags = tags.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedEnvironments = environments.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedTargetTypes = targetTypes.slice(0, MAX_ITEMS_PER_CATEGORY);
	const renderedActions = actions.slice(0, MAX_ITEMS_PER_CATEGORY);
	const numTags = renderedTags.length;
	const numEnvironments = renderedEnvironments.length;
	const numTargetTypes = renderedTargetTypes.length;
	const numActions = renderedActions.length;
	const numKinds = kinds.length;
	const hoveredIndex = useKeyboardNavigation(
		(i) => {
			if (i < 0 || i >= numTags + numEnvironments + numTargetTypes + numActions + numKinds) {
				if (query) {
					updateUrlState({ page: 0, freeTextPhrases: [...freeTextPhrases, query] });
					clearQuery();
				}
				return;
			}

			if (i < numTags) {
				const selectedItem = renderedTags[i];
				updateUrlState({ page: 0, tags: [...tagsFromUrl, selectedItem] });
				return clearQuery();
			} else if (i < numTags + numEnvironments) {
				const selectedItem = renderedEnvironments[i - numTags];
				updateUrlState({ page: 0, environmentIds: [...environmentIdsFromUrl, selectedItem.id] });
				return clearQuery();
			}
			if (i < numTags + numEnvironments + numTargetTypes) {
				const selectedItem = renderedTargetTypes[i - numTags - numEnvironments];
				updateUrlState({ page: 0, targetTypes: [...targetTypesFromUrl, selectedItem.id] });
				return clearQuery();
			}
			if (i < numTags + numEnvironments + numTargetTypes + numActions) {
				const selectedItem = renderedActions[i - numTags - numEnvironments - numTargetTypes];
				updateUrlState({ page: 0, actions: [...actionsFromUrl, selectedItem.id] });
				return clearQuery();
			}
			if (i < numTags + numEnvironments + numTargetTypes + numActions + numKinds) {
				const selectedItem = kinds[i - numTags - numEnvironments - numTargetTypes - numActions];
				updateUrlState({ page: 0, kinds: [...kindsFromUrl, selectedItem] });
				return clearQuery();
			}
		},
		{ numTags, numEnvironments, numTargetTypes, numActions, numKinds, hasQuery: Boolean(query) },
	);

	if (
		!query &&
		tags.length === 0 &&
		environments.length === 0 &&
		targetTypes.length === 0 &&
		actions.length === 0 &&
		kinds.length === 0
	) {
		return <></>;
	}

	return (
		<Stack ref={wrapperRef} p="small" width="700px" maxWidth={width}>
			{tags.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						TAGS
					</Text>
					<ListWrapper>
						{renderedTags.map((tag, index) => (
							<ListItem key={tag} isHighlighted={hoveredIndex === index} borderRadius={4}>
								<presets.pill.Tag
									appearance={mode === 'experimentList' ? 'experiment' : 'template'}
									onClick={() => {
										updateUrlState({ page: 0, tags: [...tagsFromUrl, tag] });
										clearQuery();
									}}
								>
									{tag}
								</presets.pill.Tag>
							</ListItem>
						))}
						{tags.length > MAX_ITEMS_PER_CATEGORY && <MoreItems numberMore={tags.length - MAX_ITEMS_PER_CATEGORY} />}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{environments.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						ENVIRONMENTS
					</Text>
					<ListWrapper>
						{renderedEnvironments.map(({ id, env }, index) => {
							const indexOffset = numTags;
							return (
								<ListItem key={id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={16}>
									<presets.pill.EnvironmentTag
										global={env?.global}
										onClick={() => {
											updateUrlState({ page: 0, environmentIds: [...environmentIdsFromUrl, id] });
											clearQuery();
										}}
									>
										{env?.name || ''}
									</presets.pill.EnvironmentTag>
								</ListItem>
							);
						})}
						{environments.length > MAX_ITEMS_PER_CATEGORY && (
							<MoreItems numberMore={environments.length - MAX_ITEMS_PER_CATEGORY} />
						)}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{targetTypes.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						TARGET TYPES
					</Text>
					<ListWrapper>
						{renderedTargetTypes.map((targetType, index) => {
							const indexOffset = numTags + numEnvironments;
							const targetDefinition = targetDefinitions.find((t) => t.id === targetType.id);
							return (
								<ListItem key={targetType.id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={16}>
									<presets.pill.TargetTypeTag
										icon={targetDefinition?.icon}
										onClick={() => {
											updateUrlState({
												page: 0,
												targetTypes: [...targetTypesFromUrl, targetType.id],
											});
											clearQuery();
										}}
									>
										{targetDefinition?.label.one || targetType.id}
									</presets.pill.TargetTypeTag>
								</ListItem>
							);
						})}
						{targetTypes.length > MAX_ITEMS_PER_CATEGORY && (
							<MoreItems numberMore={targetTypes.length - MAX_ITEMS_PER_CATEGORY} />
						)}
					</ListWrapper>
					<Divider />
				</Stack>
			)}
			{actions.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						ACTIONS
					</Text>
					{allowActionDragAndDrop ? (
						<Droppable droppableId="droppable" isDropDisabled type="steps">
							{(droppableProvided) => (
								<div ref={droppableProvided.innerRef} style={{ position: 'relative' }}>
									<ListWrapper>
										{renderedActions.map((action, index) => {
											const indexOffset = numTags + numEnvironments + numTargetTypes;
											const id = SearchbarActionMaker + action.id;

											return (
												<Draggable key={action.id} draggableId={id} index={index}>
													{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
														const isDraggingOverLane: boolean =
															dragSnapshot.draggingOver && dragSnapshot.draggingOver.startsWith(WorkspaceLaneMaker)
																? true
																: false;
														const isDragging = dragSnapshot.isDragging;

														const { x, y } = wrapperRef.current?.getBoundingClientRect() || { x: 0, y: 0 };

														return (
															<ListItem isHighlighted={hoveredIndex === indexOffset + index} borderRadius={6}>
																<div
																	ref={dragProvided.innerRef}
																	{...dragProvided.draggableProps}
																	{...dragProvided.dragHandleProps}
																	style={correctAnimation(x, y, dragSnapshot, dragProvided.draggableProps.style)}
																>
																	{isDragging ? (
																		<Action
																			isDraggingOverLane={isDraggingOverLane}
																			isDragging={isDragging}
																			action={action}
																			label={action.name}
																		/>
																	) : (
																		<presets.pill.ActionTag
																			kind={action.kind}
																			icon={action.icon}
																			onClick={() => {
																				updateUrlState({ page: 0, actions: [...actionsFromUrl, action.id] });
																				clearQuery();
																			}}
																		>
																			{action.name}
																		</presets.pill.ActionTag>
																	)}
																</div>
															</ListItem>
														);
													}}
												</Draggable>
											);
										})}
										{actions.length > MAX_ITEMS_PER_CATEGORY && (
											<MoreItems numberMore={actions.length - MAX_ITEMS_PER_CATEGORY} />
										)}
									</ListWrapper>
								</div>
							)}
						</Droppable>
					) : (
						<ListWrapper>
							{renderedActions.map((action, index) => {
								const indexOffset = numTags + numEnvironments + numTargetTypes;
								return (
									<ListItem key={action.id} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={6}>
										<presets.pill.ActionTag
											kind={action.kind}
											icon={action.icon}
											onClick={() => {
												updateUrlState({ page: 0, actions: [...actionsFromUrl, action.id] });
												clearQuery();
											}}
										>
											{action.name}
										</presets.pill.ActionTag>
									</ListItem>
								);
							})}
							{actions.length > MAX_ITEMS_PER_CATEGORY && (
								<MoreItems numberMore={actions.length - MAX_ITEMS_PER_CATEGORY} />
							)}
						</ListWrapper>
					)}

					{query && <Divider />}
				</Stack>
			)}
			{kinds.length > 0 && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						KINDS
					</Text>
					<ListWrapper>
						{kinds.map((kind, index) => {
							const indexOffset = numTags + numEnvironments + numTargetTypes + numActions;
							return (
								<ListItem key={kind} isHighlighted={hoveredIndex === indexOffset + index} borderRadius={6}>
									<presets.pill.KindTag
										key={kind}
										kind={kind as ActionKindVO}
										onClick={() => {
											updateUrlState({ page: 0, kinds: [...kindsFromUrl, kind] });
											clearQuery();
										}}
									/>
								</ListItem>
							);
						})}
					</ListWrapper>
					{query && <Divider />}
				</Stack>
			)}
			{query && (
				<Stack size="xSmall">
					<Text variant="small" color="neutral600">
						Show everything that contains
					</Text>

					{query && (
						<ListWrapper>
							<ListItem
								key="add_as_free_text"
								isHighlighted={hoveredIndex === numTags + numEnvironments + numTargetTypes + numActions + numKinds}
								borderRadius={6}
							>
								<presets.pill.FreeText
									data-cy="create-free-text"
									onClick={() => {
										updateUrlState({ page: 0, freeTextPhrases: [...freeTextPhrases, query] });
										clearQuery();
									}}
								>
									{`“${query}“`}
								</presets.pill.FreeText>
							</ListItem>
						</ListWrapper>
					)}
				</Stack>
			)}
		</Stack>
	);
}

function Divider(): ReactElement {
	return <div style={{ height: '1px', backgroundColor: theme.colors.neutral300 }} />;
}

function MoreItems({ numberMore }: { numberMore: number }): ReactElement {
	return (
		<ListItem>
			<Text as="span" variant="small" sx={{ px: 'xSmall' }}>{`+${numberMore} more`}</Text>
		</ListItem>
	);
}

function ListWrapper({ children }: { children: ReactNode | ReactNode[] }): ReactElement {
	return (
		<Ul
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
				gap: '4px',
			}}
		>
			{children}
		</Ul>
	);
}

interface ListItemProps {
	isHighlighted?: boolean;
	borderRadius?: number;
	children: ReactNode;
}

function ListItem({ children, isHighlighted = false, borderRadius = 0 }: ListItemProps): ReactElement {
	return (
		<Li
			sx={{
				borderRadius: borderRadius + 'px',
				border: '2px solid ' + (isHighlighted ? theme.colors.aqua700 : 'transparent'),
			}}
			onKeyDown={
				isHighlighted
					? (e) => {
							e.preventDefault();
						}
					: undefined
			}
		>
			{children}
		</Li>
	);
}

function useKeyboardNavigation(
	onSelect: (index: number) => void,
	config: {
		numEnvironments: number;
		numTargetTypes: number;
		numActions: number;
		hasQuery: boolean;
		numKinds: number;
		numTags: number;
	},
): number | undefined {
	const { numTags, numEnvironments, numTargetTypes, numActions, numKinds, hasQuery } = config;
	const numberItems = numTags + numEnvironments + numTargetTypes + numActions + numKinds + (hasQuery ? 1 : 0);

	const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
	useEffect(() => setHoveredIndex(undefined), [numberItems]);

	const keyDownListener = (event: KeyboardEvent): void => {
		if (numberItems === 0) {
			return;
		}

		if (event.key === 'Tab' || event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}
		if (event.key === 'ArrowDown') {
			const newIndex = (hoveredIndex ?? -1) + 1;
			setHoveredIndex(Math.min(newIndex, numberItems - 1));
		} else if (event.key === 'ArrowUp') {
			const newIndex = (hoveredIndex ?? numberItems) - 1;
			setHoveredIndex(Math.max(0, newIndex));
		} else if (event.key === 'Enter') {
			onSelect(hoveredIndex ?? -1);
		} else if (event.key === 'Tab') {
			if (hoveredIndex == undefined) {
				setHoveredIndex(0);
			}

			const jumpSteps = Array.from(
				new Set([
					0,
					numTags,
					numTags + numEnvironments,
					numTags + numEnvironments + numTargetTypes,
					numTags + numEnvironments + numTargetTypes + numActions,
					numTags + numEnvironments + numTargetTypes + numActions + numKinds,
				]),
			);

			if (event.shiftKey) {
				const prevJumpStep = jumpSteps.reverse().find((step) => step < (hoveredIndex ?? -1));
				setHoveredIndex(prevJumpStep);
			} else {
				const nextJumpStep = jumpSteps.find((step) => step > (hoveredIndex ?? -1));
				setHoveredIndex(nextJumpStep ?? 0);
			}
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	return hoveredIndex;
}

function correctAnimation(
	offsetX: number,
	offsetY: number,
	snapshot: DraggableStateSnapshot,
	style?: DraggingStyle | NotDraggingStyle | undefined,
): DraggingStyle | NotDraggingStyle | undefined {
	const [top, left] = isDraggingStyle(style) ? [(style.top || 0) - offsetY, (style.left || 0) - offsetX] : [0, 0];

	if (!snapshot.isDropAnimating || !snapshot.dropAnimation) {
		if (isDraggingStyle(style)) {
			return { ...style, top, left };
		}
		return style;
	}

	const { moveTo } = snapshot.dropAnimation;
	let y = moveTo.y;
	let x = moveTo.x;
	if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}above`) {
		const domElement = document.getElementById(snapshot.draggingOver);
		const rect = domElement?.getBoundingClientRect();
		if (rect) {
			y += rect.height - 60;
		}
	} else if (snapshot.draggingOver === `${WorkspaceLaneFalloffCatcherMaker}mid`) {
		const domElement = document.getElementById(snapshot.draggingOver);
		const rect = domElement?.getBoundingClientRect();
		if (rect) {
			y += rect.height / 2 - 30;
		}
	} else if (snapshot.draggingOver?.startsWith(WorkspaceLaneMaker)) {
		y -= 5;
		x -= 30;
	}

	// patching the existing style
	return {
		...style,
		top,
		left,
		transform: `translate(${x + 24}px, ${y}px)`,
		// cannot be 0, but make it super tiny
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		transitionDuration: '0.5s',
	};
}

function isDraggingStyle(style?: DraggingStyle | NotDraggingStyle | undefined): style is DraggingStyle {
	return style !== undefined && 'transform' in style;
}
