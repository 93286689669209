/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import ListHeader from 'components/List/presets/ListHeader';
import { Snackbar, Spinner, Stack, Text } from 'components';
import { IconAdvanced } from 'components/icons';
import { ReactElement } from 'react';
import { Box } from 'theme-ui';

import { FileUpload } from '../../../components/FileUpload/FileUpload';
import { useAsyncState } from '../../../utils/hooks/useAsyncState';
import ContentWrapper from '../components/ContentWrapper';
import { useTenant } from '../../../tenancy/useTenant';
import { Services } from '../../../services/services';
import { isUserAdmin } from '../../../utils/user';
import { CustomLogosVO } from '../../../ui-api';
import HelpText from '../components/HelpText';

export default function Customization(): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	const [settings, fetch] = useAsyncState(() => {
		return Services.settingsApi.getCustomLogoSettings();
	}, []);

	const handleOnChange = async (value: CustomLogosVO): Promise<void> => {
		try {
			await Services.settingsApi.setCustomLogoSettings(value);
			fetch();
		} catch (error) {
			Snackbar.error('Settings could not be updated: ' + error.toString(), { toastId: 'settings-custom-logo' });
		}
	};

	return (
		<>
			<ContentWrapper>
				<ListHeader left={<ListHeaderTitle title="Customization" Icon={IconAdvanced} />} />
				<Stack direction="horizontal" justifyContent="space-between" alignItems="center" mb="small">
					<HelpText>Customize Steadybit by adding your own logo. You can upload .png or .svg files</HelpText>
				</Stack>
				<CustomizeBox>
					<>
						{tenant.type === 'ONPREM' && (
							<LogoUpload
								title={'Splash Screen Logo'}
								fileId={settings?.value?.splashscreen}
								loading={settings.loading}
								logoWidth={600}
								logoHeight={122}
								disabled={!isAdmin}
								background={''}
								onChange={async (value: string | undefined) => {
									await handleOnChange({ ...settings.value, splashscreen: value });
								}}
							/>
						)}
						<LogoUpload
							title={'Navigation Logo'}
							fileId={settings?.value?.navigation}
							loading={settings.loading}
							logoWidth={150}
							logoHeight={30}
							disabled={!isAdmin}
							background={'linear-gradient(0deg, rgb(27, 22, 61) 0%, rgb(59, 47, 131) 100%)'}
							onChange={async (value: string | undefined) => {
								await handleOnChange({ ...settings.value, navigation: value });
							}}
						/>
						<LogoUpload
							title={'Navigation Logo Small'}
							fileId={settings?.value?.navigationSmall}
							loading={settings.loading}
							logoWidth={30}
							logoHeight={30}
							disabled={!isAdmin}
							background={'linear-gradient(0deg, rgb(27, 22, 61) 0%, rgb(59, 47, 131) 100%)'}
							onChange={async (value: string | undefined) => {
								await handleOnChange({ ...settings.value, navigationSmall: value });
							}}
						/>
					</>
				</CustomizeBox>
			</ContentWrapper>
		</>
	);
}

interface LogoUploadProps {
	title: string;
	loading: boolean;
	fileId?: string;
	background: string;
	logoWidth: number;
	logoHeight: number;
	disabled: boolean;
	onChange: (value: string | undefined) => void;
}

function LogoUpload({
	title,
	loading,
	fileId,
	background,
	logoWidth,
	logoHeight,
	disabled,
	onChange,
}: LogoUploadProps): ReactElement {
	return (
		<Stack size="xSmall">
			<Text variant="mediumStrong" color="neutral800">
				{title}
			</Text>
			<Text variant="medium" color="neutral700">
				Max height: {logoHeight}px (2x for retina screens), max width: {logoWidth}px)
			</Text>
			<div style={{ width: 400, paddingTop: 16, paddingBottom: 16 }}>
				<FileUpload
					value={fileId}
					onChange={onChange}
					accept="image/png, image/svg+xml"
					useTemporaryFiles={true}
					disabled={disabled}
				/>
			</div>
			{loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} />
				</Stack>
			) : (
				fileId && (
					<Box p="small" sx={{ width: logoWidth + 2 * 16, background: background, borderRadius: 8 }}>
						<img
							src={`/ui/files/${fileId}/image`}
							alt={title}
							style={{
								width: 'fit-content',
								height: 'fit-content',
								maxWidth: logoWidth,
								maxHeight: logoHeight,
							}}
						/>
					</Box>
				)
			)}
		</Stack>
	);
}

function CustomizeBox({ children }: { children: ReactElement }): ReactElement {
	return (
		<Stack
			size="xLarge"
			sx={{
				justifyContent: 'space-between',
				border: '1px solid',
				borderColor: 'neutral300',
				borderRadius: '8px',
				p: 'medium',
			}}
		>
			{children}
		</Stack>
	);
}
