/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconChevronDown, IconChevronUp, IconRemove, IconSearch } from 'components/icons';
import { Dropdown, RenderDropdownContent } from '@steadybit/ui-components-lib';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { Tooltip, Container, Text } from 'components';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

interface Value {
	value: string;
	label: string;
}

interface MultiSelectDropdownProps<T> {
	width: number | string | undefined;
	variant?: 'light' | 'dark';
	selectedValues: Value[];
	placeholder?: string;
	value: string;
	onRemoveSelectedValue: (selectedValue: Value) => void;
	onValueChanged: (value: T) => void;
	children: RenderDropdownContent;
}

export default function MultiSelectDropdown(props: MultiSelectDropdownProps<string>): ReactElement {
	const { variant = 'dark', selectedValues, onValueChanged, onRemoveSelectedValue, children } = props;

	const backgroundColor = variant === 'light' ? 'neutral000' : 'neutral200';

	return (
		<Dropdown<HTMLDivElement> renderDropdownContent={children} snapToTop>
			{({ setRefElement, isOpen, setOpen }) => {
				const Icon = isOpen ? IconChevronUp : IconChevronDown;
				return (
					<Container
						ref={setRefElement}
						display="flex"
						alignItems="center"
						backgroundColor={backgroundColor}
						width={props.width}
						sx={{
							...(isOpen
								? {
										borderLeft: '3px solid ' + theme.colors.slate,
										borderRight: '3px solid ' + theme.colors.slate,
										borderTop: '3px solid ' + theme.colors.slate,
									}
								: { border: '2px solid transparent', outline: '1px solid ' + theme.colors.neutral300 }),
							borderRadius: isOpen ? '4px 4px 0 0' : 0,
						}}
					>
						<IconSearch variant="small" onClick={() => setOpen(!isOpen)} ml="xSmall" />

						{selectedValues.length > 0 && (
							<Container display="flex" pr="xxSmall">
								{selectedValues.map((selectedValue) => (
									<SelectedValue
										key={selectedValue.value}
										value={selectedValue}
										onRemove={() => onRemoveSelectedValue(selectedValue)}
									/>
								))}
							</Container>
						)}

						<Container
							as="input"
							value={props.value}
							flexGrow={1}
							onChange={(e) => onValueChanged(e.target.value)}
							onFocus={() => setOpen(true)}
							minWidth={120}
							sx={{
								height: 36,
								ml: 'xSmall',
								backgroundColor,
								color: 'neutral800',
								border: 'none',
								':focus': {
									outline: 'none',
								},
							}}
						/>

						<Icon
							variant="small"
							sx={{
								mx: 'xSmall',
								cursor: 'pointer',
							}}
							onClick={() => setOpen(!isOpen)}
						/>
					</Container>
				);
			}}
		</Dropdown>
	);

	// return (
	// 	<Dropdown
	// 		{...props}
	// 		renderComponent={({ showMenu, setShowMenu, value, ref }) => {
	// 			const Icon = showMenu ? IconChevronUp : IconChevronDown;
	// 			return (
	// 				<Container
	// 					display="flex"
	// 					alignItems="center"
	// 					backgroundColor={backgroundColor}
	// 					width={props.width}
	// 					ref={ref}
	// 					sx={{
	// 						...(showMenu
	// 							? {
	// 									borderLeft: '3px solid ' + theme.colors.slate,
	// 									borderRight: '3px solid ' + theme.colors.slate,
	// 									borderTop: '3px solid ' + theme.colors.slate,
	// 									borderBottom: '3px solid ' + theme.colors.slate,
	// 								}
	// 							: { border: '2px solid transparent', outline: '1px solid ' + theme.colors.neutral300 }),
	// 						borderRadius: showMenu ? '4px 4px 0 0' : 0,
	// 					}}
	// 				>
	// 					<IconSearch variant="small" onClick={() => setShowMenu(!showMenu)} ml="xSmall" />

	// 					{selectedValues.length > 0 && (
	// 						<Container display="flex" pr="xxSmall">
	// 							{selectedValues.map((selectedValue) => (
	// 								<SelectedValue
	// 									key={selectedValue.value}
	// 									value={selectedValue}
	// 									onRemove={() => onRemoveSelectedValue(selectedValue)}
	// 								/>
	// 							))}
	// 						</Container>
	// 					)}

	// 					<Container
	// 						as="input"
	// 						flexGrow={1}
	// 						value={value}
	// 						onChange={(e) => onValueChanged?.(e.target.value)}
	// 						onFocus={() => setShowMenu(true)}
	// 						minWidth={120}
	// 						sx={{
	// 							height: 36,
	// 							ml: 'xSmall',
	// 							backgroundColor,
	// 							color: 'neutral800',
	// 							border: 'none',
	// 							':focus': {
	// 								outline: 'none',
	// 							},
	// 						}}
	// 					/>

	// 					<Icon
	// 						variant="small"
	// 						sx={{
	// 							mx: 'xSmall',
	// 							cursor: 'pointer',
	// 						}}
	// 						onClick={() => setShowMenu(!showMenu)}
	// 					/>
	// 				</Container>
	// 			);
	// 		}}
	// 	/>
	// );
}

interface SelectedValueProps {
	value: Value;
	onRemove: () => void;
}

function SelectedValue({ value, onRemove }: SelectedValueProps): ReactElement {
	return (
		<Tooltip content={value.label}>
			<Container
				sx={{
					display: 'flex',
					alignItems: 'center',
					ml: 'xSmall',
					pl: 'xxSmall',
					backgroundColor: 'neutral00',
					color: 'neutral800',
					border: '1px solid ' + theme.colors.neutral400,
					borderRadius: 4,
					...textEllipsis,
				}}
			>
				<Text
					sx={{
						fontSize: 12,
						lineHeight: '14px',
						...textEllipsis,
					}}
				>
					{value.label}
				</Text>

				<IconRemove
					sx={{
						minHeight: 16,
						minWidth: 18,
						px: 'xxSmall',
						color: 'neutral400',
						cursor: 'pointer',
						'&:hover': { color: 'neutral600' },
					}}
					onClick={onRemove}
				/>
			</Container>
		</Tooltip>
	);
}
