/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, Grid, Text } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO, TeamVO } from 'ui-api';
import { withBaseHref } from 'utils/getBaseHref';
import { IconSaveFile } from 'components/icons';
import { ButtonIcon, Link } from 'components';
import { useUrlState } from 'url/useUrlState';
import { useTenant } from 'tenancy/useTenant';
import { startCase, toLower } from 'lodash';
import { ReactElement } from 'react';
import { days } from 'utils/time';

import {
	directionParam,
	environmentIdsParam,
	pageParam,
	sortParam,
	statesParam,
	teamIdsParam,
	timeParam,
	UrlState,
} from './urlParams';
import { FilterDropDown, FilterDropDownListWrapper } from './FilterDropDown';
import Times, { getTimeLabel } from './Times';
import Environments from './Environments';
import States from './States';
import Teams from './Teams';

interface FilterBarProps {
	environments: EnvironmentSummaryVO[];
	loading: boolean;
	teams: TeamVO[];
}

export default function FilterBar({ loading, environments, teams }: FilterBarProps): ReactElement {
	const [{ environmentIds, time, teamIds, states }, , updateUrlState] = useUrlState<UrlState>([
		environmentIdsParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
	]);

	return (
		<Flex spacing="small">
			<Text type="mediumStrong">Filter by :</Text>
			<Grid cols="1fr 1fr 1fr 1fr 40px" spacing="medium" align="end" style={{ width: '100%' }}>
				<FilterDropDown
					placeholder="Type to search"
					loading={loading}
					name="Team"
					label={teamIds
						.map((teamId) => teams.find((team) => team.id === teamId))
						.filter((team): team is TeamVO => !!team)
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((team) => team.name)
						.concat(teamIds.filter((teamId) => teams.find((team) => team.id === teamId) === undefined))
						.join(', ')}
					clearAll={() => updateUrlState({ teamIds: [] })}
				>
					{({ value, width }) => (
						<FilterDropDownListWrapper width={width}>
							<Teams
								selectedTeamIds={teamIds}
								teams={teams}
								value={value}
								width={width}
								toggleId={(_teamId) => {
									updateUrlState({
										teamIds:
											teamIds.indexOf(_teamId) !== -1 ? teamIds.filter((id) => id !== _teamId) : [...teamIds, _teamId],
									});
								}}
								selectIds={(_teamIds) => {
									updateUrlState({
										teamIds: Array.from(new Set([...teamIds, ..._teamIds])),
									});
								}}
								deselectIds={(_teamIds) => {
									updateUrlState({
										teamIds: teamIds.filter((id) => !_teamIds.includes(id)),
									});
								}}
							/>
						</FilterDropDownListWrapper>
					)}
				</FilterDropDown>

				<FilterDropDown
					placeholder="Select a data range"
					name="Time range"
					loading={loading}
					label={getTimeLabel(time)}
				>
					{({ selectItem }) => (
						<Times
							selectedTime={time}
							selectItem={(time) => {
								updateUrlState({ time });
								if (!Array.isArray(time)) {
									selectItem('time');
								}
							}}
						/>
					)}
				</FilterDropDown>

				<FilterDropDown
					placeholder="Type to search"
					name="Environment"
					loading={loading}
					label={environmentIds
						.map((envId) => environments.find((env) => env.id === envId))
						.filter((env): env is EnvironmentSummaryVO => !!env)
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((e) => e.name)
						.concat(environmentIds.filter((envId) => environments.find((env) => env.id === envId) === undefined))
						.join(', ')}
					clearAll={() => updateUrlState({ environmentIds: null })}
				>
					{({ value, width }) => (
						<FilterDropDownListWrapper width={width}>
							<Environments
								environments={environments}
								value={value}
								selectedEnvironmentIds={environmentIds}
								width={width}
								toggleId={(_envId) => {
									updateUrlState({
										environmentIds:
											environmentIds.indexOf(_envId) !== -1
												? environmentIds.filter((id) => id !== _envId)
												: [...environmentIds, _envId],
									});
								}}
								selectIds={(_envIds) => {
									updateUrlState({
										environmentIds: _envIds,
									});
								}}
								deselectIds={(_envIds) => {
									updateUrlState({
										environmentIds: environmentIds.filter((id) => !_envIds.includes(id)),
									});
								}}
							/>
						</FilterDropDownListWrapper>
					)}
				</FilterDropDown>

				<FilterDropDown
					placeholder="Type to search"
					loading={loading}
					name="State"
					label={states
						.map((s) => startCase(toLower(s)))
						.sort((a, b) => a.localeCompare(b))
						.join(', ')}
					clearAll={() => updateUrlState({ states: null })}
				>
					{({ value, width }) => (
						<FilterDropDownListWrapper width={width}>
							<States
								states={['CANCELED', 'COMPLETED', 'CREATED', 'ERRORED', 'FAILED', 'PREPARED', 'RUNNING']}
								value={value}
								selectedStates={states}
								width={width}
								selectItem={(_state) => {
									updateUrlState({
										states: states.indexOf(_state) !== -1 ? states.filter((id) => id !== _state) : [...states, _state],
									});
								}}
								selectAll={(_states) => {
									updateUrlState({
										states: Array.from(new Set([...states, ..._states])),
									});
								}}
							/>
						</FilterDropDownListWrapper>
					)}
				</FilterDropDown>

				<DownloadAsCSVButton />
			</Grid>
		</Flex>
	);
}

function DownloadAsCSVButton(): ReactElement {
	const tenant = useTenant();
	const [{ environmentIds, time, teamIds, states }] = useUrlState<UrlState>([
		environmentIdsParam,
		directionParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
		sortParam,
	]);

	const params = new URLSearchParams();
	if (teamIds.length > 0) {
		params.append('teamIds', teamIds.join(','));
	}
	if (environmentIds.length > 0) {
		params.append('environmentIds', environmentIds.join(','));
	}
	if (states.length > 0) {
		params.append('states', states.join(','));
	}

	if (time) {
		let createdFrom: number | null = null;
		let createdTo: number | null = null;
		createdFrom = new Date().getTime();
		createdTo = new Date().getTime();
		if (Array.isArray(time)) {
			createdFrom = time[0];
			createdTo = time[1];
		} else if (time === 'lastDay') {
			createdFrom = createdTo - days(1).getMillis();
		} else if (time === 'lastWeek') {
			createdFrom = createdTo - days(7).getMillis();
		} else if (time === 'lastMonth') {
			createdFrom = createdTo - days(31).getMillis();
		}
		params.append('createdFrom', String(createdFrom));
		params.append('createdTo', String(createdTo));
	}

	params.append('tenantKey', tenant.key);

	return (
		<Link href={withBaseHref(`/ui/reports/experiments/runs?${params.toString()}`)}>
			<ButtonIcon
				variant="small"
				tooltip="Download as CSV"
				sx={{ borderColor: 'neutral300', height: '40px', width: '40px' }}
			>
				<IconSaveFile />
			</ButtonIcon>
		</Link>
	);
}
