/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Spacings } from '@steadybit/ui-components-lib';

export const theme = {
	useCustomProperties: false,

	space: Spacings,
	colors: Colors,

	breakpoints: ['60em', '80em'],

	fonts: {
		heading: '"Inter", sans-serif',
		body: '"Inter", sans-serif',
		code: '"Fira Code", monospace',
	},

	shadows: {
		applicationLarge: '0px 0px 2px 1px rgba(21, 31, 45, 0.05), 0px 8px 16px rgba(21, 31, 45, 0.1)',
		applicationMedium: '0px 1px 2px rgba(21, 31, 45, 0.15), 0px 4px 8px rgba(21, 31, 45, 0.05)',
		applicationSmall: '0px 1px 3px rgba(56, 73, 97, 0.1)',

		boxLarge: '0px 1px 2px rgba(21, 31, 45, 0.15), 0px 4px 8px rgba(21, 31, 45, 0.05)',
		boxMedium: 'none',

		experimentItemHold: '0px 2px 4px 1px rgba(21, 31, 45, 0.08), 0px 8px 16px rgba(21, 31, 45, 0.15)',
	},

	buttons: {
		chromeless: {
			appearance: 'none',
			border: 'none',
			background: 'transparent',
			padding: '0px',
			margin: '0px',
			color: 'inherit',
			textAlign: 'left',
			fontFamily: 'inherit',
			fontSize: 'inherit',
			fontWeight: 'inherit',

			':hover': {
				color: 'slate',
			},

			':disabled': {
				color: '#222222',
			},
		},

		chromelessSmall: (theme) => {
			return {
				...theme.buttons.chromeless,
				...theme.text.smallStrong,
				px: 'small',
				py: 'xxSmall',
				height: '32px',
			};
		},

		chromelessDanger: (theme) => ({
			...theme.buttons.chromeless,
			color: 'coral',
			fontSize: 2,

			':hover': {
				color: 'coralDark',
			},
		}),

		chromelessDangerSmall: (theme) => ({
			...theme.buttons.chromeless,
			...theme.text.smallStrong,
			px: 'small',
			py: 'xxSmall',
			height: '32px',
			color: 'coral',
		}),

		primary: {
			variant: 'text.mediumStrong',
			color: 'neutral000',
			bg: 'slate',
			px: 'small',
			py: 'xSmall',
			height: '39px',
			borderRadius: '4px',
			transition: 'color, background-color',
			transitionDuration: '200ms',
			boxShadow: '0px 1px 3px rgba(44, 68, 106, 0.15)',
			':hover': {
				bg: 'slateDark',
			},
		},

		primaryRun: {
			variant: 'text.mediumStrong',
			color: 'neutral000',
			px: 'small',
			py: 'xSmall',
			height: '39px',
			borderRadius: '4px',
			transition: 'color, background-color',
			transitionDuration: '200ms',
			boxShadow: '0px 1px 2px rgba(21, 31, 45, 0.5), 0px 2px 8px rgba(21, 31, 45, 0.25)',
			background: 'linear-gradient(0deg, #7261D7 0%, #8A7BE2 100%)',
			':hover': {
				bg: 'slateDark',
			},
		},

		primaryRunSmall: (theme) => {
			return {
				...theme.buttons.primaryRun,
				...theme.text.smallStrong,
				px: 'small',
				py: 'xxSmall',
				height: '32px',
			};
		},

		primarySmall: (theme) => {
			return {
				...theme.buttons.primary,
				...theme.text.smallStrong,
				px: 'small',
				py: 'xxSmall',
				height: '32px',
				borderRadius: '2px',
			};
		},

		primaryLarge: (theme) => {
			return {
				...theme.buttons.primary,
				...theme.text.largeStrong,
				px: 'small',
				py: 'xSmall',
				height: '50px',
			};
		},

		secondary: {
			variant: 'text.mediumStrong',
			color: 'neutral700',
			background: 'linear-gradient(180deg, #FFFFFF 0%, #F4F7FB 100%)',
			px: 'small',
			py: 'xSmall',
			height: '40px',
			transition: 'color',
			transitionDuration: '200ms',
			borderRadius: '4px',
			border: '1px solid',
			borderColor: 'neutral300',

			':hover': {
				color: 'white',
				background: '#5B48CA',
			},
			':disabled': {
				background: '#e3e7f9',
				borderColor: 'neutral400',
				color: '#222222',
			},
		},

		secondarySmall: (theme) => ({
			...theme.buttons.secondary,
			...theme.text.smallStrong,
			px: 'small',
			py: 'xxSmall',
			height: '32px',
			borderRadius: '2px',
		}),

		secondaryXSmall: (theme) => {
			return {
				...theme.buttons.secondary,
				...theme.text.xSmall,
				px: 'xxSmall',
				py: 'xxxSmall',
				height: '32px',
			};
		},

		secondaryXXSmall: (theme) => {
			return {
				...theme.buttons.secondary,
				...theme.text.xSmall,
				px: 'xxxSmall',
				py: 'xxxSmall',
				height: '32px',
				borderRadius: '2px',
			};
		},

		secondaryLarge: (theme) => {
			return {
				...theme.buttons.secondary,
				...theme.text.largeStrong,
				px: 'small',
				py: 'xSmall',
				height: '50px',
			};
		},

		primaryAttention: (theme) => {
			return {
				...theme.buttons.primary,
				bg: 'coral',
			};
		},

		select: {
			height: '42px',
			variant: 'text.medium',
			borderRadius: '4px',
			px: '12px',
			py: '0px',
			border: '1px solid',
			borderColor: 'neutral200',
			color: 'neutral800',
			backgroundColor: 'transparent',
			backgroundImage: 'linear-gradient(180deg, rgba(244, 247, 251, 0) 0%, #F4F7FB 100%);',
			transition: 'border 150ms',
			':disabled': {
				backgroundColor: 'neutral100',
				backgroundImage: 'none',
				borderColor: 'neutral200',
				color: 'neutral600',
			},
			':focus': {
				outline: 'none',
				boxShadow: ({ colors }) => `inset 0 0 0 1px ${colors.slate}`,
				borderColor: 'slate',
			},
			':hover': {
				color: 'white',
				backgroundColor: '#5B48CA',
				backgroundImage: 'none',
			},
		},

		selectSmall: (theme) => {
			return {
				...theme.buttons.select,
				height: '34px',
				variant: 'text.small',
			};
		},

		nav: {
			variant: 'text.mediumStrong',
			color: 'neutral000',
			bg: 'transparent',
			transition: 'color',
			transitionDuration: '200ms',
			outline: 'none',
			borderRadius: '8px',

			':hover, &[data-active=true]': {
				backgroundColor: 'neutral700',
			},
		},

		onboardingNav: {
			variant: 'text.mediumStrong',
			color: 'neutral400',
			bg: 'transparent',
			height: '40px',
			transition: 'color',
			transitionDuration: '200ms',
			outline: 'none',
			':hover': {
				color: 'neutral800',
			},
		},

		landscapeHeader: (theme) => ({
			...theme.buttons.secondary,
			...theme.text.smallStrong,
			px: 'small',
			py: 'xxSmall',
			height: '32px',
			background: 'neutral700',
			color: 'neutral000',
			borderColor: 'neutral600',
			':hover': {
				background: 'neutral600',
			},
			':disabled': {
				borderColor: 'neutral100',
				color: 'neutral200',
			},
		}),
		landscapeHeaderPrimary: (theme) => ({
			...theme.buttons.landscapeHeader,
			bg: 'neutral600',
		}),
	},

	buttonLongClick: {
		secondarySmall: {
			display: 'flex',
			alignItems: 'center',
			px: 'small',
			height: '32px',

			variant: 'text.smallStrong',
			overflow: 'hidden',
			color: 'neutral700',
			background: 'linear-gradient(180deg, #FFFFFF 0%, #F4F7FB 100%)',
			transition: 'color',
			transitionDuration: '200ms',

			borderRadius: '4px',
			border: '1px solid',
			borderColor: 'neutral300',
			':hover': {
				cursor: 'pointer',
				color: 'neutral000',
				background: '#3B2F83',
			},
			':disabled': {
				color: 'neutral700',
				background: 'linear-gradient(180deg, #FFFFFF 0%, #F4F7FB 100%)',
				cursor: 'default',
				opacity: 0.75,
			},
		},
		primarySmall: (theme) => {
			return {
				...theme.buttonLongClick.secondarySmall,
				background: theme.colors.slate,
				color: theme.colors.neutral000,
				':hover': {
					cursor: 'pointer',
					background: theme.colors.purple800,
				},
				':disabled': {
					background: theme.colors.slate,
					cursor: 'default',
					opacity: 0.5,
				},
			};
		},
	},

	buttonLongClickProgress: {
		secondarySmall: {
			background: '#5B48CA',
		},
		primarySmall: {
			background: '#5B48CA',
		},
	},

	buttonRound: {
		primary: {
			color: 'neutral000',
			background: 'linear-gradient(0deg, #7261D7 0%, #8A7BE2 100%)',
			borderRadius: '50%',
		},
		primaryClicking: {
			color: 'neutral000',
			background: 'slate',
			borderRadius: '50%',
		},
		secondary: {
			boxShadow: '0px 1px 2px rgb(21 31 45 / 15%), 0px 4px 8px rgb(21 31 45 / 5%)',
			color: 'neutral600',
			backgroundColor: 'neutral200',
			borderRadius: '50%',

			':hover': {
				background: 'linear-gradient(0deg, #7261D7 0%, #8A7BE2 100%)',
				color: 'neutral000',
			},
		},
		danger: {
			color: 'neutral000',
			background: 'linear-gradient(0deg, #F94D4C 0%, #FF6362 100%)',
			borderRadius: '50%',
		},
	},

	buttonRun: {
		primarySmall: {
			variant: ['buttons.chromeless', 'text.smallStrong'],
			height: '32px',
			px: 'xxSmall',
		},
		primary: {
			variant: ['buttons.chromeless', 'text.mediumStrong'],
			height: '40px',
			px: 'xSmall',
		},
	},

	runButtonStop: {
		medium: {
			width: '24px',
			height: '24px',
		},
		large: {
			width: '32px',
			height: '32px',
		},
		xLarge: {
			width: '48px',
			height: '48px',
		},
	},

	runButtonPlay: {
		medium: {
			width: '24px',
			height: '24px',
		},
		large: {
			width: '32px',
			height: '32px',
		},
	},

	buttonSplitLeft: {
		primary: {
			variant: 'buttons.primary',
		},
		primaryLarge: {
			variant: 'buttons.primaryLarge',
		},
		primarySmall: {
			variant: 'buttons.primarySmall',
		},
		secondarySmall: {
			variant: 'buttons.secondarySmall',
		},
	},

	buttonSplitRight: {
		primary: {
			variant: 'buttons.primary',
			borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
			width: '36px',
		},
		primaryLarge: {
			variant: 'buttons.primaryLarge',
			borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
			width: '42px',
		},
		primarySmall: {
			variant: 'buttons.primarySmall',
			borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
			width: '36px',
		},
		secondarySmall: {
			variant: 'buttons.secondarySmall',
			borderLeft: '1px solid neutral300',
			width: '36px',
		},
	},

	buttonEmergency: {
		primary: {
			variant: 'buttons.primary',
		},
		primaryLarge: {
			variant: 'buttons.primaryLarge',
		},
	},

	links: {
		primary: {
			color: 'slate',
			textDecoration: 'none',

			':hover': {
				textDecoration: 'underline',
			},
		},

		secondary: {
			color: 'neutral800',
			':hover': {
				color: 'slate',
				textDecoration: 'none',
			},
		},

		muted: {
			color: 'neutral500',
			':hover': {
				color: 'neutral800',
				textDecoration: 'underline',
			},
		},

		chromeless: {
			color: 'currentcolor',
			':hover': {
				color: 'currentcolor',
				textDecoration: 'underline',
			},
		},
	},

	buttonIcon: {
		activityButton: {},
		xSmall: {
			width: '24px',
			height: '24px',

			svg: {
				width: '20px',
				height: '20px',
			},
		},
		small: {
			width: '32px',
			height: '32px',

			svg: {
				width: '20px',
				height: '20px',
			},
		},
		medium: {
			width: '40px',
			height: '40px',

			svg: {
				width: '20px',
				height: '20px',
			},
		},
		large: {
			width: '48px',
			height: '48px',

			svg: {
				width: '24px',
				height: '24px',
			},
		},
		userIcon: {
			width: '48px',
			height: '48px',

			svg: {
				width: '32px',
				height: '32px',
			},
		},
		buttonActivity: {
			width: '48px',
			height: '48px',

			svg: {
				width: '32px',
				height: '32px',
			},
		},
	},

	settingsGroup: {
		small: {
			'[data-textfield]': { variant: 'textfield.small', marginTop: '0px', height: 'auto' },
			'[data-select-button]': { variant: 'select.buttonSmall' },
			'[data-number-input]': { variant: 'numberInput.small' },
			'[data-formik-error]': { variant: 'text.xSmallStrong', marginTop: 'none' },
			'[data-settings-group-item]': { py: 'xSmall' },
			'[data-combobox]': { variant: 'combobox.small', marginTop: '0px' },
		},
		medium: {
			'[data-textfield]': { variant: 'textfield.medium' },
			'[data-select-button]': { variant: 'select.buttonMedium' },
			'[data-number-input]': { variant: 'numberInput.medium' },
			'[data-formik-error]': { variant: 'text.smallStrong' },
		},
	},

	tags: {
		large: {
			height: '40px',
			px: '10px',
			variant: 'text.largeStrong',
			'[data-tag-icon]': { mr: '10px', variant: 'icons.medium' },
			'[data-tag-icon-remove]': { transform: 'translateX(5px)', variant: 'icons.medium' },
		},
		medium: {
			height: '32px',
			px: '10px',
			variant: 'text.mediumStrong',
			'[data-tag-icon]': { mr: '10px', variant: 'icons.medium' },
			'[data-tag-icon-remove]': { transform: 'translateX(5px)', variant: 'icons.medium' },
		},
		small: {
			height: '24px',
			px: 'xSmall',
			variant: 'text.smallStrong',
			'[data-tag-icon]': { mr: '6px', variant: 'icons.small' },
			'[data-tag-icon-remove]': { transform: 'translateX(3px)', variant: 'icons.small' },
		},
		smallNonStrong: {
			height: '24px',
			px: 'xSmall',
			variant: 'text.small',
			'[data-tag-icon]': { mr: '6px', variant: 'icons.small' },
			'[data-tag-icon-remove]': { transform: 'translateX(3px)', variant: 'icons.small' },
		},
		xSmall: {
			px: 'xxSmall',
			height: '20px',
			variant: 'text.small',
			'[data-tag-icon-remove]': { transform: 'translateX(3px)', variant: 'icons.small' },
		},
		xxSmall: {
			px: 'xxSmall',
			height: '16px',
			variant: 'text.xSmall',
			'[data-tag-icon-remove]': { transform: 'translateX(3px)', variant: 'icons.small' },
		},
	},

	segmentedControl: {
		small: {
			px: 'small',
			py: 'xSmall',
			variant: 'text.smallStrong',
		},
		medium: {
			px: 'small',
			py: 'xSmall',
			variant: 'text.mediumStrong',
		},
	},

	tabs: {
		tab: {
			medium: {
				variant: 'text.mediumStrong',
				px: '24px',
				py: '12px',
			},

			large: {
				variant: 'text.largeStrong',
				px: '24px',
				py: '20px',
			},
		},
	},

	select: {
		medium: {
			variant: 'buttons.select',
			'[data-select-inline-label]': {
				variant: 'text.mediumStrong',
			},
			'[data-select-selected-item]': {
				variant: 'text.medium',
			},
		},
		small: {
			variant: 'buttons.selectSmall',
			'[data-select-inline-label]': {
				variant: 'text.smallStrong',
			},
			'[data-select-selected-item]': {
				variant: 'text.small',
			},
		},
		chromeless: {
			variant: 'text.medium',
			fontSize: 'inherit',
			border: 'none',
			color: 'slate',
			p: '0px',
			background: 'transparent',
			':disabled': {
				color: 'neutral600',
			},
			':focus': {
				outline: 'none',
				boxShadow: ({ colors }) => `inset 0 0 0 1px ${colors.slate}`,
			},
			':hover': {
				textDecoration: 'underline',
				textUnderlineOffset: '2px',
			},
			medium: {
				variant: 'text.medium',
			},
			small: {
				variant: 'text.small',
			},
			svg: {
				color: 'slate',
			},
		},
		chromelessMediumStrong: (theme) => {
			return {
				...theme.select.chromeless,
				...theme.text.mediumStrong,
			};
		},
	},

	textfield: {
		small: {
			height: '34px',
			'[data-textfield-input]': {
				variant: 'text.small',
			},
			'[data-textfield-icon-right]': {
				variant: 'buttonIcon.xSmall',
			},
		},
		smallHeading: {
			height: '34px',
			'[data-textfield-input]': {
				variant: 'heading.small',
			},
			'[data-textfield-icon-right]': {
				variant: 'buttonIcon.xSmall',
			},
		},
		stepEdit: {
			'[data-textfield-input]': {
				variant: 'heading.medium',
			},
		},
		medium: {
			height: '42px',
			'[data-textfield-input]': {
				variant: 'text.medium',
			},
			'[data-textfield-icon-right]': {
				variant: 'buttonIcon.small',
			},
		},
	},

	combobox: {
		small: {
			'[data-textfield]': {
				variant: 'textfield.small',
			},
			'[data-combobox-remove-icon]': {
				variant: 'buttonIcon.xSmall',
			},
		},
		medium: {
			'[data-textfield]': {
				variant: 'textfield.medium',
			},
			'[data-combobox-remove-icon]': {
				variant: 'buttonIcon.small',
			},
		},
	},

	numberInput: {
		smallStrong: {
			input: {
				variant: 'text.smallStrong',
			},
		},
		mediumStrong: {
			input: {
				variant: 'text.mediumStrong',
			},
		},
	},

	selectOptions: {
		small: {
			'[data-select-option-group]': {
				variant: 'text.smallStrong',
			},
			'[data-select-option-group-text]': {
				variant: 'text.small',
			},
			'[data-select-option-text]': {
				variant: 'text.small',
			},
			'[data-select-option-text-selected]': {
				variant: 'text.smallStrong',
			},
		},
		medium: {
			'[data-select-option-group]': {
				variant: 'text.mediumStrong',
			},
			'[data-select-option-group-text]': {
				variant: 'text.medium',
			},
			'[data-select-option-text]': {
				variant: 'text.medium',
			},
			'[data-select-option-text-selected]': {
				variant: 'text.mediumStrong',
			},
		},
	},

	predicateBuilder: {
		small: {
			gap: 'xSmall',
		},
		medium: {
			gap: 'small',
		},
	},

	table: {
		default: {
			'& td, & th': {
				py: '0px',
				px: 'xSmall',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral300',
				verticalAlign: 'middle',
			},

			'& td': {
				variant: 'text.small',
				color: 'neutral800',
				'& > div': {
					minHeight: '3.5rem',
				},
			},

			'& th': {
				variant: 'text.tableHeader',
				color: 'neutral600',
				borderBottomWidth: '2px',
				bg: 'neutral100',
				'& > div': {
					minHeight: '2.75rem',
				},
			},
		},

		defaultSmall: {
			'& td, & th': {
				py: '0px',
				px: 'xxSmall',
				borderBottom: '1px solid',
				borderBottomColor: 'neutral200',
				verticalAlign: 'middle',
			},

			'& td': {
				variant: 'text.small',
				color: 'neutral800',
				'& > div': {
					minHeight: '2rem',
				},
			},

			'& th': {
				variant: 'text.tableHeader',
				textTransform: 'uppercase',
				color: 'neutral600',
				'& > div': {
					minHeight: '1.5rem',
				},
			},
		},

		inline: {
			'& td, & th': {
				pt: '0px',
				px: 'xSmall',
				verticalAlign: 'middle',
			},
			'& td': {
				variant: 'text.medium',
				color: 'neutral800',
				'& > div': {
					minHeight: '2.5rem',
				},
			},

			'& th': {
				variant: 'text.tableHeader',
				textTransform: 'uppercase',
				color: 'neutral600',
				bg: 'neutral100',
				'& > div': {
					minHeight: '2.5rem',
				},
			},
		},
	},

	targetIndicator: {
		icon: {
			medium: {
				width: '42px',
				height: '42px',
			},
			large: {
				width: '64px',
				height: '64px',
			},
		},

		label: {
			lineHeight: 1.3,

			medium: {
				variant: 'text.medium',
				fontSize: 4,
			},
			large: {
				variant: 'text.medium',
				fontSize: 5,
			},
		},
	},

	radioButton: {
		medium: {
			px: 'small',
			py: 'xSmall',
			variant: 'text.mediumStrong',
			flex: '1 1 auto',
			border: '2px solid',
			width: '100%',
		},
		large: {
			px: 'medium',
			py: 'small',
			variant: 'text.mediumStrong',
			flex: '1 1 auto',
			border: '2px solid',
			width: '100%',
		},
		small: {
			px: '7px',
			py: '3px',
			variant: 'text.smallStrong',
			flex: '0 1 auto',
			border: '1px solid',
		},
	},

	pill: {
		medium: {
			px: 'xSmall',
			variant: 'text.xSmallStrong',
			borderRadius: '10px',
			py: 'xxSmall',
		},
	},

	numberIndicator: {
		small: {
			px: 'xxSmall',
			variant: 'text.xSmallStrong',
			height: '20px',
			minWidth: '20px',
			borderRadius: '10px',
		},
		medium: {
			px: 'xSmall',
			variant: 'text.smallStrong',
			height: '32px',
			minWidth: '32px',
			borderRadius: '16px',
		},
		large: {
			px: 'small',
			variant: 'text.largeStrong',
			height: '41px',
			minWidth: '41px',
			borderRadius: '20px',
		},
	},

	alert: {
		high: { bg: 'coral' },
		medium: { bg: 'experimentWarning' },
		low: { bg: 'neutral400' },
	},

	icons: {
		xxSmall: {
			width: '8px',
			height: '8px',
		},
		xSmall: {
			width: '12px',
			height: '12px',
		},
		small: {
			width: '16px',
			height: '16px',
		},
		medium: {
			width: '20px',
			height: '20px',
		},
		large: {
			width: '24px',
			height: '24px',
		},
		xLarge: {
			width: '48px',
			height: '48px',
		},
		xxLarge: {
			width: '64px',
			height: '64px',
		},
		xxxLarge: {
			width: '144px',
			height: '72px',
		},
	},

	userIcon: {
		medium: {
			height: '24px',
		},
		large: {
			height: '32px',
		},
	},

	buttonActivity: {
		medium: {
			width: '24px',
			height: '24px',
		},
		large: {
			width: '32px',
			height: '32px',
		},
	},

	experimentEditor: {
		lane: {
			default: {
				bg: 'neutral100',
				'&[data-hovered="true"]': {
					bg: 'slateMidLight20p',
					borderColor: 'slateMid',
				},
			},
			dragging: {
				bg: 'neutral200',
				boxShadow: ({ colors }) => `2px dotted ${colors.neutral300}`,
			},
		},
		laneInner: {
			default: {
				border: '1px solid transparent',
			},
			dragging: {
				bg: 'neutral000',
				border: '1px solid',
				borderColor: 'slateMid',
				boxShadow: 'experimentItemHold',
				userSelect: 'none',
			},
		},
		step: {
			default: {
				border: '1px solid transparent',
				'&[data-invalid="true"]': {
					borderColor: 'coral',
				},
				'&[data-hovered="true"]': {
					borderColor: 'slate',
				},
				bg: 'transparent',
				transition: '200ms border-color, 200ms background-color',
			},
			dragging: {
				bg: 'slateMidLight40p',
				border: '1px dashed',
				borderColor: 'slate',
			},
			draggedOff: {
				color: 'transparent',
				bg: 'transparent',
				border: '1px dashed',
				borderColor: 'transparent',
			},
		},
		stepInner: {
			default: {
				border: '1px solid transparent',
			},
			dragging: {
				boxShadow: 'experimentItemHold',
				border: '1px solid',
				borderColor: 'slateMid',
			},
			selected: {
				border: '1px solid',
				borderColor: 'neutral000',
				bg: 'transparent',
				boxShadow: ({ colors }) => `0 0 0 2px ${colors.slate}`,
			},
		},
	},

	experimentPlayer: {
		cursor: {
			slate: {
				bg: 'slate',
			},
			hover: {
				bg: 'neutral500',
			},
			success: {
				bg: 'cyanDark',
			},
			failed: {
				bg: 'experimentWarning',
			},
			errored: {
				bg: 'coral',
			},
		},
	},

	charts: {
		grid: {
			stroke: 'neutral100',
		},

		axes: {
			stroke: 'neutral500',

			fontFamily: '"Inter", sans-serif',
			lineHeight: '16px',
			fontSize: '12px',
			fontWeight: 400,
		},

		colors: ['experimentWarning', 'cyanDark', 'slate', 'blueMid', 'experimentWarning', 'cyan'],
	},

	fontSizes: ['10px', '13px', '15px', '17px', '20px', '32px'],

	fontWeights: {
		body: 400,
		medium: 500,
		strong: 600,
		bold: 700,
	},

	lineHeights: {
		bodyXSmall: '15px',
		bodySmall: '19.5px',
		body: '22.5px',
		large: '25.5px',
		xLarge: '30px',
		xxLarge: '42px',
	},

	heading: {
		xLarge: {
			fontFamily: 'heading',
			lineHeight: '31px',
			fontSize: '24px',
			fontWeight: 'bold',
		},
		large: {
			fontFamily: 'heading',
			lineHeight: '31.2px',
			fontSize: 4,
			fontWeight: 'bold',
		},
		medium: {
			fontFamily: 'heading',
			lineHeight: '22.1px',
			fontSize: 3,
			fontWeight: 'bold',
		},
		small: {
			fontFamily: 'heading',
			lineHeight: '22.5px',
			fontSize: 2,
			fontWeight: 'bold',
		},
		xSmall: {
			fontFamily: 'heading',
			lineHeight: '19.5px',
			fontSize: 1,
			fontWeight: 'bold',
		},
	},

	text: {
		large: {
			fontFamily: 'body',
			lineHeight: 'large',
			fontSize: 3,
			fontWeight: 'body',
		},
		largeMedium: {
			fontFamily: 'body',
			lineHeight: 'large',
			fontSize: 3,
			fontWeight: 'medium',
		},
		largeStrong: {
			fontFamily: 'body',
			lineHeight: 'large',
			fontSize: 3,
			fontWeight: 'strong',
		},
		xxLarge: {
			fontFamily: 'body',
			lineHeight: 'xxLarge',
			fontSize: 5,
			fontWeight: 'body',
		},
		xxLargeMedium: {
			fontFamily: 'body',
			lineHeight: 'xxLarge',
			fontSize: 5,
			fontWeight: 'medium',
		},
		xxLargeStrong: {
			fontFamily: 'body',
			lineHeight: 'xxLarge',
			fontSize: 5,
			fontWeight: 'strong',
		},
		xLarge: {
			fontFamily: 'body',
			lineHeight: 'xLarge',
			fontSize: 4,
			fontWeight: 'body',
		},
		xLargeMedium: {
			fontFamily: 'body',
			lineHeight: 'xLarge',
			fontSize: 4,
			fontWeight: 'medium',
		},
		xLargeStrong: {
			fontFamily: 'body',
			lineHeight: 'xLarge',
			fontSize: 4,
			fontWeight: 'strong',
		},
		medium: {
			fontFamily: 'body',
			lineHeight: 'body',
			fontSize: 2,
			fontWeight: 'body',
		},
		mediumMedium: {
			fontFamily: 'body',
			lineHeight: 'body',
			fontSize: 2,
			fontWeight: 'medium',
		},
		mediumStrong: {
			fontFamily: 'body',
			lineHeight: 'body',
			fontSize: 2,
			fontWeight: 'strong',
		},
		small: {
			fontFamily: 'body',
			lineHeight: 'bodySmall',
			fontSize: 1,
			fontWeight: 'body',
		},
		smallMedium: {
			fontFamily: 'body',
			lineHeight: 'bodySmall',
			fontSize: 1,
			fontWeight: 'medium',
		},
		smallStrong: {
			fontFamily: 'body',
			lineHeight: 'bodySmall',
			fontSize: 1,
			fontWeight: 'strong',
		},
		xSmall: {
			fontFamily: 'body',
			lineHeight: 'bodyXSmall',
			fontSize: 0,
			fontWeight: 'body',
		},
		xSmallMedium: {
			fontFamily: 'body',
			lineHeight: 'bodyXSmall',
			fontSize: 0,
			fontWeight: 'medium',
		},
		xSmallStrong: {
			fontFamily: 'body',
			lineHeight: 'bodyXSmall',
			fontSize: 0,
			fontWeight: 'strong',
		},
		smallCode: {
			fontFamily: 'code',
			lineHeight: '18px',
			fontSize: '12px',
			fontWeight: 'body',
		},
		largeCode: {
			fontFamily: 'code',
			lineHeight: 'large',
			fontSize: 3,
			fontWeight: 'body',
		},
		tableHeader: {
			fontFamily: 'body',
			lineHeight: '16.5px',
			fontSize: '11px',
			fontWeight: 'strong',
		},
	},

	styles: {
		root: {
			margin: '0px',
			fontFamily: 'body',
			lineHeight: 'body',
			fontWeight: 'body',
			color: 'neutral800',
			WebkitFontSmoothing: 'antialiased',
			WebkitTapHighlightColor: '#5B48CA',
		},

		a: {
			color: 'coral',
			textDecoration: 'none',
			':hover': {
				textDecoration: 'underline',
			},
		},

		img: {
			maxWidth: '100%',
			height: 'auto',
		},

		svg: {
			verticalAlign: 'middle',
		},
	},
};

theme.charts.colors = theme.charts.colors.map((c) => theme.colors[c] ?? c);
theme.charts.grid.stroke = theme.colors[theme.charts.grid.stroke] ?? theme.charts.grid.stroke;
theme.charts.axes.stroke = theme.colors[theme.charts.axes.stroke] ?? theme.charts.axes.stroke;

function resolveKeysAndValues(obj) {
	for (const key in obj) {
		if (typeof obj[key] === 'object') {
			resolveKeysAndValues(obj[key]);
		} else if (typeof obj[key] === 'function') {
			obj[key] = obj[key](theme);
		}
	}
}
resolveKeysAndValues(theme);
