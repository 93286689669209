/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, ErrorMessage, Flex, presets, Spacings, TextInput } from '@steadybit/ui-components-lib';
import { Container, Stack, Text, TextField } from 'components';
import { useField, useFormikContext } from 'formik';
import { ReactElement, useState } from 'react';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';

import DescriptionEditor from '../components/DescriptionEditor/DescriptionEditor';
import { TemplateError, TemplateFormValues } from './types';

/*
  This is pretty similar pages/experimentsV2/ExperimentTags.tsx
  but there are many small differences so we keep with 2 code bases
*/
export default function TemplateDescriptionContent(): ReactElement {
	const { values, errors, setFieldValue, setFieldTouched } = useFormikContext<TemplateFormValues>();

	const errorMessages: { [key: string]: TemplateError } = errors as { [key: string]: TemplateError };

	return (
		<Stack alignItems="center" py="large">
			<Stack width="100%" maxWidth="1200px">
				<Text variant="largeStrong" color="neutral800">
					Template Description
				</Text>

				<Block
					title="Title*"
					description="Provide a title for this experiment template. It will be shown in the search results, detailed view, and as part of the dialogue that is shown to users of this template."
				>
					<Stack size="xSmall">
						<TextField
							value={values.templateTitle}
							placeholder="Title"
							onChange={(e) => {
								setFieldValue('templateTitle', e.target.value);
								setFieldTouched('templateTitle', true);
							}}
							hasError={!!errors.templateTitle}
						/>
						{errorMessages.templateTitle && (
							<ErrorMessage type="xSmall" level={errorMessages.templateTitle.level} withIcon>
								{errorMessages.templateTitle.message}
							</ErrorMessage>
						)}
					</Stack>
				</Block>

				<Block
					title="Details*"
					description="Provide additional context for the template, e.g., its intent, when it is valuable, and how the experiment's steps relate to each other to achieve the overall goal."
				>
					<Stack size="xSmall">
						<DescriptionEditor
							value={values.templateDescription}
							hasError={!values.templateDescription}
							onChange={(templateDescription) => {
								setFieldValue('templateDescription', templateDescription);
								setFieldTouched('templateDescription', true);
							}}
						/>
						{errorMessages.templateDescription && (
							<ErrorMessage type="xSmall" level={errorMessages.templateDescription.level} withIcon>
								{errorMessages.templateDescription.message}
							</ErrorMessage>
						)}
					</Stack>
				</Block>

				<Block title="Tags" description="Assign tags to make it easier to discover your template.">
					<Tags />
				</Block>
			</Stack>
		</Stack>
	);
}

interface BlockProps {
	children: ReactElement;
	description: string;
	title: string;
}

function Block({ title, description, children }: BlockProps): ReactElement {
	return (
		<Stack
			size="xxSmall"
			style={{
				padding: '24px',
				backgroundColor: theme.colors.neutral100,
				borderRadius: '8px',
			}}
		>
			<Text variant="mediumStrong" color="neutral800">
				{title}
			</Text>
			<Text variant="small" color="neutral600">
				{description}
			</Text>
			{children}
		</Stack>
	);
}

function Tags(): ReactElement {
	const [{ value: tags }, , { setValue, setTouched }] = useField<string[]>('tags');
	const [{ value: allTags }] = useField<string[]>('allTags');

	const [queryString, setQueryString] = useState<string>('');

	return (
		<Dropdown<HTMLInputElement>
			placement="bottom-start"
			bindHeightToViewport
			renderDropdownContent={({ width }) => {
				const queryMatchesExistingTag = [...allTags, ...tags].some(
					(tag) => tag.toLowerCase() === queryString.toLowerCase(),
				);

				const selectableTags = allTags.filter((tag) => includes(tag, queryString) && !tags.includes(tag));
				if (selectableTags.length === 0 && (queryString.length === 0 || queryMatchesExistingTag)) {
					return <></>;
				}

				return (
					<presets.dropdown.DropdownContentFrame maxWidth={width}>
						<Container p="small">
							{selectableTags.length > 0 && (
								<Flex spacing="small">
									<Text variant="small" color="neutral600">
										Select an option or add a new one
									</Text>
									<Flex direction="horizontal" spacing="xSmall" wrap>
										{selectableTags.map((tag) => (
											<presets.pill.Tag
												appearance="template"
												key={tag}
												onClick={() => {
													setValue([...tags, tag]);
													setTouched(true);
													setQueryString('');
												}}
											>
												{tag}
											</presets.pill.Tag>
										))}
									</Flex>
								</Flex>
							)}
							{queryString.length > 0 && !queryMatchesExistingTag && (
								<>
									<Container sx={{ height: '1px', my: 'medium', bg: 'neutral300' }} />
									<Stack direction="horizontal" alignItems="center">
										<Text variant="small" color="neutral600">
											Create
										</Text>
										<presets.pill.Tag
											appearance="template"
											onClick={() => {
												setValue([...tags, queryString]);
												setTouched(true);
												setQueryString('');
											}}
										>
											{queryString}
										</presets.pill.Tag>
									</Stack>
								</>
							)}
						</Container>
					</presets.dropdown.DropdownContentFrame>
				);
			}}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<TextInput
						ref={setRefElement}
						placeholder="Assign an existing tag or create a new one"
						syntheticFocussed={isOpen}
						withLeftIcon="tag"
						value={queryString}
						wrap
						prevInputContent={
							<>
								{tags.map((tag) => (
									<presets.pill.Tag
										key={tag}
										appearance="template"
										onDelete={() => {
											setValue(tags.filter((t) => t !== tag));
											setTouched(true);
										}}
									>
										{tag}
									</presets.pill.Tag>
								))}
							</>
						}
						style={{ paddingTop: Spacings.xSmall, paddingBottom: Spacings.xSmall }}
						onChange={setQueryString}
						onClick={() => setOpen(true)}
					/>
				);
			}}
		</Dropdown>
	);
}
