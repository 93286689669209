/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex } from '@steadybit/ui-components-lib';
import { useLicense } from 'services/licenseApi';
import { formatDate } from 'utils/dateFns';
import { ReactElement } from 'react';
import { Text } from 'components';

interface LicenseInfoProps {
	color?: string;
}

export function LicenseInfo({ color }: LicenseInfoProps): ReactElement | null {
	const license = useLicense();

	if (license?.expires) {
		return (
			<Flex spacing="small" wrap>
				<Flex spacing="xxxSmall" wrap>
					<Text as="span" variant="small" muted fontFamily="code" color={color}>
						Type: <span style={{ textTransform: 'capitalize' }}>{license.licenseType.toLowerCase()}</span>
					</Text>

					<Text as="span" variant="small" muted fontFamily="code" color={color}>
						License expiry: {formatDate(license.expires)}
					</Text>
				</Flex>
			</Flex>
		);
	}

	return null;
}
