/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	IconCancel,
	IconCheck,
	IconWarning,
	IconWarningCircle,
	IconWarningCircleOpen,
	IconWarningOpen,
	IconExplosion,
	IconComponent,
	IconLoadTest,
	IconState,
	IconOther,
	IconSandGlass,
} from 'components/icons';
import { ActionKindVO, VariableVO, ExperimentStepRadiusVO, MissingQuerySelectionVO } from 'ui-api';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { usePromise } from 'utils/hooks/usePromise';
import { LoadingIndicator } from 'components';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { useMemo } from 'react';

export const useBlastRadiusCount = (
	blastRadius: ExperimentStepRadiusVO,
	environmentId: string,
	experimentVariables: VariableVO[],
	missingQuerySelection: MissingQuerySelectionVO,
	dependencies: unknown[] = [],
): { total?: number; impacted?: number; loading: boolean } => {
	const predicateString = toPredicateString(blastRadius.predicate);
	const targetType = blastRadius.targetType;

	const totalTargetCountForBlastRadius = usePromise(
		() =>
			Services.targets.countTargetsForBlastRadius(
				environmentId,
				missingQuerySelection,
				blastRadius,
				experimentVariables,
			),
		[environmentId, predicateString, targetType, createStableId(experimentVariables), ...dependencies],
	);

	const countImpacted = useMemo(() => {
		if (totalTargetCountForBlastRadius.value === 0) {
			return 0;
		}
		if (Number.isInteger(blastRadius.percentage)) {
			return Math.round(((blastRadius.percentage ?? 0) / 100) * (totalTargetCountForBlastRadius.value || 0));
		}
		if (Number.isInteger(blastRadius.maximum)) {
			return blastRadius.maximum;
		}
		return totalTargetCountForBlastRadius.value;
	}, [blastRadius.percentage, blastRadius.maximum, totalTargetCountForBlastRadius.value]);

	return {
		total: totalTargetCountForBlastRadius.value,
		impacted: countImpacted,
		loading: totalTargetCountForBlastRadius.loading,
	};
};

type Colors = {
	backgroundColor: string;
	color: string;
};

export const getColors = (kind: ActionKindVO): Colors => {
	switch (kind) {
		case 'ATTACK':
			return {
				backgroundColor: theme.colors.experimentAttack,
				color: theme.colors.neutral000,
			};
		case 'CHECK':
			return {
				backgroundColor: theme.colors.experimentCheck,
				color: theme.colors.neutral000,
			};
		case 'LOAD_TEST':
			return {
				backgroundColor: theme.colors.experimentLoadTest,
				color: theme.colors.purple900,
			};
		case 'OTHER':
			return {
				backgroundColor: theme.colors.experimentOther,
				color: theme.colors.neutral000,
			};
		case 'BASIC':
			return {
				backgroundColor: theme.colors.experimentWait,
				color: theme.colors.neutral000,
			};
	}
};

export const getLabel = (kind: ActionKindVO): string => {
	switch (kind) {
		case 'ATTACK':
			return 'Attack';
		case 'CHECK':
			return 'Check';
		case 'LOAD_TEST':
			return 'Loadtest';
		case 'OTHER':
			return 'Other';
		case 'BASIC':
			return 'Basic';
	}
};

export const getIcon = (kind: ActionKindVO): IconComponent => {
	switch (kind) {
		case 'CHECK':
			return IconState;
		case 'LOAD_TEST':
			return IconLoadTest;
		case 'OTHER':
			return IconOther;
		case 'BASIC':
			return IconSandGlass;
	}
	return IconExplosion;
};

export function getBGColorForState(state: string): string {
	switch (state) {
		case 'CANCELED':
			return theme.colors.neutral150;
		case 'FAILED':
			return '#FEF5D9';
		case 'ERRORED':
			return theme.colors.coral100;
		case 'RUNNING':
		case 'PREPARED':
		case 'CREATED':
			return theme.colors.purple100;
		default:
			return theme.colors.success050;
	}
}

export function getColorForState(state: string, fallback: string = theme.colors.successDark): string {
	switch (state) {
		case 'CANCELED':
			return theme.colors.neutral600;
		case 'FAILED':
			return theme.colors.experimentWarning;
		case 'ERRORED':
			return theme.colors.coral;
		case 'RUNNING':
		case 'PREPARED':
		case 'CREATED':
			return theme.colors.purple700;
		case 'SKIPPED':
			return theme.colors.neutral400;
		default:
			return fallback;
	}
}

export function getDarkerColorForState(state: string): string {
	switch (state) {
		case 'FAILED':
			return '#B54708';
		case 'ERRORED':
			return theme.colors.coral700;
		default:
			return getColorForState(state);
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getIconForState(state: string, light = false) {
	switch (state) {
		case 'CANCELED':
		case 'SKIPPED':
			return IconCancel;
		case 'FAILED':
			return light ? IconWarningOpen : IconWarning;
		case 'ERRORED':
			return light ? IconWarningCircleOpen : IconWarningCircle;
		case 'CREATED':
		case 'PREPARED':
		case 'RUNNING':
			return LoadingIndicator;
		default:
			return IconCheck;
	}
}

export function isErrored(state: string): boolean {
	return state === 'ERRORED';
}

export function isCanceled(state: string): boolean {
	return state === 'CANCELED';
}

export function isFailed(state: string): boolean {
	return state === 'FAILED';
}

export function isRunning(state: string): boolean {
	return state === 'RUNNING';
}

export function isNotEnded(state: string): boolean {
	return isRunning(state) || isPrepared(state) || isCreated(state);
}

export function isPrepared(state: string): boolean {
	return state === 'PREPARED';
}

export function isCreated(state: string): boolean {
	return state === 'CREATED';
}

export function isSkipped(state: string): boolean {
	return state === 'SKIPPED';
}

export function isCompleted(state: string): boolean {
	return state === 'COMPLETED';
}

export function isSucceeded(state: string): boolean {
	return !isErrored(state) && !isCanceled(state) && !isFailed(state) && !isRunning(state);
}

export function mapExperimentExecutionState(state: string): 'failed' | 'aborted' | 'succeeded' | 'errored' | undefined {
	if (isFailed(state)) {
		return 'failed';
	} else if (isCanceled(state)) {
		return 'aborted';
	} else if (isCompleted(state)) {
		return 'succeeded';
	} else if (isErrored(state)) {
		return 'errored';
	}
	return undefined;
}

function createStableId(variables: VariableVO[]): string {
	return variables.map((v) => `${v.key}👾${v.value}`).join(',');
}
