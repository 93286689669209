/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconBadge,
	IconCalendarClock,
	IconCalendarEdit,
	IconDelete,
	IconDuplicate,
	IconLogfile,
	IconTemplate,
} from 'components/icons';
import {
	duplicateExperimentConfirm,
	DuplicateExperimentResult,
} from 'pages/experiments/components/DuplicateExperimentConfirm';
import { ExperimentSubHeaderEnvironments } from 'pages/experiments/components/header/experimentSubHeaderEnvironments';
import ExperimentSubHeaderHypothesis from 'pages/experiments/components/header/ExperimentSubHeaderHypothesisDropdown';
import EditExperimentScheduleModal from 'pages/experiments/components/Schedule/EditExperimentScheduleModal';
import ScheduleExperimentModal from 'pages/experiments/components/Schedule/ScheduleExperimentModal';
import { Button, Dropdown, Flex, IconButton, presets, Text } from '@steadybit/ui-components-lib';
import { ContextualMenuButton, Snackbar, Stack, Subnav, Tooltip, userConfirm } from 'components';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import ExperimentFileModal from 'pages/experiments/components/header/ExperimentFileModal';
import useRefreshingSchedule from 'pages/experiments/hooks/useRefreshingSchedule';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { prepareCopyExperimentForm } from 'pages/experiments/experiment';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { useState } from 'react';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { useLicenseFeature } from '../../services/licenseApi';
import { ExperimentFormValues } from './types';
import ExperimentTags from './ExperimentTags';

export default function ExperimentSubHeader(): ReactElement {
	const { values, touched, submitForm, setValues, setTouched, isSubmitting } = useFormikContext<ExperimentFormValues>();

	const { experimentKey, name, actions } = values;

	const isNewExperiment = !experimentKey || experimentKey === '<new>';

	const isEditPermitted = actions.includes('save');
	const disabled = !isEditPermitted;

	const history = useHistory();
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showBadgeModal, setShowBadgeModal] = useState(false);

	const templatesEnabled = useLicenseFeature('TEMPLATES');
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const schedule = useRefreshingSchedule(experimentKey);

	const dirty = Object.keys(touched).length > 0;
	const canSaveAsTemplate = canCreateTemplates && isEditPermitted && !isNewExperiment;
	const canSaveExperiment = canSaveAsTemplate || (isEditPermitted && (isNewExperiment || dirty));

	return (
		<Subnav
			px="medium"
			maxWidth="100%"
			sx={{ boxShadow: 'applicationSmall', zIndex: 3 }}
			stackProps={{ justifyContent: 'space-between' }}
		>
			{showDownloadModal && (
				<ExperimentFileModal
					title={`experiment-${experimentKey}`}
					experimentKey={experimentKey}
					onClose={() => setShowDownloadModal(false)}
				/>
			)}
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={experimentKey} onClose={() => setShowBadgeModal(false)} />
			)}
			{showScheduleModal &&
				(schedule.value ? (
					<EditExperimentScheduleModal
						schedule={schedule.value}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				) : (
					<ScheduleExperimentModal
						experimentKey={experimentKey}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				))}

			<Stack
				justifyContent="flex-start"
				direction="horizontal"
				minWidth="fit-content"
				alignItems="center"
				overflowX="hidden"
				py="2px"
				flexGrow={1}
			>
				<ExperimentSubHeaderEnvironments disabled={disabled} />
				<Divider />
				<ExperimentSubHeaderHypothesis disabled={disabled} />
				<Divider />
				<ExperimentTags />
			</Stack>

			<Dropdown<HTMLButtonElement>
				placement="bottom-end"
				renderDropdownContent={({ close }) => {
					const buttonStyle = { minWidth: 20, minHeight: 20 };
					return (
						<presets.dropdown.DropdownContentFrame style={{ p: 'xxSmall' }}>
							<Stack size="none">
								{!isNewExperiment && (
									<>
										<ContextualMenuButton
											onClick={() => {
												onDuplicate();
												close();
											}}
										>
											<IconDuplicate sx={buttonStyle} />
											Duplicate
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												onDownload();
												close();
											}}
										>
											<IconLogfile sx={buttonStyle} />
											View as JSON
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												onBadge();
												close();
											}}
										>
											<IconBadge sx={buttonStyle} />
											Create badge
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												onSchedule();
												close();
											}}
										>
											{schedule.value ? (
												<>
													<IconCalendarEdit />
													Edit Schedule
												</>
											) : (
												<>
													<IconCalendarClock sx={buttonStyle} />
													Schedule run
												</>
											)}
										</ContextualMenuButton>
									</>
								)}

								{(actions.includes('delete') || isNewExperiment) && (
									<ContextualMenuButton
										onClick={async () => {
											if (
												(await userConfirm({
													title: isNewExperiment ? 'Discard new Experiment' : 'Delete Experiment',
													message: isNewExperiment
														? `Do you really want to discard your new Experiment ${name ?? ''}?`
														: `Do you really want to delete ${experimentKey} ${name ?? ''}?`,
													actions: [
														{ value: 'cancel', label: 'Cancel' },
														{
															value: 'confirm',
															label: isNewExperiment ? 'Discard' : `Delete ${experimentKey}`,
															variant: 'primary',
														},
													],
												})) === 'confirm'
											) {
												if (isNewExperiment) {
													history.replace('/experiments', 'redirect_after_delete');
												} else {
													try {
														await Services.experiments.deleteExperiment(experimentKey ?? '', 'UI_EDITOR');
														Snackbar.dark('Experiment deleted.', { toastId: 'experiment-deleted' });
														history.replace('/experiments', 'redirect_after_delete');
													} catch (err) {
														Snackbar.error('Experiment not deleted: ' + err.toString(), {
															toastId: 'experiment-deleted',
														});
													}
												}
											}
										}}
									>
										<IconDelete sx={buttonStyle} />
										{isNewExperiment ? 'Discard' : 'Delete '}
									</ContextualMenuButton>
								)}
							</Stack>
						</presets.dropdown.DropdownContentFrame>
					);
				}}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<IconButton
							ref={setRefElement}
							type="chromelessWithBorderOnHover"
							size="small"
							icon="dots"
							onClick={() => setOpen(!isOpen)}
						/>
					);
				}}
			</Dropdown>

			<Stack direction="horizontal" justifyContent="flex-end" alignItems="center" overflowX="hidden">
				{canSaveAsTemplate ? (
					<Flex direction="horizontal">
						<Button
							type="secondary"
							size="small"
							disabled={isSubmitting}
							data-cy="save-experiment-button"
							style={{
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
							onClick={submitForm}
						>
							Save
						</Button>

						<Dropdown<HTMLButtonElement>
							placement="bottom-end"
							renderDropdownContent={() => (
								<presets.dropdown.DropdownContentFrame style={{ p: 'xxSmall' }}>
									<Tooltip content={templatesEnabled ? undefined : <LicenseTooltipContent />}>
										<ContextualMenuButton
											onClick={() => history.push(`/settings/templates/design/fromExperiment/${values.experimentKey}`)}
											disabled={!templatesEnabled}
										>
											<Flex direction="horizontal" align="center" spacing="xxSmall" style={{ pointerEvents: 'none' }}>
												<IconTemplate
													variant="large"
													style={{
														minWidth: 24,
														minHeight: 24,
													}}
												/>
												<Text type="smallStrong">Save as Template</Text>
											</Flex>
										</ContextualMenuButton>
									</Tooltip>
								</presets.dropdown.DropdownContentFrame>
							)}
						>
							{({ setRefElement, isOpen, setOpen }) => {
								return (
									<IconButton
										ref={setRefElement}
										type="secondary"
										size="small"
										icon={isOpen ? 'triangle-up' : 'triangle-down'}
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
										}}
										onClick={() => setOpen(!isOpen)}
									/>
								);
							}}
						</Dropdown>
					</Flex>
				) : canSaveExperiment ? (
					<Button
						type="secondary"
						size="small"
						disabled={isSubmitting}
						data-cy="save-experiment-button"
						onClick={submitForm}
					>
						Save
					</Button>
				) : null}
			</Stack>
		</Subnav>
	);

	async function ensureSaved({ saveLabel }: { saveLabel: string }): Promise<boolean> {
		if (dirty || isNewExperiment) {
			if (
				(await userConfirm({
					title: 'Unsaved Changes',
					message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{
							value: 'save&run',
							label: saveLabel,
							variant: 'primary',
						},
					],
				})) === 'save&run'
			) {
				const saveResult = await saveExperiment(values);
				if (saveResult == null) {
					return false;
				}
				const [, saved] = saveResult;
				if (saved === null) {
					return false;
				}

				const experimentKey = saved.experimentKey;
				const experiment = await Services.experiments.fetchExperiment(experimentKey);

				setValues({ ...values, version: experiment.version });
				setTouched({});

				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	async function onDuplicate(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Duplicate' });
		if (executeAction) {
			const original = await Services.experiments.fetchExperiment(experimentKey);
			const duplicateResult: DuplicateExperimentResult = await duplicateExperimentConfirm(original);
			if (duplicateResult.result === 'cancel') {
				return;
			}

			const copyedExperimentFormData = await prepareCopyExperimentForm(
				experimentKey,
				duplicateResult,
				'UI_COPIED_EDITOR',
			);
			const saved = await Services.experiments.createOrUpdateExperiment(copyedExperimentFormData.initialValues);

			history.push(`/experiments/edit/${saved.experimentKey}/design`);
		}
	}

	async function onDownload(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Download' });
		if (executeAction) {
			setShowDownloadModal(true);
		}
	}

	async function onBadge(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Create Badge' });
		if (executeAction) {
			setShowBadgeModal(true);
		}
	}

	async function onSchedule(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Schedule' });
		if (executeAction) {
			setShowScheduleModal(true);
		}
	}
}

function Divider(): ReactElement {
	return <div style={{ width: 1, height: 20, backgroundColor: theme.colors.neutral300 }} />;
}
