/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	VariableVO,
	GetTargetsPageResponse,
	TargetPredicateVO,
	TargetTypeDescriptionVO,
	TargetVO,
} from 'ui-api';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import debounce from 'debounce-promise';

import { TargetId } from './util';

interface FetchTargetsOptions {
	targetDefinition: TargetTypeDescriptionVO;
	experimentVariables?: VariableVO[];
	additionalAttributes?: string[];
	predicate?: TargetPredicateVO;
	environmentId?: string;
	page: PageParams;
	query: string;
}

export const debouncedFetchTargets = debounce(fetchTargets, 350, { leading: true });
function fetchTargets(opts: FetchTargetsOptions): Promise<GetTargetsPageResponse> {
	const allAttributeKeys = opts.targetDefinition.table.columns.reduce((agg, column) => {
		agg.push(column.attribute, ...(column.fallbackAttributes ?? []));
		return agg;
	}, [] as string[]);
	if (opts.additionalAttributes) {
		allAttributeKeys.push(...opts.additionalAttributes);
	}

	return Services.targets.fetchTargets(
		opts.targetDefinition.id,
		opts.query,
		allAttributeKeys,
		opts.environmentId,
		opts.predicate,
		opts.page,
		opts.experimentVariables,
	);
}

export async function fetchTarget(targetId: TargetId, anyUsage?: boolean): Promise<TargetVO[]> {
	const targetPredicates: CompositeTargetPredicateVO = {
		operator: 'AND',
		predicates: [{ name: targetId.name }, { type: targetId.type }],
	};
	const response = await Services.targets.fetchTarget(targetPredicates, anyUsage);
	return response.content;
}
