/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { presets } from '@steadybit/ui-components-lib';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';

interface UserSelectProps {
	value: string | null;
	onChange: (v: string | null) => void;
}

export default function UserSelect({ value, onChange }: UserSelectProps): ReactElement {
	const [optionsResult] = useAsyncState(async () => {
		const users = (await Services.users.fetchUsers(new PageParams(0, 1000))).content;
		return users.map((u) => ({ label: u.name ?? u.username, id: u.username }));
	});
	const options = [{ label: 'Any', id: 'ANY' }, ...(optionsResult.value ?? [])];
	const selected = options.find((o) => o.id === value) ?? options[0];

	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={value || 'ANY'}
			items={options}
			size="small"
			onSelect={(id) => onChange?.(id === 'ANY' ? null : id)}
			maxContentHeight="400px"
			style={{ width: '100%' }}
		>
			{selected?.label || 'Any'}
		</presets.dropdown.SingleChoiceButton>
	);
}
