/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ColumnVO,
	ExperimentStepExecutionActionTargetVO,
	TargetAttributeDescriptionVO,
	TargetTypeDescriptionVO,
	TargetTypeSummaryVO,
	TargetVO,
} from 'ui-api';
import { createIconFromDataUri, IconComponent, IconTarget } from 'components/icons';
import { localeCompareIgnoreCase } from 'utils/string';
import { Direction, Order } from 'utils/hooks/usePage';
import memoizeOne from 'memoize-one';
import { sortedUniq } from 'lodash';

import { getLabel } from '../i18n/label';

type AttributeValues = string[];

interface Attribute {
	key: string;
	value: string;
}

/**
 * Returns a human-readable label for a target. Will attempt to leverage attribute labels
 * of the target, but can support fallback to some generated labels if the default labels
 * are unavailable.
 */
export function getTargetLabel(target: TargetVO, targetDefinition?: TargetTypeDescriptionVO): string {
	const label = getAttributeValues(target.attributes, 'steadybit.label');
	if (label.length > 0) {
		return joinAttributeValues(label);
	}

	if (targetDefinition) {
		const label = getAttributeKeyValue(targetDefinition.table.columns[0], target.attributes)?.value;
		if (label) {
			return label;
		}
	}

	return target.name;
}

/**
 * We frequently show targets within list-like structures. In these cases
 * we need to generate key props for React. This functions helps to generate
 * a correct key.
 */
export function getKey(target: TargetVO): string {
	return `${target.agentId}_${target.type}_${target.name}`;
}

const getAttributeLabel = (
	attribute: string,
	attributeDefinitions: TargetAttributeDescriptionVO[],
): string | undefined => {
	const attributeLabel = attributeDefinitions.find((attr) => attr.attribute === attribute)?.label;
	return attributeLabel ? getLabel(attributeLabel, 1) : undefined;
};

interface AttributeHeader {
	label: string;
	tooltip?: string;
}

export const getAttributeHeader = (
	column: ColumnVO,
	attributeDefinitions: TargetAttributeDescriptionVO[],
): AttributeHeader => {
	const attributeHeader = getAttributeLabel(column.attribute, attributeDefinitions);
	const fallbackHeaders = column.fallbackAttributes?.map((attr) => getAttributeLabel(attr, attributeDefinitions)) ?? [];
	const headers = [attributeHeader, ...fallbackHeaders].filter((header) => !!header);

	if (headers.length > 0) {
		const label = headers
			.filter((header, i) => headers.findIndex((h) => h?.toLowerCase() === header?.toLowerCase()) >= i)
			.join(' / ');
		const tooltip = column.fallbackAttributes
			? [column.attribute, ...column.fallbackAttributes].join(' / ')
			: column.attribute;
		return { label, tooltip };
	} else if (column.fallbackAttributes) {
		return { label: [column.attribute, ...column.fallbackAttributes].join(' / ') };
	} else {
		return { label: column.attribute };
	}
};

export function getAttributeKeyValue(
	column: ColumnVO,
	attributes: Attribute[],
): { value: string; key: string } | undefined {
	const values = getAttributeValues(attributes, column.attribute);
	if (values.length > 0) {
		return { value: joinAttributeValues(values), key: column.attribute };
	}

	for (const attributeKey of column.fallbackAttributes ?? []) {
		const values = getAttributeValues(attributes, attributeKey);
		if (values.length > 0) {
			return { value: joinAttributeValues(values), key: attributeKey };
		}
	}

	return undefined;
}

function getAttributeValues(attributes: Attribute[], attributeKey: string): AttributeValues {
	return sortedUniq(
		attributes
			.filter((attr) => attr.key === attributeKey)
			.map((attr) => attr.value)
			.sort(localeCompareIgnoreCase),
	);
}

function joinAttributeValues(values: AttributeValues): string {
	return values.join(', ');
}

export function toTarget(vo: ExperimentStepExecutionActionTargetVO): TargetVO | undefined {
	if (!vo.targetType || !vo.targetName || !vo.targetAttributes) {
		return undefined;
	}
	return {
		agentId: vo.agentId,
		type: vo.targetType,
		name: vo.targetName,
		attributes: vo.targetAttributes,
	};
}

export interface TargetId {
	type: string;
	name: string;
}

/**
 * Useful to only get those parts of the target that make up its unique identifier.
 * For example, you might only want to persist the unique identifier in a URL instead
 * of the whole target with all its attributes.
 */
export function toTargetId(target: TargetVO): TargetId {
	return {
		type: target.type,
		name: target.name,
	};
}

export function getCategory(targetDefinition: TargetTypeDescriptionVO): string {
	return targetDefinition.category ?? 'uncategorized';
}

/**
 * Helpful to strip the category label from the target definition label. You might find this useful
 * in list-presentation use-cases. For example:
 *
 * Kubernetes
 * - Kubernetes Deployments
 * - Kubernetes Namespaces
 *
 * Will then be
 *
 * Kubernetes
 * - Deployments
 * - Namespaces
 */
export function removeCategoryFromTargetDefinitionLabel(label: string, category: string): string {
	if (label.toLowerCase().startsWith(category.toLowerCase())) {
		return label.substring(category.length).trim();
	}

	return label;
}

/**
 * Returns the target's icon or our fallback one. Will also turn the icon into an IconComponent
 * if necessary.
 */
export function getIcon(targetDefinition: TargetTypeDescriptionVO | TargetTypeSummaryVO): IconComponent {
	if (targetDefinition.icon) {
		return createIconFromDataUri(targetDefinition.icon);
	}
	return IconTarget;
}

export const getInitialSort = memoizeOne((targetDefinition: TargetTypeDescriptionVO) =>
	targetDefinition.table.orderBy.map(
		(by) => [`attributes[${by.attribute}]`, by.direction.toLowerCase() as Direction] as Order,
	),
);

export function emptyTargetDefinition(targetType: string): TargetTypeDescriptionVO {
	return {
		id: targetType,
		version: '0.0.0',
		label: { one: targetType, other: targetType },
		table: { columns: [{ attribute: '' }], orderBy: [] },
	};
}
