/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { Pill, TableDataCell } from 'components';
import { ReactElement, useState } from 'react';
import { TeamVO } from 'ui-api';

interface EnvironmentsProps {
	reduced: boolean;
	team: TeamVO;
}

export default function Environments({ team, reduced }: EnvironmentsProps): ReactElement {
	const [showAll, setShowAll] = useState(false);
	const envs = team.allowedEnvironmentNames;
	return (
		<TableDataCell sx={{ flexWrap: 'wrap' }}>
			{reduced ? (
				<Pill color="neutral800" backgroundColor="neutral200">
					{envs.length}
				</Pill>
			) : (
				<Flex direction="horizontal" wrap spacing="xxSmall">
					{envs.slice(0, showAll ? envs.length : 9).map((name) => (
						<presets.pill.Tag key={name} small>
							{name}
						</presets.pill.Tag>
					))}
					{envs.length > 9 && !showAll && (
						<presets.pill.Tag small onClick={() => setShowAll(true)}>
							{envs.length - 9 > 99 ? '99+' : `+${envs.length - 9}`}
						</presets.pill.Tag>
					)}
				</Flex>
			)}
		</TableDataCell>
	);
}
