/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Dropdown, Pill, presets, TextArea } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';

import { ExperimentFormValues } from '../../experiment';

export default function ExperimentSubHeaderHypothesis({ disabled }: { disabled: boolean }): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue, setFieldTouched } = formik;
	const { hypothesis } = formik.values;

	const [localHypothesis, setLocalHypothesis] = useState<string>(hypothesis);

	const [, meta] = useField('hypothesis');
	const hasError = Boolean(meta.error);

	return (
		<Dropdown<HTMLDivElement>
			placement="bottom-start"
			renderDropdownContent={({ close }) => (
				<presets.dropdown.DropdownContentFrame
					maxHeight="500px"
					style={{ padding: '1px', borderRadius: 'xxSmall', border: 'none' }}
				>
					<TextArea
						placeholder="No description"
						value={localHypothesis}
						maxLength={20_000}
						autoFocus
						onChange={setLocalHypothesis}
						style={{
							minWidth: '400px',
							minHeight: '150px',
						}}
						onKeyDown={(e) => {
							if (e.key === 'Escape' || e.key === 'Esc') {
								close();
							}
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
			onOpen={() => setLocalHypothesis(hypothesis)}
			onClose={() => {
				setFieldValue('hypothesis', localHypothesis, false);
				setFieldTouched('hypothesis', true);
			}}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Pill
						ref={setRefElement}
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						type={hasError ? 'error' : 'default'}
						withLeftIcon="description"
						style={{ maxWidth: '400px' }}
						onClick={disabled ? undefined : () => setOpen(!isOpen)}
					>
						{hypothesis || 'No Hypothesis'}
					</Pill>
				);
			}}
		</Dropdown>
	);
}
