/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useAdviceList from 'targets/useAdviceList';
import { LoadingIndicator } from 'components';
import { useUrlState } from 'url/useUrlState';
import { TargetPredicateVO } from 'ui-api';
import { ReactElement } from 'react';

import { UrlState, selectedAdviceIdParam, selectedTargetIdParam } from './urlParams';
import AdviceContent, { NotFound } from './AdviceDetails/AdviceContent';
import AdviceList, { LoadingList } from './AdviceList/AdviceList';
import NoAdviceContent from './NoAdviceContent';

interface AdviceListProps {
	predicate: TargetPredicateVO;
	environmentId: string;
}

export default function Advice({ environmentId, predicate }: AdviceListProps): ReactElement {
	const [{ selectedAdviceId }] = useUrlState<UrlState>([selectedAdviceIdParam, selectedTargetIdParam]);
	const adviceListResult = useAdviceList(predicate);
	const allAdviceListResult = useAdviceList({ predicates: [], operator: 'AND' });

	const isLoading = !adviceListResult.value || !allAdviceListResult.value;
	if (isLoading) {
		return <LoadingContent />;
	}

	const adviceList = adviceListResult.value;
	if (adviceList.length === 0) {
		return <NoAdviceAvailable />;
	}

	const adviceNotFound = selectedAdviceId ? !adviceList.find((advice) => advice.type === selectedAdviceId) : false;

	const adviceIsAvailableInGeneral = selectedAdviceId
		? !!allAdviceListResult.value.find((advice) => advice.type === selectedAdviceId)
		: false;

	return (
		<main
			style={{
				display: 'grid',
				gridTemplateColumns: '424px 1fr',

				gridArea: 'main',

				height: '100%',
				width: '100%',

				overflow: 'hidden',
			}}
		>
			<AdviceList selectedAdviceId={selectedAdviceId} adviceList={adviceList} />
			{adviceNotFound ? (
				<NotFound adviceIsAvailableInGeneral={adviceIsAvailableInGeneral} />
			) : (
				<AdviceContent predicate={predicate} environmentId={environmentId} />
			)}
		</main>
	);
}

function LoadingContent(): ReactElement {
	return (
		<main
			style={{
				display: 'grid',
				gridTemplateColumns: '424px 1fr',

				gridArea: 'main',

				height: '100%',
				width: '100%',
			}}
		>
			<LoadingList />
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<LoadingIndicator variant="xxLarge" color="slate" />
			</div>
		</main>
	);
}

function NoAdviceAvailable(): ReactElement {
	return (
		<main
			style={{
				gridArea: 'main',

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: '100%',
				height: 'calc(100% - 300px)',
			}}
		>
			<NoAdviceContent />
		</main>
	);
}
