/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ExperimentSummaryVO, SteadybitEventVO, TeamVO } from 'ui-api';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable } from 'rxjs';
import cached from 'utils/cached';

import { EnvironmentsApi } from './environmentsApi';
import { ExperimentApi } from './experimentApi';
import { TemplatesApi } from './templatesApi';
import { EventsApi } from './eventsApi';
import { AgentsApi } from './agentsApi';
import { TeamsApi } from './teamsApi';
import { HubsApi } from './hubsApi';

export interface Permissions {
	agents: AgentsPermissions;
	environments: EnvironmentsPermissions;
	experiments: ExperimentsPermissions;
	templates: TemplatesPermissions;
	teams: TeamsPermissions;
	hubs: HubsPermissions;
}

interface AgentsPermissions {
	canChangeLogLevel: boolean;
	canReadLog: boolean;
	canRetriggerTargetDiscovery: boolean;
	canRegister: boolean;
}

interface EnvironmentsPermissions {
	canCreate: boolean;
}

interface ExperimentsPermissions {
	canCreate: boolean;
}

interface TemplatesPermissions {
	canCreate: boolean;
	canUse: boolean;
}

interface HubsPermissions {
	canCreate: boolean;
}

interface TeamsPermissions {
	canCreate: boolean;
	canAddMember: boolean;
}

interface TeamPermissions {
	canManageMembers: boolean;
	canDelete: boolean;
	canEdit: boolean;
}

interface ExperimentPermissions {
	canDeleteSchedule: boolean;
	canRunByLicense: boolean;
	canRunByTeam: boolean;
	canSchedule: boolean;
	canDelete: boolean;
	canEdit: boolean;
}

export class PermissionsApi {
	getAgentsPermissions = cached(this.getAgentsPermissionsInternal.bind(this));
	getEnvironmentsPermissions = cached(this.getEnvironmentsPermissionsInternal.bind(this));
	getExperimentsPermissions = cached(this.getExperimentsPermissionsInternal.bind(this));
	getTemplatesPermissions = cached(this.getTemplatesPermissionsInternal.bind(this));
	getTeamsPermissions = cached(this.getTeamsPermissionsInternal.bind(this));
	getHubsPermissions = cached(this.getHubsPermissionsInternal.bind(this));
	events$: BehaviorSubject<SteadybitEventVO | null>;
	agentsApi: AgentsApi;
	environmentsApi: EnvironmentsApi;
	experimentsApi: ExperimentApi;
	templatesApi: TemplatesApi;
	teamApi: TeamsApi;
	hubApi: HubsApi;

	constructor(
		events: EventsApi,
		agentsApi: AgentsApi,
		environmentsApi: EnvironmentsApi,
		experimentApi: ExperimentApi,
		templatesApi: TemplatesApi,
		teamApi: TeamsApi,
		hubApi: HubsApi,
	) {
		this.agentsApi = agentsApi;
		this.environmentsApi = environmentsApi;
		this.experimentsApi = experimentApi;
		this.templatesApi = templatesApi;
		this.teamApi = teamApi;
		this.hubApi = hubApi;
		this.events$ = new BehaviorSubject<SteadybitEventVO | null>(null);

		events.events$
			.pipe(
				map((event) => {
					if (['team.updated', 'team.deleted', 'team.created', 'license.updated'].includes(event.type)) {
						this.getAgentsPermissions.invalidateCaches();
						this.getEnvironmentsPermissions.invalidateCaches();
						this.getExperimentsPermissions.invalidateCaches();
						this.getTemplatesPermissions.invalidateCaches();
						this.getTeamsPermissions.invalidateCaches();
						this.getHubsPermissions.invalidateCaches();
						return true;
					}
					return false;
				}),
			)
			.pipe(filter(Boolean))
			.pipe(debounceTime(200))
			.subscribe(() => {
				this.events$.next(null);
			});
	}

	getGlobalPermissions$(teamId: string): Observable<Permissions> {
		return this.events$.pipe(switchMap(() => from(this.getGlobalPermissions(teamId))));
	}

	getExperimentPermissions(experiment: ExperimentSummaryVO): ExperimentPermissions {
		return {
			canDeleteSchedule: experiment._actions.includes('delete-schedule'),
			canRunByLicense: experiment._actions.includes('run-by-license'),
			canRunByTeam: experiment._actions.includes('run-by-team'),
			canSchedule: experiment._actions.includes('schedule'),
			canDelete: experiment._actions.includes('delete'),
			canEdit: experiment._actions.includes('edit'),
		};
	}

	getTeamPermissions(team: TeamVO): TeamPermissions {
		return {
			canManageMembers: team._actions.includes('manage-members'),
			canDelete: team._actions.includes('delete'),
			canEdit: team._actions.includes('edit'),
		};
	}

	private async getGlobalPermissions(teamId: string): Promise<Permissions> {
		const agents = await this.getAgentsPermissions();
		const environments = await this.getEnvironmentsPermissions();
		const experiments = await this.getExperimentsPermissions(teamId);
		const templates = await this.getTemplatesPermissions(teamId);
		const teams = await this.getTeamsPermissions(teamId);
		const hubs = await this.getHubsPermissions(teamId);

		return {
			agents,
			environments,
			experiments,
			templates,
			teams,
			hubs,
		};
	}

	private async getAgentsPermissionsInternal(): Promise<AgentsPermissions> {
		try {
			const actions = await this.agentsApi.getPermissions();
			return {
				canChangeLogLevel: actions.includes('changeLogLevel'),
				canReadLog: actions.includes('readLog'),
				canRetriggerTargetDiscovery: actions.includes('retriggerTargetDiscovery'),
				canRegister: actions.includes('register'),
			};
		} catch {
			return this.defaultPermissions.agents;
		}
	}

	private async getEnvironmentsPermissionsInternal(): Promise<EnvironmentsPermissions> {
		try {
			const actions = await this.environmentsApi.getPermissions();
			return {
				canCreate: actions.includes('create'),
			};
		} catch {
			return this.defaultPermissions.environments;
		}
	}

	private async getExperimentsPermissionsInternal(teamId: string): Promise<ExperimentsPermissions> {
		try {
			const actions = await this.experimentsApi.getPermissions(teamId);
			return {
				canCreate: actions.includes('create'),
			};
		} catch {
			return this.defaultPermissions.experiments;
		}
	}

	private async getTemplatesPermissionsInternal(teamId: string): Promise<TemplatesPermissions> {
		try {
			const actions = await this.templatesApi.getPermissions(teamId);
			return {
				canCreate: actions.includes('create'),
				canUse: actions.includes('use'),
			};
		} catch {
			return this.defaultPermissions.templates;
		}
	}

	private async getHubsPermissionsInternal(): Promise<HubsPermissions> {
		try {
			const actions = await this.hubApi.getPermissions();
			return {
				canCreate: actions.includes('create'),
			};
		} catch {
			return this.defaultPermissions.hubs;
		}
	}

	private async getTeamsPermissionsInternal(): Promise<TeamsPermissions> {
		try {
			const actions = await this.teamApi.getPermissions();
			return {
				canCreate: actions.includes('create'),
				canAddMember: actions.includes('addMember'),
			};
		} catch {
			return this.defaultPermissions.teams;
		}
	}

	defaultPermissions: Permissions = {
		agents: {
			canChangeLogLevel: false,
			canReadLog: false,
			canRetriggerTargetDiscovery: false,
			canRegister: false,
		},
		environments: {
			canCreate: false,
		},
		experiments: {
			canCreate: false,
		},
		templates: {
			canCreate: false,
			canUse: false,
		},
		hubs: {
			canCreate: false,
		},
		teams: {
			canCreate: false,
			canAddMember: false,
		},
	};
}
