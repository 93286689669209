/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalV2,
	Snackbar,
	Spinner,
	Stack,
	Text,
} from 'components';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Form, Formik, FormikHelpers } from 'formik';
import { WebhookTypes } from 'services/webhooksApi';
import { ValidationError } from 'utils/error';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { isUserAdmin } from 'utils/user';
import { ensure } from 'utils/ensure';
import { WebhookVO } from 'ui-api';
import React from 'react';

import { WebhookForm, WebhookFormValues } from './components/webhookForm';

const EditWebhook: React.VFC<{ id: string; onClose?: () => void; labelTitle?: (webhook?: WebhookVO) => string }> = ({
	id,
	onClose,
	labelTitle,
}) => {
	const [submitError, setSubmitError] = React.useState<Error | null>();
	const [webhook] = useAsyncState(() => Services.webhooks.fetchWebhook(id), [id]);

	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const handleSubmit = async (values: WebhookFormValues, form: FormikHelpers<WebhookFormValues>): Promise<void> => {
		try {
			setSubmitError(null);
			await Services.webhooks.updateWebhook(id, {
				...values,
				version: ensure(webhook.value).version,
				type: ensure(webhook.value).type,
			});
			Snackbar.dark(`'${values.name}' saved.`, { toastId: 'webhook-saved' });
			onClose?.();
		} catch (error) {
			if (error instanceof ValidationError) {
				error.violations.forEach((violation) => form.setFieldError(violation.field, violation.message));
			} else {
				setSubmitError(error);
			}
		}
	};

	const toWebhookFormValue = (webhook: WebhookVO): WebhookFormValues => {
		const formValues: WebhookFormValues = {
			...webhook,
			team: webhook.teamId,
		};
		const selectedType = WebhookTypes.find((w) => w.type === webhook.type);
		selectedType?.parameters.forEach((p) => {
			// @ts-expect-error - we want to store all params in the form values and this is okay to do
			formValues[p.name] = webhook.parameters[p.name];
		});
		return formValues;
	};

	return (
		<>
			{webhook.loading ? (
				<Stack>
					<Spinner variant="large" color={'neutral500'} mr={'auto'} />
				</Stack>
			) : null}
			{!webhook.loading && webhook.value ? (
				<Formik initialValues={toWebhookFormValue(webhook.value)} onSubmit={handleSubmit}>
					{({ isSubmitting }) => (
						<Form noValidate>
							<ModalV2 withFooter>
								<ModalHeaderV2
									title={labelTitle ? labelTitle(webhook.value) : `Edit Webhook ${webhook.value?.name}`}
									onClose={onClose}
								/>
								<ModalContentV2>
									<WebhookForm
										type={webhook.value?.type || ''}
										disabled={
											!webhook.value?._actions.includes('edit') || isSubmitting || (isAdmin ? [] : ['type', 'scope'])
										}
									/>
								</ModalContentV2>
								<ModalFooterV2>
									<>
										{submitError && <Container width="100%">{submitError.toString()}</Container>}
										{submitError && (
											<Container width="100%">
												<Text variant="mediumStrong" color="coral">
													{submitError.toString()}
												</Text>
											</Container>
										)}
										<Button id={'save'} type={'submit'} loading={isSubmitting}>
											Save
										</Button>
									</>
								</ModalFooterV2>
							</ModalV2>
						</Form>
					)}
				</Formik>
			) : null}

			{webhook.error ? <Text color={'coral'}>{webhook.error.toString()}</Text> : null}
			{submitError ? <Text color={'coral'}>{submitError.toString()}</Text> : null}
		</>
	);
};

export default EditWebhook;
