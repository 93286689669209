/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

import { CustomLogosVO } from '../ui-api';

export class SettingsApi {
	async isSupportAccessEnabled(): Promise<boolean> {
		return Boolean((await axios.get<string>('/ui/settings/support')).data);
	}

	async setSupportAccessEnabled(enabled: boolean): Promise<void> {
		await axios.post('/ui/settings/support', { enabled });
	}

	async getCustomLogoSettings(): Promise<CustomLogosVO> {
		return (await axios.get<CustomLogosVO>('/ui/settings/logo')).data;
	}

	async setCustomLogoSettings(values: CustomLogosVO): Promise<void> {
		await axios.post('/ui/settings/logo', values);
	}

	async getIcalAccessUrl(): Promise<string> {
		return (await axios.get<string>('/ui/ical')).data;
	}

	async setIcalAccessEnabled(enabled: boolean): Promise<void> {
		await axios.post('/ui/ical', { enabled });
	}
}
