/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Container,
	ContextualMenuButton,
	ContextualMenuLink,
	ContextualMenuRouterLink,
	RouterButton,
	RouterLink,
	RouterPagination,
	Snackbar,
	Stack,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
	Tooltip,
	TutorialTooltip,
	userConfirm,
} from 'components';
import {
	IconAdd,
	IconBadge,
	IconCalendarClock,
	IconCalendarEdit,
	IconCalendarPaused,
	IconCalendarRecurrent,
	IconClose,
	IconDelete,
	IconDuplicate,
	IconEdit,
	IconExperiment,
	IconInformation,
	IconRun,
	IconStopV2,
	IconTemplate,
	IconView,
} from 'components/icons';
import { ExperimentScheduleVO, ExperimentSummaryVO, GetExperimentsPageResponse, RunningExperimentVO } from 'ui-api';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import { Dropdown, Flex, IconButton, presets } from '@steadybit/ui-components-lib';
import { createFilterParams } from 'pages/templates/FromTemplateModal/urlParams';
import ButtonRoundLongClick from 'components/ButtonRound/ButtonRoundLongClick';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import ProgressIndicator from 'components/ButtonRound/ProgressIndicator';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import useGlobalPermissions from 'services/useGlobalPermissions';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import ButtonRound from 'components/ButtonRound/ButtonRound';
import SearchBar from 'pages/components/SearchBar/SearchBar';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ListHeader from 'components/List/presets/ListHeader';
import { KillSwitchTooltip } from 'hocs/KillSwitchTooltip';
import useObservable from 'react-use/lib/useObservable';
import { useLicenseFeature } from 'services/licenseApi';
import ViewWrapper from 'pages/components/ViewWrapper';
import { localeCompareIgnoreCase } from 'utils/string';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { usePage } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { useLocation } from 'react-use';
import { TUTORIALS } from 'tutorials';
import { ampli } from 'ampli';

import EditExperimentScheduleModal from './components/Schedule/EditExperimentScheduleModal';
import useRefreshingExperimentExecutions from './hooks/useRefreshingExperimentExecutions';
import { useExperimentExecutionStatus } from './useExperimentExecutionSystemStatusCheck';
import ScheduleExperimentModal from './components/Schedule/ScheduleExperimentModal';
import { useExperimentExecutionTrigger } from './useExperimentExecutionTrigger';
import Notifications from '../../components/Notifications/Notifications';
import { directionParam, sortParam, UrlState } from './urlParams';
import invokePromise from '../../utils/ignorePromise';
import ExperimentRunTrend from './ExperimentRunTrend';
import { useUrlState } from '../../url/useUrlState';
import { useTeam } from '../../services/useTeam';

interface ExperimentListProps {
	experiments: DataStreamResult<GetExperimentsPageResponse>;
	schedules?: ExperimentScheduleVO[];
}

export default function ExperimentListWithTrend({ experiments, schedules }: ExperimentListProps): ReactElement {
	const { environmentIdParam, tagsParam, actionsParam, targetTypesParam, freeTextPhrasesParam, pageParam } = useRef(
		createFilterParams('/experiments'),
	).current;
	const [{ page }, getWithUrlState, updateUrlState] = useUrlState<UrlState>([pageParam]);

	const location = useLocation();
	const [showError, setShowError] = useState<string | undefined>(location.state?.state?.showError);
	const [showBadgeModal, setShowBadgeModal] = useState<string | undefined>(undefined);
	const [scheduleToEdit, setScheduleToEdit] = useState<ExperimentScheduleVO | undefined>(undefined);
	const [keyToCreateSchedule, setKeyToCreateSchedule] = useState<string | undefined>(undefined);

	const running = useObservable(Services.experiments.running$, []);
	const team = useTeam();

	useEffect(() => {
		ampli.experimentListViewed({ url: window.location.href });
	}, []);

	useEffect(() => {
		if (page > 0 && experiments.value?.content.length === 0) {
			updateUrlState({ page: 0 });
		}
	}, [experiments.value?.content.length, page]);

	const targetDefinitionsResult = useTargetDefinitions();
	const searchObjectsResult = usePromise(() => Services.experiments.getSearchObjects(team.id), []);
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);

	const isEmptyResult = experiments.value && !experiments.loading && experiments.value.content.length === 0;

	const [{ environmentIds, tags, targetTypes, actions, freeTextPhrases }] = useUrlState<UrlState>([
		freeTextPhrasesParam,
		environmentIdParam,
		targetTypesParam,
		actionsParam,
		tagsParam,
	]);

	const isAnyFilterDefined =
		environmentIds.length > 0 ||
		tags.length > 0 ||
		targetTypes.length > 0 ||
		actions.length > 0 ||
		freeTextPhrases.length > 0;

	const permissions = useGlobalPermissions();

	return (
		<>
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={showBadgeModal} onClose={() => setShowBadgeModal(undefined)} />
			)}
			{scheduleToEdit && (
				<EditExperimentScheduleModal
					schedule={scheduleToEdit}
					onClose={() => setScheduleToEdit(undefined)}
					disabled={false} //menu not clickable if not permitted
				/>
			)}
			{keyToCreateSchedule && (
				<ScheduleExperimentModal
					title={`Create Schedule for Experiment ${keyToCreateSchedule}`}
					experimentKey={keyToCreateSchedule}
					onClose={() => setKeyToCreateSchedule(undefined)}
					disabled={false} //menu not clickable if not permitted
				/>
			)}
			<ViewWrapper>
				<Stack px="xxLarge" py="small" flexGrow={1} size="medium">
					<ListHeader
						left={
							<ListHeaderTitle title="Experiments" Icon={IconExperiment}>
								<TutorialTooltip {...TUTORIALS.experiments.gettingStarted} />
							</ListHeaderTitle>
						}
						right={
							<Tooltip
								content={
									!permissions.experiments.canCreate
										? 'You need to be a member of the Team to create new experiments.'
										: false
								}
							>
								<RouterButton
									to="/experiments/start"
									data-cy="experiment-start"
									disabled={!permissions.experiments.canCreate}
									minWidth={180}
								>
									<IconAdd mr="xSmall" ml="-small" />
									<Text variant="mediumStrong"> New Experiment</Text>
								</RouterButton>
							</Tooltip>
						}
					/>
					<Notifications types={['LICENSE_HARD_LIMIT_REACHED_EXPERIMENT_EXECUTION']} />
					<SearchBar
						mode="experimentList"
						targetDefinitionsResult={targetDefinitionsResult}
						searchObjectsResult={searchObjectsResult}
						actions={actionsResult.value || []}
						cypressTag="search-input"
						pathname="/experiments"
					/>

					{showError && (
						<Stack
							alignItems={'left'}
							justifyContent={'center'}
							bg={'coral100'}
							height={47}
							sx={{ borderLeft: '4px solid', borderLeftColor: 'coral700', borderRadius: '3px' }}
						>
							<Container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
								<Text mx={'small'} variant={'mediumStrong'} color={'neutral800'}>
									{showError}
								</Text>
								<ButtonIcon onClick={() => setShowError(undefined)}>
									<IconClose />
								</ButtonIcon>
							</Container>
						</Stack>
					)}
					<ExperimentTable>
						{experiments.error && <TableErrorRow error={experiments.error.message} />}

						{experiments.loading && !isEmptyResult ? (
							<>
								<TableLoadingRow numColumns={5} />
								<TableLoadingRow numColumns={5} />
								<TableLoadingRow numColumns={5} />
							</>
						) : (
							experiments.value &&
							experiments.value.content.map((experiment) => (
								<ExperimentRow
									value={experiment}
									key={experiment.key}
									running={running.find((r) => r.experimentKey === experiment.key)}
									showBadgeModal={setShowBadgeModal}
									experimentSchedule={schedules?.find((s) => s.experimentKey === experiment.key)}
									editSchedule={(schedule) => {
										setScheduleToEdit(schedule);
										setKeyToCreateSchedule(undefined);
									}}
									createSchedule={(experimentKey) => {
										setScheduleToEdit(undefined);
										setKeyToCreateSchedule(experimentKey);
									}}
								/>
							))
						)}
						{isEmptyResult && (
							<TableRow>
								<TableDataCell colSpan={6}>
									<Text muted data-cy="no-experiments-found">
										{isAnyFilterDefined ? 'No Experiments matched your search.' : 'No experiments found for this team.'}
									</Text>
								</TableDataCell>
							</TableRow>
						)}
					</ExperimentTable>
					<RouterPagination
						activePage={page}
						totalPages={experiments.value?.totalPages}
						to={(i) => getWithUrlState({ page: i })}
					/>
				</Stack>
			</ViewWrapper>
		</>
	);
}

const ExperimentTable: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [{ direction }, getWithUrlState] = useUrlState<UrlState>([sortParam, directionParam]);

	return (
		<Table data-cy="experiment-table" width={'100%'}>
			<TableHead>
				<TableRow>
					<TableHeadCell>
						<TableSortLink
							sort={direction === 'DESC' ? 'asc' : 'desc'}
							to={getWithUrlState({ direction: direction === 'DESC' ? 'ASC' : 'DESC' })}
							onClick={() => {
								ampli.experimentListSorted({ sorted_by: 'Name' });
							}}
						>
							Name
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell width={160} justifyContent="flex-end">
						Run Trend
						<Tooltip content="See the latest 10 runs; last one is on the right.">
							<div>
								<IconInformation variant="small" ml="xxSmall" />
							</div>
						</Tooltip>
					</TableHeadCell>
					<TableHeadCell width={150}>Last Run</TableHeadCell>
					<TableHeadCell width={40} />
					<TableHeadCell width={40} />
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
};

const ExperimentRow: React.VFC<{
	value: ExperimentSummaryVO;
	running?: RunningExperimentVO;
	experimentSchedule?: ExperimentScheduleVO;
	showBadgeModal: (experimentKey: string) => void;
	editSchedule: (schedule: ExperimentScheduleVO) => void;
	createSchedule: (experimentKey: string) => void;
}> = ({ value, running, experimentSchedule, createSchedule, editSchedule, showBadgeModal }) => {
	const lastExecutionsResult = useRefreshingExperimentExecutions(value);

	const templatesEnabled = useLicenseFeature('TEMPLATES');
	const page = usePage('/design');

	const location = useLocation();
	const highlightedExperiment = location.state?.state?.highlightedExperiment;

	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const handleDeleteExperimentClick = (value: ExperimentSummaryVO): void => {
		invokePromise(async () => {
			if (
				(await userConfirm({
					title: 'Delete Experiment',
					message: `Do you really want to delete ${value.key} ${value.name}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{ value: 'confirm', label: `Delete ${value.key}`, variant: 'primary', dataCyTag: 'confirmDelete' },
					],
				})) === 'confirm'
			) {
				try {
					await Services.experiments.deleteExperiment(value.key, 'UI_LIST');
					Snackbar.dark('Experiment deleted.', { toastId: 'experiment-deleted' });
				} catch (err) {
					Snackbar.error('Experiment not deleted: ' + err.toString(), { toastId: 'experiment-deleted' });
				}
			}
		});
	};

	const { canEdit, canDelete } = Services.permissionsApi.getExperimentPermissions(value);

	return (
		<TableRow
			hoverable={true}
			sx={
				value.key === highlightedExperiment
					? {
							transition: 'opacity 500ms ease-out',
							animationName: 'fadeIn,fadeOut',
							animationIterationCount: '1',
							animationTimingFunction: 'ease-in-out',
							animationDuration: '0.35s,0.35s',
							animationDirection: 'alternate',
							animationDelay: '0s,1.5s',
							animationFillMode: 'forwards',

							'@keyframes fadeIn': {
								'0%': {
									opacity: '0',
								},
								'100%': {
									opacity: '1',
									backgroundColor: 'cyanLight',
								},
							},
							'@keyframes fadeOut': {
								'0%': {
									backgroundColor: 'cyanLight',
								},
								'100%': {
									backgroundColor: 'transparent',
								},
							},
						}
					: {}
			}
		>
			<TableDataCell maxWidth={200}>
				<ExperimentRunActions value={value} running={running} />
				<Stack size="none">
					<Tooltip content={`${value.key} ${value.name}`} onlyShowOnEllipsis>
						<RouterLink
							to={`/experiments/edit/${value.key}`}
							sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
						>
							<Text variant={'mediumStrong'} as={'span'}>
								{value.key}
							</Text>
							&nbsp;
							<Text variant={'mediumStrong'} as={'span'}>
								{value.name}
							</Text>
						</RouterLink>
					</Tooltip>
					<Flex direction="horizontal" spacing="xSmall">
						{value.editedBy ? (
							<Tooltip content={`${formatDateWithTime(value.edited)} by ${value.editedBy.name}`}>
								<Stack direction="horizontal" alignItems="center" size="xxSmall">
									<Text variant="small" as="span" color="neutral600" sx={{ whiteSpace: 'nowrap', minWidth: '86px' }}>
										Last edited by
									</Text>
									<Text
										variant="smallStrong"
										sx={{ color: 'neutral600', overflow: 'hidden', textOverflow: 'ellipsis' }}
										noWrap
										data-private
									>
										{value.editedBy.name}
									</Text>
								</Stack>
							</Tooltip>
						) : null}
						<presets.pill.Tags
							appearance="experiment"
							tags={value.tags.slice(0, 5).sort(localeCompareIgnoreCase)}
							small
						/>
					</Flex>
				</Stack>
			</TableDataCell>

			<TableDataCell>
				<>
					{lastExecutionsResult.value ? (
						<ExperimentRunTrend lastRuns={lastExecutionsResult.value} experimentKey={value.key} />
					) : (
						<Skeletons height={16} widths={[100]} />
					)}
				</>
			</TableDataCell>
			<TableDataCell>
				<Stack size="none">
					{lastExecutionsResult.value ? (
						lastExecutionsResult.value.length > 0 ? (
							<Container display={'flex'} flexDirection={'column'}>
								<Container maxWidth={150}>
									<StateBadge as="state" value={lastExecutionsResult.value[0].state} />
								</Container>

								<RouterLink mr={'xSmall'} to={`/experiments/edit/${value.key}/executions`}>
									<Text
										variant={'small'}
										sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums', ':hover': { color: 'slate' } }}
									>
										{`${formatDateWithTime(lastExecutionsResult.value[0].created)} `}
									</Text>
								</RouterLink>
							</Container>
						) : null
					) : (
						<Skeletons height={16} widths={[100]} />
					)}
				</Stack>
			</TableDataCell>
			<TableDataCell>
				{experimentSchedule && (
					<ButtonIcon
						onClick={() => editSchedule(experimentSchedule)}
						disabled={!canEdit}
						tooltip={
							canEdit ? 'Schedule experiment execution' : `You don't have permissions to schedule this experiment${''}`
						}
					>
						{!experimentSchedule.enabled ? (
							<IconCalendarPaused />
						) : experimentSchedule.cron ? (
							<IconCalendarRecurrent />
						) : (
							<IconCalendarClock />
						)}
					</ButtonIcon>
				)}
			</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				<Dropdown<HTMLButtonElement>
					placement="bottom-end"
					renderDropdownContent={({ close }) => (
						<presets.dropdown.DropdownContentFrame style={{ p: 'xxSmall' }}>
							<Stack size="none">
								<ContextualMenuRouterLink to={`/experiments/edit/${value.key}`}>
									{canEdit ? (
										<>
											<IconEdit /> Edit Experiment
										</>
									) : (
										<>
											<IconView /> View Experiment
										</>
									)}
								</ContextualMenuRouterLink>
								<>
									<ContextualMenuRouterLink
										data-cy="experimentActionDuplicate"
										to={page.toString('/experiments/edit/<new>/design', {
											copy: value.key,
										})}
									>
										<IconDuplicate /> Duplicate Experiment
									</ContextualMenuRouterLink>

									{canCreateTemplates && (
										<Tooltip content={<LicenseTooltipContent />} disabled={!!templatesEnabled}>
											<ContextualMenuRouterLink
												to={`/settings/templates/design/fromExperiment/${value.key}`}
												disabled={!templatesEnabled}
											>
												<IconTemplate /> Save as Template
											</ContextualMenuRouterLink>
										</Tooltip>
									)}

									<Tooltip
										content={
											canEdit
												? 'Schedule experiment execution'
												: `You don't have permissions to schedule this experiment${''}`
										}
									>
										<Container>
											<ContextualMenuButton
												onClick={() => {
													if (experimentSchedule) {
														editSchedule(experimentSchedule);
													} else {
														createSchedule(value.key);
													}
													close();
												}}
												disabled={!canEdit}
												data-cy="experimentActionSchedule"
											>
												{experimentSchedule ? <IconCalendarEdit /> : <IconCalendarClock />}
												{experimentSchedule ? 'Edit Schedule' : 'Create New Schedule'}
											</ContextualMenuButton>
										</Container>
									</Tooltip>

									{canDelete && (
										<ContextualMenuButton
											onClick={() => {
												handleDeleteExperimentClick(value);
												close();
											}}
											data-cy="experimentActionDelete"
										>
											<IconDelete /> Delete Experiment
										</ContextualMenuButton>
									)}
								</>
								<ContextualMenuLink
									onClick={() => {
										showBadgeModal(value.key);
										close();
									}}
								>
									<IconBadge /> Create Badge
								</ContextualMenuLink>
							</Stack>
						</presets.dropdown.DropdownContentFrame>
					)}
				>
					{({ setRefElement, isOpen, setOpen }) => {
						return (
							<IconButton
								ref={setRefElement}
								type="chromelessWithBorderOnHover"
								size="small"
								data-cy="experimentActions"
								icon="dots"
								onClick={() => setOpen(!isOpen)}
							/>
						);
					}}
				</Dropdown>
			</TableDataCell>
		</TableRow>
	);
};

const ExperimentRunActions: React.VFC<{
	value: ExperimentSummaryVO;
	running?: RunningExperimentVO;
}> = ({ value, running }) => {
	const killswitchActive = useObservable(Services.killswitch.killswitchActive$);

	const ongoingIncidentMessage = useExperimentExecutionStatus();
	const handleExperimentRunClick = useExperimentExecutionTrigger();

	const handleExperimentRunCancelClick = (experimentExecutionId: number): void => {
		invokePromise(async () => {
			try {
				await Services.experiments.cancelExperimentRun(experimentExecutionId);
			} catch (err) {
				Snackbar.error('Experiment not canceled: ' + err.toString(), { toastId: 'experiment-cancel' });
			}
		});
	};

	const { canRunByTeam, canRunByLicense } = Services.permissionsApi.getExperimentPermissions(value);
	const canRun = canRunByTeam && canRunByLicense;

	return (
		<Tooltip
			content={
				killswitchActive ? (
					<KillSwitchTooltip />
				) : (
					ongoingIncidentMessage ||
					(canRun
						? 'Long press to run experiment'
						: !canRunByTeam
							? 'You need to be a member of the Team to run an experiment'
							: `You've reached your maximum number of experiment runs.${''}`)
				)
			}
		>
			<Container minWidth="48px">
				{!running ? (
					<ButtonRoundLongClick
						variant={'secondary'}
						size={'medium'}
						onClick={() =>
							handleExperimentRunClick(value.key, {
								redirectToEditorOnRunFailure: true,
								executionSource: 'UI_EXPERIMENT_LIST',
							})
						}
						onLongClickReleased={(fired, progress) => ampli.runExperimentLongClickReleased({ fired, progress })}
						disabled={ongoingIncidentMessage !== undefined || !canRun || killswitchActive}
						data-cy={`run-${value.name}`}
						mr={'small'}
					>
						<IconRun />
					</ButtonRoundLongClick>
				) : (
					<ButtonRound
						variant={'danger'}
						size={'medium'}
						data-cy={`run-${value.name}`}
						mr={'small'}
						tooltip={'Stop experiment'}
						onClick={() => handleExperimentRunCancelClick(running.executionId)}
					>
						<>
							<ProgressIndicator
								start={running?.started?.valueOf()}
								end={running?.estimatedEnd?.valueOf()}
								size={'medium'}
							/>
							<IconStopV2 />
						</>
					</ButtonRound>
				)}
			</Container>
		</Tooltip>
	);
};
