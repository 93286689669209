/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { VariableVO, TargetPredicateTemplateVO, TargetPredicateVO, TemplatePlaceholderVO } from 'ui-api';
import { Button, Container, Snackbar, Stack, Text, Tooltip, userConfirmV2 } from 'components';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { toPredicateString } from 'queryLanguage/parser/serializer';
import { toTargetPredicate } from 'queryLanguage/parser/parser';
import { isParsingError } from 'queryLanguage/parser/types';
import { ExperimentError } from 'pages/experimentsV2/types';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { mergeAndSort } from 'utils/envVars';
import { theme } from 'styles.v2/theme';
import { useField } from 'formik';

import PredicateBuilder from '../PredicateBuilder/PredicateBuilder';
import { isPredicateEditableWithTheQueryBuilder } from './utils';
import PredicateTemplates from './PredicateTemplates';
import Editor from '../Editor/Editor';

export type KeyError = {
	key: ExperimentError;
};

export type ValuesError = {
	values: ExperimentError[];
};

interface PredicateEditorProps {
	targetPredicateTemplates?: TargetPredicateTemplateVO[];
	partErrors?: Array<KeyError | ValuesError>;
	predicate: TargetPredicateVO | undefined;
	additionalActions?: React.ReactNode;
	environmentId?: string;
	hasErrors?: boolean;
	targetType?: string;
	disabled?: boolean;
	onAttributeKeyChange?: (key: string, change: 'added' | 'removed' | 'replaced') => void;
	onTargetPredicateTemplateApplied?: (predicate: TargetPredicateTemplateVO) => void;
	setPredicate: (predicate: TargetPredicateVO | undefined) => void;
	autoFocus?: boolean;
}

export default function PredicateEditor({
	targetPredicateTemplates,
	additionalActions,
	partErrors = [],
	environmentId,
	targetType,
	hasErrors,
	predicate,
	disabled,
	onTargetPredicateTemplateApplied,
	onAttributeKeyChange,
	setPredicate,
	autoFocus,
}: PredicateEditorProps): ReactElement {
	const isEditableWithTheQueryBuilder = isPredicateEditableWithTheQueryBuilder(predicate);

	const predicateString = toPredicateString(predicate);
	const keys = usePromise(
		() => Services.editorApi.fetchTargetAttributeKeys({ targetType, environmentId }),
		[targetType, environmentId],
	);
	const [mode, setMode] = useState<'queryBuilder' | 'editor'>(
		isEditableWithTheQueryBuilder ? 'queryBuilder' : 'editor',
	);
	const { mode: editorMode } = useEditorSettings();

	const [forceUpdateSignal, setForceUpdateSignal] = useState(0);

	const fetchAttributeValues = useCallback(
		(key: string) => Services.editorApi.fetchTargetAttributeValues({ key, targetType, environmentId }),
		[targetType, environmentId],
	);

	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>('placeholders');
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>('variables');
	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');
	const environmentAndExperimentVariables = mergeAndSort(environmentVariables, experimentVariables);

	const [tempFilterQuery, setTempFilterQuery] = useState(predicateString);

	const showApplyButton = predicateString !== tempFilterQuery;

	// This is a very special behaviour handling: When applying a template, we want to warn users that the current predicate
	// will be replaced by the template. Still, for the use case, that users want to toggle between templates, we don't want
	// to annoy them with the warning. Therefore, we keep track of the last applied template and reset it, when the user
	// changes the predicate manually (or when the sidebar closes and this component gets unmounted).
	const [lastAppliedTemplatePredicate, setLastAppliedTemplatePredicate] = useState<string | undefined>(undefined);
	useEffect(() => {
		if (lastAppliedTemplatePredicate && predicateString !== lastAppliedTemplatePredicate) {
			setLastAppliedTemplatePredicate(undefined);
		}
	}, [lastAppliedTemplatePredicate, predicateString]);

	const isOverallErrornoeus =
		(hasErrors && mode === 'editor') ||
		(hasErrors && mode === 'queryBuilder' && (!isEditableWithTheQueryBuilder || partErrors.length === 0));

	return (
		<Stack size="small">
			<PredicateTemplates
				predicateTemplates={targetPredicateTemplates || []}
				keys={keys.value || []}
				onApplyTemplate={async (tpt) => {
					const parseResult = toTargetPredicate(tpt.template);
					if (!isParsingError(parseResult)) {
						if (
							!predicateString ||
							lastAppliedTemplatePredicate ||
							(await userConfirmV2({
								title: 'Overwrite your target selection query?',
								width: '800px',
								message: 'Prefilling the target selection query will overwrite any changes you have made to it.',
								actions: [
									{ value: 'cancel', label: 'Cancel', variant: 'chromeless' },
									{
										value: 'overwrite',
										label: 'Overwrite',
										variant: 'primary',
									},
								],
							})) === 'overwrite'
						) {
							setPredicate(parseResult);
							setForceUpdateSignal(forceUpdateSignal + 1);
							onTargetPredicateTemplateApplied?.(tpt);
							setLastAppliedTemplatePredicate(toPredicateString(parseResult));
							Snackbar.dark('✓ Prefilled query');
						}
					}
				}}
			/>
			<Stack size="none">
				<Container display="flex" alignItems="center" justifyContent="space-between">
					<Stack direction="horizontal" size="none">
						<Button
							variant={mode === 'queryBuilder' ? 'primarySmall' : 'secondarySmall'}
							onClick={() => setMode('queryBuilder')}
							sx={{ borderRadius: '4px 0 0 0', borderBottom: 0 }}
						>
							Query UI
						</Button>
						<Button
							variant={mode === 'editor' ? 'primarySmall' : 'secondarySmall'}
							onClick={() => setMode('editor')}
							sx={{ borderRadius: '0 4px 0 0', borderBottom: 0 }}
						>
							Query Language
						</Button>
					</Stack>
					{additionalActions}
				</Container>

				<Container
					sx={{
						border: isOverallErrornoeus ? '2px solid ' + theme.colors.coral : '1px solid ' + theme.colors.neutral200,
						borderRadius: '0 0 4px 4px',
						bg: 'neutral000',
					}}
				>
					{mode === 'queryBuilder' ? (
						<Container py="medium" pl="small" pr="small">
							{isEditableWithTheQueryBuilder ? (
								<PredicateBuilder
									variables={environmentAndExperimentVariables}
									attributeKeysLoading={keys.loading}
									attributeKeys={keys.value}
									partErrors={partErrors}
									disabled={disabled}
									value={predicate}
									onChange={(predicate) => {
										setPredicate(predicate);
										setTempFilterQuery(toPredicateString(predicate));
									}}
									onAttributeKeyChange={onAttributeKeyChange}
									fetchAttributeValues={fetchAttributeValues}
									autoFocus={autoFocus}
								/>
							) : (
								<Stack size="xSmall" alignItems="center">
									<Text variant="mediumStrong" color="neutral800">
										¯\_(ツ)_/¯
									</Text>
									<Text variant="medium" color="neutral600">
										The query is too complex for the Query UI
									</Text>
								</Stack>
							)}
						</Container>
					) : (
						<Container>
							<Editor
								forceUpdateSignal={forceUpdateSignal}
								disabled={disabled}
								initialValue={predicateString}
								onValidCodeChanged={(codeString) => {
									setTempFilterQuery(codeString);
								}}
								targetType={targetType}
								environmentId={environmentId}
								getAdditionalSuggestions={() => environmentAndExperimentVariables.map(({ key }) => `{{${key}}}`)}
								getAdditionalKeywords={() =>
									editorMode === 'templateEditor' ? placeholders.map(({ key }) => `[[${key}]]`) : []
								}
								allowFullQueryPlaceholder={editorMode === 'templateEditor'}
								onApply={() => {
									if (showApplyButton) {
										setPredicate(getQueryPredicateOrEmpty(tempFilterQuery));
									}
								}}
							/>
							<Container
								display="flex"
								flexDirection="row-reverse"
								alignItems="center"
								justifyContent="space-between"
								width="100%"
							>
								{showApplyButton && (
									<Tooltip content={getApplyShortcutTooltip()}>
										<Button
											variant="primarySmall"
											onClick={() => setPredicate(getQueryPredicateOrEmpty(tempFilterQuery))}
											m="xxSmall"
										>
											Apply
										</Button>
									</Tooltip>
								)}
							</Container>
						</Container>
					)}
				</Container>
			</Stack>
		</Stack>
	);
}

export function getApplyShortcutTooltip(): string {
	if (window.navigator.userAgent.indexOf('Mac') !== -1) {
		return '⌘ + ↩';
	}
	return 'Ctrl + Enter';
}

function getQueryPredicateOrEmpty(query: string): TargetPredicateVO | undefined {
	if (query) {
		return { query };
	}
	return undefined;
}
