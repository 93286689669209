/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { TemplatePlaceholderRegexKeepingBrackets } from 'utils/templates';
import { EnvVarRegexKeepingBrackets } from 'utils/envVars';
import { FieldMetaProps } from 'formik';

export function hasError(meta: FieldMetaProps<unknown>): boolean {
	return Boolean(meta.touched && meta.error);
}

export function smellsLikeEnvVar(value: unknown): boolean {
	if (typeof value === 'string' && value.length > 4) {
		const matches = value.match(EnvVarRegexKeepingBrackets);
		return matches && matches?.length > 0 ? true : false;
	}
	return false;
}

export function smellsLikeTemplatePlaceholder(value: unknown): boolean {
	if (typeof value === 'string' && value.length > 4) {
		const matches = value.match(TemplatePlaceholderRegexKeepingBrackets);
		return matches && matches?.length > 0 ? true : false;
	}
	return false;
}
