/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react-markdown/lib/react-markdown';
import { presets } from '@steadybit/ui-components-lib';

interface TeamRoleSelectProps {
	value?: string;
	onChange: (value: string) => void;
}

export default function TeamRoleSelect({ value, onChange }: TeamRoleSelectProps): ReactElement {
	const owner = { id: 'OWNER', label: 'Owner' };
	const member = { id: 'MEMBER', label: 'Member' };

	return (
		<presets.dropdown.SingleChoiceButton
			placement="bottom-start"
			selectedId={value}
			items={[owner, member]}
			size="small"
			onSelect={onChange}
			style={{
				width: '110px',
			}}
		>
			{value === owner.id ? owner.label : member.label}
		</presets.dropdown.SingleChoiceButton>
	);
}
