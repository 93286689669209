/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, ItemRenderer, presets, SingleChoiceListItem, Ul } from '@steadybit/ui-components-lib';
import { RouterButton, RouterLink, Stack, Text } from 'components';
import { IconAdd, IconBookmark } from 'components/icons';
import { localeCompareIgnoreCase } from 'utils/string';
import { LandscapeViewVO } from 'ui-api';
import { ReactElement } from 'react';
import { ampli } from 'ampli';

interface ExploreViewsContentProps {
	templateViews: LandscapeViewVO[];
	selectedViewId?: string | null;
	views: LandscapeViewVO[];
	onChangeView: () => void;
}

export default function ExploreViewsContent({
	selectedViewId,
	templateViews,
	onChangeView,
	views,
}: ExploreViewsContentProps): ReactElement {
	return (
		<presets.dropdown.DropdownContentFrame maxWidth="350px" maxHeight="600px">
			<Stack
				direction="horizontal"
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					px: 'small',
					pt: 'small',
				}}
			>
				<RouterLink to="/landscape/views" onClick={() => onChangeView()}>
					<Stack
						direction="horizontal"
						size="xSmall"
						sx={{
							alignItems: 'center',
							cursor: 'pointer',
							color: 'neutral800',
							':hover': {
								color: 'slate',
							},
						}}
					>
						<IconBookmark />
						<Text variant="mediumStrong">Saved views</Text>
					</Stack>
				</RouterLink>

				<RouterButton
					variant="primarySmall"
					to="/landscape/explore/<new>"
					onClick={() => {
						ampli.landscapeExplorerNewView({
							landscape_explorer_new_view_context: 'view_switcher',
							url: window.location.href,
						});
						onChangeView();
					}}
					sx={{ borderRadius: 2 }}
					dontResolve
				>
					<IconAdd mr="xSmall" ml="-xSmall" />
					<Text variant="mediumStrong"> New view</Text>
				</RouterButton>
			</Stack>

			{views.length > 0 && (
				<>
					<Text variant="medium" color="neutral600" pt="small" px="small">
						YOUR VIEWS
					</Text>
					<Ul<SingleChoiceListItem>
						items={views
							.sort((v1, v2) => localeCompareIgnoreCase(v1.name, v2.name))
							.map((view) => ({ id: view.id, label: view.name, isSelected: selectedViewId === view.id }))}
						ItemRenderer={ViewListItemRenderer}
						onSelect={onChangeView}
					/>
				</>
			)}

			{templateViews.length > 0 && (
				<>
					<div style={{ height: '1px', backgroundColor: Colors.neutral200, margin: '12px 12px 0' }} />
					<Text variant="medium" color="neutral600" pt="small" px="small">
						SHARED BY STEADYBIT
					</Text>
					<Ul<SingleChoiceListItem>
						items={templateViews
							.sort((v1, v2) => localeCompareIgnoreCase(v1.name, v2.name))
							.map((view) => ({ id: view.id, label: view.name, isSelected: selectedViewId === view.id }))}
						ItemRenderer={ViewListItemRenderer}
						onSelect={onChangeView}
					/>
				</>
			)}
		</presets.dropdown.DropdownContentFrame>
	);
}

const ViewListItemRenderer: ItemRenderer<SingleChoiceListItem> = (props) => {
	const href = `/landscape/explore/${props.item.id}`;
	return (
		<RouterLink to={href} dontResolve style={{ textDecoration: 'none' }}>
			<presets.dropdown.SingleChoiceListItemRenderer {...props} />
		</RouterLink>
	);
};
