/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Pill, Text, Tooltip, smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { TemplatePlaceholderOrVariableRegexKeepingBrackets } from 'utils/templates';
import { useEditorSettings } from 'pages/experimentsV2/useEditorSettings';
import { EnvVarRegexKeepingBrackets, mergeAndSort } from 'utils/envVars';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { TemplatePlaceholderVO, VariableVO } from 'ui-api';
import { IconClose } from 'components/icons';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface ChunkProps {
	value: string;
}

export default function Chunks({ value = '' }: ChunkProps): ReactElement {
	const { mode } = useEditorSettings();
	const chunks = mode === 'experiment' ? getVariableChunks(value) : getTemplateChunks(value);

	return (
		<div style={{ ...textEllipsis }}>
			{chunks.map((chunk, index) => (
				<Chunk key={index} chunk={chunk} />
			))}
		</div>
	);
}

function Chunk({ chunk }: { chunk: string }): ReactElement {
	const { mode } = useEditorSettings();
	if (mode !== 'experiment') {
		if (smellsLikeEnvVar(chunk)) {
			return (
				<HighlightedChunk tooltip={chunk} background="purple200" color="purple800">
					{chunk}
				</HighlightedChunk>
			);
		}
		if (smellsLikeTemplatePlaceholder(chunk)) {
			return <Placeholder placeholder={chunk} />;
		}
		return <span style={{ fontSize: '15px' }}>{chunk}</span>;
	}

	if (smellsLikeEnvVar(chunk)) {
		return <Variable variableKey={chunk} />;
	}
	return <span style={{ ...textEllipsis }}>{chunk}</span>;
}

function Variable({ variableKey, onDelete }: { variableKey: string; onDelete?: () => void }): ReactElement {
	const [{ value: environmentVariables = [] }] = useField<VariableVO[]>({ name: 'variables' });
	const [{ value: experimentVariables = [] }] = useField<VariableVO[]>('experimentVariables');
	const environmentAndExperimentVariables = mergeAndSort(environmentVariables, experimentVariables);

	const resolvedVariable = environmentAndExperimentVariables.find((variable) => `{{${variable.key}}}` === variableKey);
	const isResolved = resolvedVariable && resolvedVariable.value ? true : false;

	return (
		<HighlightedChunk
			tooltip={variableKey}
			background={isResolved ? 'slateMidLight40p' : 'coral200'}
			color={isResolved ? 'neutral800' : 'coral'}
			onDelete={onDelete}
		>
			{resolvedVariable && resolvedVariable.value ? resolvedVariable.value : variableKey}
		</HighlightedChunk>
	);
}

function Placeholder({ placeholder, onDelete }: { placeholder: string; onDelete?: () => void }): ReactElement {
	const { mode: editorMode } = useEditorSettings();

	const cleanedPlaceholder = placeholder.substring(2, placeholder.length - 2);
	const [{ value: placeholderValuesMap = new Map() }] = useField<Map<string, string>>({ name: 'placeholderValuesMap' });
	const [{ value: placeholders = [] }] = useField<TemplatePlaceholderVO[]>({ name: 'placeholders' });

	const content =
		editorMode === 'templateUsage'
			? placeholderValuesMap.get(cleanedPlaceholder) ||
				placeholders.find((p) => p.key === cleanedPlaceholder)?.name ||
				placeholder
			: placeholder;

	return (
		<HighlightedChunk background="aqua300" tooltip={content} color="aqua800" onDelete={onDelete}>
			{content}
		</HighlightedChunk>
	);
}

function HighlightedChunk({
	background,
	children,
	tooltip,
	color,
	onDelete,
}: {
	background: string;
	children: string;
	tooltip: string;
	color: string;
	onDelete?: () => void;
}): ReactElement {
	return (
		<div style={{ display: 'inline-flex', width: 'fit-content' }}>
			<Pill
				backgroundColor={background}
				color={color}
				sx={{
					py: '4px',
					width: 'fit-content',
					fontSize: '13px',
					fontWeight: '500',
				}}
			>
				<>
					<Tooltip content={tooltip}>
						<Text variant="smallMedium" sx={{ lineHeight: '16px' }}>
							{children}
						</Text>
					</Tooltip>

					{onDelete && (
						<Tooltip content="Clear variable">
							<span>
								<IconClose
									variant="xSmall"
									onClick={onDelete}
									sx={{
										ml: 'xxSmall',
										color: 'neutral700',
										cursor: 'pointer',
										':hover': {
											color: 'neutral400',
										},
									}}
								/>
							</span>
						</Tooltip>
					)}
				</>
			</Pill>
		</div>
	);
}

// exported for testing
export function getVariableChunks(value: string): string[] {
	return getChunks(value, EnvVarRegexKeepingBrackets);
}

// exported for testing
export function getTemplateChunks(value: string): string[] {
	return getChunks(value, TemplatePlaceholderOrVariableRegexKeepingBrackets);
}

function getChunks(value: string, regex: RegExp): string[] {
	const matches = value.match(regex);
	if (!matches || matches.length === 0) {
		return [value];
	}

	const chunks = [];
	let lastMatchIndex = 0;
	for (let i = 0; i < matches.length; i++) {
		const match = matches[i];
		const matchIndex = value.indexOf(match, lastMatchIndex);
		const chunk = value.substring(lastMatchIndex, matchIndex);
		chunks.push(chunk);
		chunks.push(match);
		lastMatchIndex = matchIndex + match.length;
	}
	const lastChunk = value.substring(lastMatchIndex);
	if (lastChunk) {
		chunks.push(lastChunk);
	}
	return chunks.filter(Boolean);
}
