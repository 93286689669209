/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CompositeTargetPredicateVO,
	ExperimentStepRadiusVO,
	GetTargetAttributeDescriptionsSummaryResponse,
	GetTargetsPageResponse,
	ListResponse,
	MissingQuerySelectionVO,
	QueryLanguagePredicateVO,
	TargetAttributeDescriptionVO,
	TargetEnrichmentRuleOnAgentInfoVO,
	TargetEnrichmentRuleSummaryVO,
	TargetEnrichmentRuleVO,
	TargetPredicateVO,
	TargetPredicateWithVariablesVO,
	TargetTypeDescriptionVO,
	TargetTypeOnAgentInfoVO,
	TargetTypeSummaryVO,
	VariableVO,
} from 'ui-api';
import { PageParams } from 'utils/hooks/usePage';
import { sortBy, startCase } from 'lodash';
import cached from 'utils/cached';
import { filter } from 'rxjs';
import axios from 'axios';

import { EventsApi } from './eventsApi';

const CATEGORY_LABELS = new Map([
	['aws', 'AWS'],
	['aws-eks', 'AWS EKS'],
	['aws-ec2', 'AWS EC2'],
	['aws-ecs', 'AWS ECS'],
	['aws-elb', 'AWS ELB'],
	['google-gce', 'Google GCE'],
	['google-gke', 'Google GKE'],
	['azure-vm', 'Azure VM'],
	['azure-aks', 'Azure AKS'],
	['k8s', 'Kubernetes'],
	['k8s.pod.label', 'Kubernetes Labels'],
	['jvm', 'JVM'],
]);

export const getCategoryLabel = (category: string): string => {
	return CATEGORY_LABELS.get(category) || startCase(category);
};

export const isPredicateEmpty = (predicate?: TargetPredicateVO): boolean => {
	if (predicate && ((predicate as QueryLanguagePredicateVO).query?.trim().length ?? 0 > 0)) {
		return false;
	}

	return (
		!predicate ||
		!(predicate as CompositeTargetPredicateVO).predicates ||
		!(predicate as CompositeTargetPredicateVO).predicates.length
	);
};

export class TargetsApi {
	getAttributeDefinition = cached(this.getAttributeDefinitionInternal.bind(this));
	getAttributeDefinitions = cached(this.getAttributeDefinitionsInternal.bind(this));
	getTargetDefinition = cached(this.getTargetDefinitionInternal.bind(this));
	getTargetDefinitions = cached(this.getTargetDefinitionsInternal.bind(this));

	constructor(events: EventsApi) {
		//invalidate the cache when the settings changes
		events.events$
			.pipe(filter((event) => ['targetType.updated', 'targetType.created', 'targetType.deleted'].includes(event.type)))
			.subscribe(() => {
				this.getAttributeDefinition.invalidateCaches();
				this.getAttributeDefinitions.invalidateCaches();
				this.getTargetDefinition.invalidateCaches();
				this.getTargetDefinitions.invalidateCaches();
			});
	}

	async fetchTarget(
		predicate: TargetPredicateVO,
		anyUsage?: boolean,
		page: PageParams = new PageParams(0, 20),
	): Promise<GetTargetsPageResponse> {
		const params = page.toUrlSearchParams();
		if (anyUsage !== undefined) {
			params.append('anyUsage', String(anyUsage));
		}
		return (await axios.post<GetTargetsPageResponse>('/ui/targets', predicate, { params })).data;
	}

	async fetchAdviceTargets(
		predicate: TargetPredicateVO,
		adviceType: string,
		targetType: string,
		requiredAttributes: string[],
		query: string,
		page: PageParams,
		filteredStatus: string[],
	): Promise<GetTargetsPageResponse> {
		const params = page.toUrlSearchParams();
		return (
			await axios.post<GetTargetsPageResponse>(
				'/ui/targets/advice',
				{
					predicate,
					adviceType,
					targetType,
					requiredAttributes,
					query,
					filteredStatus,
				},
				{ params },
			)
		).data;
	}

	async fetchTargets(
		targetType: string,
		query: string,
		requiredAttributes: string[],
		environmentId?: string,
		predicate?: TargetPredicateVO,
		page: PageParams = new PageParams(0, 20),
		experimentVariables?: VariableVO[],
	): Promise<GetTargetsPageResponse> {
		const params = page.toUrlSearchParams({ query, requiredAttributes: requiredAttributes.join(',') });
		if (environmentId) {
			params.append('environmentId', environmentId);
		}
		const body = { predicate, experimentVariables } as TargetPredicateWithVariablesVO;
		return (await axios.post<GetTargetsPageResponse>('/ui/targets/type/' + targetType, body, { params })).data;
	}

	async countTargets(
		environmentId: string,
		predicate?: TargetPredicateVO,
		experimentVariables?: VariableVO[],
	): Promise<Record<string, number>> {
		if (environmentId) {
			const params = new URLSearchParams();
			params.append('environmentId', environmentId);

			if (experimentVariables) {
				experimentVariables = experimentVariables.map((variable) =>
					typeof variable === 'string' ? { key: variable, value: '' } : variable,
				);
			}

			const body = { predicate, experimentVariables: experimentVariables } as TargetPredicateWithVariablesVO;
			return (await axios.post<Record<string, number>>('/ui/targets/count', body, { params })).data;
		} else {
			return {};
		}
	}

	async countTargetsForBlastRadius(
		environmentId: string,
		missingQuerySelection: MissingQuerySelectionVO,
		{ predicate, targetType }: Pick<ExperimentStepRadiusVO, 'predicate' | 'targetType'>,
		experimentVariables: VariableVO[],
	): Promise<number> {
		if ((missingQuerySelection === 'INCLUDE_NONE' && isPredicateEmpty(predicate)) || targetType == null) {
			return 0;
		}
		const targetCounts = await this.countTargets(environmentId, predicate, experimentVariables);
		return targetCounts[targetType] ?? 0;
	}

	async fetchTargetTypeSummaries(): Promise<ListResponse<TargetTypeSummaryVO>> {
		const response = await axios.get<ListResponse<TargetTypeSummaryVO>>('/ui/targets/descriptions/types/summaries');
		return {
			...response.data,
			content: sortBy([...response.data.content], 'id'),
		};
	}

	async deleteTargetTypeDescription(id: string): Promise<void> {
		await axios.delete(`/ui/targets/descriptions/types/${id}`);
	}

	async fetchTargetAttributeDefinitions(): Promise<GetTargetAttributeDescriptionsSummaryResponse> {
		const response = await axios.get<GetTargetAttributeDescriptionsSummaryResponse>(
			'/ui/targets/descriptions/attributes/summaries',
		);
		return {
			...response.data,
			content: sortBy([...response.data.content], 'attribute'),
		};
	}

	async fetchTargetDefinitionOnAgentInfo(id: string): Promise<ListResponse<TargetTypeOnAgentInfoVO>> {
		return (await axios.get<ListResponse<TargetTypeOnAgentInfoVO>>(`/ui/targets/descriptions/types/${id}/agents`)).data;
	}

	async fetchTargetEnrichmentRulesSummaries(): Promise<ListResponse<TargetEnrichmentRuleSummaryVO>> {
		return (await axios.get<ListResponse<TargetEnrichmentRuleSummaryVO>>('/ui/targets/enrichment-rules/summaries'))
			.data;
	}

	async deleteTargeEnrichmentRule(id: string): Promise<void> {
		await axios.delete(`/ui/targets/enrichment-rules/${id}`);
	}

	async fetchTargetEnrichmentRuleOnAgentInfo(id: string): Promise<ListResponse<TargetEnrichmentRuleOnAgentInfoVO>> {
		return (
			await axios.get<ListResponse<TargetEnrichmentRuleOnAgentInfoVO>>(`/ui/targets/enrichment-rules/${id}/agents`)
		).data;
	}

	async fetchTargetEnrichmentRule(id: string): Promise<TargetEnrichmentRuleVO> {
		return (await axios.get<TargetEnrichmentRuleVO>(`/ui/targets/enrichment-rules/${id}`)).data;
	}

	private async getAttributeDefinitionInternal(attribute: string): Promise<TargetAttributeDescriptionVO | undefined> {
		const definitions = await this.getAttributeDefinitions();
		return definitions.find((definition) => definition.attribute === attribute);
	}

	private async getAttributeDefinitionsInternal(): Promise<TargetAttributeDescriptionVO[]> {
		const response = await axios.get<ListResponse<TargetAttributeDescriptionVO>>('/ui/targets/descriptions/attributes');
		return response.data.content;
	}

	private async getTargetDefinitionInternal(id: string): Promise<TargetTypeDescriptionVO | undefined> {
		const typeDefinitions = await this.getTargetDefinitions();
		return typeDefinitions.find((typeDefinition) => typeDefinition.id === id);
	}

	private async getTargetDefinitionsInternal(): Promise<TargetTypeDescriptionVO[]> {
		const response = await axios.get<ListResponse<TargetTypeDescriptionVO>>('/ui/targets/descriptions/types');
		return response.data.content;
	}
}
