/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement } from 'react';
import { Text } from 'components';

interface LabeledProps {
	title: string;
	children: ReactElement;
}

export default function Labeled({ title, children }: LabeledProps): ReactElement | null {
	return (
		<div
			style={{
				display: 'grid',
				gridTemplateRows: '24px 1fr',
				gap: '6px',
			}}
		>
			<Text variant="smallStrong" color="neutral600">
				{title}:
			</Text>

			{children}
		</div>
	);
}
