/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import useActionHierarchy, { ActionCategory, ActionCategoryItem } from 'experiment/actions/useActionHierarchy';
import { Button, Colors, Dropdown, presets } from '@steadybit/ui-components-lib';
import { IconArrowDropDown, IconArrowDropUp } from 'components/icons';
import { ReactElement, ReactNode, useState } from 'react';
import useActions from 'pages/experimentsV2/useActions';
import { Container, Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

interface StepTypeSwitcherProps {
	onActionClick: (actionId: string) => void;
	actionId: string;
	label: string;
}

export default function StepTypeSwitcher({ actionId, label, onActionClick }: StepTypeSwitcherProps): ReactElement {
	return (
		<Dropdown<HTMLButtonElement>
			placement="bottom-start"
			renderDropdownContent={() => (
				<Content
					key={actionId}
					actionId={actionId}
					onActionClick={(id) => {
						onActionClick(id);
					}}
				/>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Button
						ref={setRefElement}
						type="chromeless"
						size="small"
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						style={{ width: 'fit-content', maxWidth: '100%', color: Colors.neutral600 }}
						onClick={() => setOpen(!isOpen)}
					>
						{label}
					</Button>
				);
			}}
		</Dropdown>
	);
}

interface ContentProps {
	actionId: string;
	onActionClick: (actionId: string) => void;
}

function Content({ actionId, onActionClick }: ContentProps): ReactElement {
	const { actions } = useActions();
	const actionCategories = useActionHierarchy({ actions });

	return (
		<presets.dropdown.DropdownContentFrame maxHeight="500px">
			{actionCategories.map((category) => (
				<Category key={category.label} label={category.label} initExpanded={containsAction(category, actionId)}>
					{category.subCategories &&
						category.subCategories.map((subCategory) => (
							<SubCategory key={subCategory.label} label={subCategory.label}>
								<ItemList
									selectedActionId={actionId}
									actions={subCategory.actions}
									onActionClick={(id) => {
										onActionClick(id);
									}}
								/>
							</SubCategory>
						))}

					<Stack size="none" px="small" pb="xSmall">
						{category.actions && (
							<ItemList
								selectedActionId={actionId}
								actions={category.actions}
								onActionClick={(id) => {
									onActionClick(id);
								}}
							/>
						)}
					</Stack>
				</Category>
			))}
		</presets.dropdown.DropdownContentFrame>
	);
}

function Category({
	children,
	label,
	initExpanded,
}: {
	label: string;
	children: ReactNode;
	initExpanded: boolean;
}): ReactElement | null {
	const [isExpanded, setIsExpanded] = useState(initExpanded);

	if (!label) {
		return null;
	}

	return (
		<Container
			sx={{
				borderBottom: '1px solid ' + theme.colors.neutral200,
				':last-child': {
					borderBottom: 'none',
				},
			}}
		>
			<Stack
				direction="horizontal"
				onClick={() => setIsExpanded(!isExpanded)}
				sx={{
					alignItems: 'center',
					justifyContent: 'space-between',
					px: 'small',
					py: 'small',
					color: 'neutral800',
					cursor: 'pointer',
					':hover': {
						bg: 'neutral100',
						color: 'slate',
					},
				}}
			>
				<Text variant="smallStrong">{label}</Text>
				{isExpanded ? <IconArrowDropUp color="neutral600" /> : <IconArrowDropDown color="neutral600" />}
			</Stack>
			{isExpanded && children}
		</Container>
	);
}

function SubCategory({ children, label }: { label: string; children: ReactNode }): ReactElement {
	return (
		<Stack
			size="xxSmall"
			sx={{
				mx: 'small',
				mb: 'small',
			}}
		>
			<Text variant="small" color="neutral600">
				{label}
			</Text>
			{children}
		</Stack>
	);
}

function ItemList({
	selectedActionId,
	actions,
	onActionClick,
}: {
	selectedActionId: string;
	actions: ActionCategoryItem[];
	onActionClick: (id: string) => void;
}): ReactElement {
	return (
		<Stack size="none">
			{actions.map((item) => {
				const isSelected = item.action.id === selectedActionId;
				return (
					<Text
						key={item.label}
						variant="small"
						onClick={isSelected ? undefined : () => onActionClick(item.action.id)}
						sx={{
							py: 'xxSmall',
							px: 'small',
							borderRadius: 2,
							cursor: isSelected ? 'default' : 'pointer',
							border: '1px solid transparent',
							':hover': {
								bg: 'purple100',
								border: '1px solid ' + theme.colors.purple200,
							},

							bg: isSelected ? 'purple100' : 'transparent',
							color: isSelected ? 'slate' : 'neutral800',
						}}
					>
						{item.label}
					</Text>
				);
			})}
		</Stack>
	);
}

function containsAction(category: ActionCategory, actionId: string): boolean {
	if (category.subCategories) {
		return category.subCategories.some((subCategory) =>
			subCategory.actions.some((action) => action.action.id === actionId),
		);
	}
	if (category.actions) {
		return category.actions.some((action) => action.action.id === actionId);
	}
	return false;
}
