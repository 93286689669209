/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import Notifications from 'components/Notifications/Notifications';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import ListHeader from 'components/List/presets/ListHeader';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { RouterButton, Text, Tooltip } from 'components';
import { IconAdd, IconTeam } from 'components/icons';
import DimensionAware from 'hocs/DimensionAware';
import { usePage } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { TUTORIALS } from 'tutorials';
import { debounce } from 'lodash';

import ContentWrapper from '../components/ContentWrapper';
import HelpText from '../components/HelpText';
import TeamContent from './TeamContent';
import { ampli } from '../../../ampli';

export default function Teams(): ReactElement {
	const permissions = useGlobalPermissions();

	const page = usePage('/teams', { page: 0, size: 15, sort: [['name', 'asc', 'ignoreCase']] });

	const [teams, fetch] = useAsyncState(
		() => Services.teams.fetchTeams(page.pageParams),
		[page.pageParams.page, page.pageParams.size, page.pageParams.sort.join(',')],
	);

	const debouncedFetch = useMemo(() => debounce(fetch, 100), [fetch]);
	useEventEffect(
		useCallback(
			(event) => {
				if ('team.created' === event.type || 'team.deleted' === event.type || 'team.updated' === event.type) {
					debouncedFetch();
				} else if (teams.value?.content.some((team) => team.id === event.teamId)) {
					debouncedFetch();
				}
			},
			[debouncedFetch, teams],
		),
		[],
		debouncedFetch.cancel,
		[teams.value],
	);

	useEffect(() => {
		ampli.teamListViewed({ url: window.location.href });
	}, []);

	return (
		<ContentWrapper>
			<ListHeader
				left={<ListHeaderTitle title="Teams" Icon={IconTeam} />}
				description={
					<>
						<HelpText>{TUTORIALS.settings.teams.children}</HelpText>
						<Notifications types={['LICENSE_HARD_LIMIT_REACHED_TEAM_SIZE']} />
					</>
				}
				right={
					<Tooltip content={'You reached your maximum number of teams.'} disabled={permissions.teams.canCreate}>
						<RouterButton
							color="primaryLarge"
							to="/settings/teams/create"
							data-cy="create-team"
							minWidth="fit-content"
							disabled={!permissions.teams.canCreate}
						>
							<IconAdd mr="xSmall" ml="-xxSmall" />
							<Text variant="mediumStrong" sx={{ whiteSpace: 'nowrap' }}>
								Create Team
							</Text>
						</RouterButton>
					</Tooltip>
				}
			/>

			<DimensionAware>{({ width }) => <TeamContent width={width} page={page} teams={teams} />}</DimensionAware>
		</ContentWrapper>
	);
}
