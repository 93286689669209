/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { NotificationVO, SteadybitEventVO } from 'ui-api';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import cached from 'utils/cached';
import axios from 'axios';

import { EventsApi } from './eventsApi';

const relevantRefreshEvents = [
	'notification.created',
	'notification.updated',
	'notification.dismissed',
	'license.updated',
];

export class NotificationsApi {
	emptyNotifications: NotificationVO[] = [];

	events$: BehaviorSubject<SteadybitEventVO | null>;

	constructor(events: EventsApi) {
		this.events$ = new BehaviorSubject<SteadybitEventVO | null>(null);
		events.events$.pipe(filter((event) => relevantRefreshEvents.includes(event.type))).subscribe((event) => {
			this.getNotifications.invalidateCaches();
			this.events$.next(event);
		});
	}

	getNotifications = cached(this.getNotificationsInternal.bind(this));

	getGlobalNotifications$(): Observable<NotificationVO[]> {
		return this.events$.pipe(switchMap(() => from(this.getNotifications())));
	}

	private async getNotificationsInternal(): Promise<NotificationVO[]> {
		return (await axios.get('/ui/notifications')).data;
	}

	async dismiss(notification: NotificationVO): Promise<void> {
		await axios.post(`/ui/notifications/${notification.id}/dismiss`, notification);
	}
}
