/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, TargetTypeDescriptionVO, TemplatePreviewVO } from 'ui-api';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { Button, Skeletons, Stack, Tooltip } from 'components';
import { presets } from '@steadybit/ui-components-lib';
import React, { ReactElement, useState } from 'react';
import Markdown from 'components/Markdown/Markdown';

import ExperimentPreview from './ExperimentPreview';
import Targets from './Targets';
import Title from './Title';

interface TemplateCardProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	template: TemplatePreviewVO;
	actions: ActionVO[];
	disabled?: boolean;
	onDetailsClick: () => void;
	onUseClick?: () => void;
}

export default function TemplateCard({
	targetDefinitions,
	disabled = false,
	template,
	actions,
	onDetailsClick,
	onUseClick,
}: TemplateCardProps): ReactElement {
	const permissions = useGlobalPermissions();
	const canUseTemplate = permissions.templates.canUse;

	return (
		<CardWrapper
			onClick={(e) => {
				e.stopPropagation();
				onDetailsClick();
			}}
		>
			{({ hovered }) => (
				<>
					<Stack
						size="xSmall"
						sx={{
							maxHeight: '346px',
							overflow: 'hidden',
						}}
					>
						<ExperimentPreview lanes={template.lanes} actions={actions} disabled={disabled} />
						<presets.pill.Tags
							appearance="template"
							tags={
								template.tags.length > 5
									? [...template.tags.slice(0, 5), `+${template.tags.length - 5}`]
									: template.tags
							}
							small
						/>
						<Title title={template.templateTitle} />
						<VerticalScrollWrapper>
							<Markdown content={template.templateDescription} small />
						</VerticalScrollWrapper>
					</Stack>

					<Stack
						size="none"
						sx={{
							position: 'absolute',
							bottom: '0',
							left: '0',
							right: '0',
							backgroundColor: hovered ? 'purple100' : 'neutral000',
						}}
					>
						{/* shadow that covers the description */}
						<div
							style={{
								position: 'absolute',
								left: '0',
								right: '0',
								top: '-48px',
								height: '48px',
								background: hovered
									? 'linear-gradient(0deg, #F5F2FF 0%, #F5F2FF 10%, rgb(255 255 255 / 0%) 100%)'
									: 'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 10%, rgb(255 255 255 / 0%) 100%)',
							}}
						/>
						<Targets targets={template.targetTypes} targetDefinitions={targetDefinitions} />
						<Stack size="none" direction="horizontal" justifyContent="space-between" pt="xSmall">
							<Button
								variant="secondarySmall"
								onClick={(e) => {
									e.stopPropagation();
									onDetailsClick();
								}}
							>
								Details
							</Button>
							<Tooltip
								content={
									disabled || !canUseTemplate ? 'You need to be a member of the Team to use this template.' : undefined
								}
							>
								<Button
									variant="secondarySmall"
									disabled={disabled || !canUseTemplate}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										onUseClick?.();
									}}
									data-cy="use-template-button"
								>
									Use this template
								</Button>
							</Tooltip>
						</Stack>
					</Stack>
				</>
			)}
		</CardWrapper>
	);
}

export function LoadingCard(): ReactElement {
	return (
		<CardWrapper>
			{() => (
				<Stack justifyContent="space-between">
					<Skeletons height={122} widths={['100%']} />
					<Skeletons height={18} widths={['42px', '30px', '60px']} />
					<Skeletons height={22.5} widths={['200px', '160px']} />
					<Skeletons height={18} widths={['42px', '30px']} />
				</Stack>
			)}
		</CardWrapper>
	);
}

function CardWrapper({
	children,
	onClick,
}: {
	onClick?: React.MouseEventHandler;
	children: (params: { hovered: boolean }) => ReactElement | ReactElement[];
}): ReactElement {
	const [hovered, setHovered] = useState(false);
	return (
		<Stack
			size="small"
			sx={{
				justifyContent: 'space-between',

				p: 'small',
				borderRadius: '8px',
				border: '2px solid',
				borderColor: hovered ? 'slate' : 'neutral200',
				backgroundColor: hovered ? 'purple100' : 'neutral000',
				cursor: 'pointer',
			}}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			onClick={onClick}
		>
			<Stack
				size="small"
				sx={{
					position: 'relative',
					justifyContent: 'space-between',
					minWidth: '238px',
					maxWidth: '238px',
					minHeight: '389px',
					maxHeight: '389px',
					height: '100%',

					overflow: 'hidden',
				}}
				onClick={onClick}
			>
				{children({ hovered })}
			</Stack>
		</Stack>
	);
}

function VerticalScrollWrapper({ children }: { children: ReactElement | ReactElement[] }): ReactElement {
	return (
		<div
			style={{
				maxHeight: '100px',
				overflow: 'hidden',
			}}
		>
			{children}
		</div>
	);
}
