/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Button, Container, Divider, Li, ModalOverlay, Stack, Text, Tooltip, Ul } from 'components';
import { IconAdd, IconDelete, IconEdit, IconMetricQuery } from 'components/icons';
import { FieldVO, MetricCheckVO, MetricQueryVO } from 'ui-api';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import MetricQueryEditor, { MetricQueryEditorFormValues } from './MetricQueryEditor';
import EmptyStateWrapper from './EmptyStateWrapper';

interface MetricQueriesProps {
	metricQueryParameterDefinitions: FieldVO[];
	queries: MetricQueryVO[];
	checks: MetricCheckVO[];
	disabled: boolean;
	deleteQuery: (query: MetricQueryVO) => void;
	saveQuery: (query: MetricQueryVO) => void;
}

export default function MetricQueries({
	metricQueryParameterDefinitions,
	disabled,
	queries,
	checks,
	deleteQuery,
	saveQuery,
}: MetricQueriesProps): ReactElement | null {
	const [queryToEdit, setQueryToEdit] = useState<MetricQueryVO | null>(null);
	const usedQueryIds = getAllUsedQueryIds(checks);

	return (
		<>
			{queryToEdit && (
				<ModalOverlay open onClose={() => setQueryToEdit(null)}>
					{({ close }) => (
						<MetricQueryEditor
							metricQueryParameterDefinitions={metricQueryParameterDefinitions}
							query={queryToEdit}
							save={(formValues: MetricQueryEditorFormValues) => {
								saveQuery(formValues.query);
								setQueryToEdit(null);
							}}
							close={close}
						/>
					)}
				</ModalOverlay>
			)}
			<Stack size="medium" mt="xSmall">
				<Divider />
				{queries.length === 0 ? (
					<EmptyStateWrapper
						title="Metric Queries"
						description="You may define queries to gather metrics from a target system. Once gathered, these metrics are visualized during experiment executions. Additionally, you can use gathered metrics to define conditions under which an experiment should fail."
						icon={<IconMetricQuery minWidth={40} minHeight={40} color="slate" />}
					>
						<Button onClick={() => setQueryToEdit(createEmptyQuery())} disabled={disabled}>
							<IconAdd mr="xSmall" />
							Define Query
						</Button>
					</EmptyStateWrapper>
				) : (
					<Stack size="none">
						<Container
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							backgroundColor="neutral100"
							py="xSmall"
							pl="medium"
							pr="xSmall"
						>
							<Text variant="smallStrong">Queries</Text>
							<Button
								disabled={disabled}
								variant="chromelessSmall"
								color="slate"
								onClick={() => setQueryToEdit(createEmptyQuery())}
							>
								<IconAdd mr="xxSmall" /> Add new Query
							</Button>
						</Container>
						<Ul width="100%">
							{queries.map((query, index) => {
								const { id, label } = query;
								const isQueryUsed = usedQueryIds.has(id);

								return (
									<Li key={id} mb={index < queries.length - 1 ? 'xSmall' : undefined} mx="small" mt="small">
										<Container display="flex" alignItems="center" color="neutral700">
											<Text variant="small" sx={{ flexGrow: 1, mr: 'small', ...textEllipsis }}>
												{label}
											</Text>
											<Button
												variant="secondary"
												onClick={() => setQueryToEdit(query)}
												px="xSmall"
												mr="xSmall"
												minWidth={40}
											>
												<IconEdit disabled={disabled} minWidth={22} minHeight={22} />
											</Button>
											<Tooltip content={isQueryUsed ? 'Metric Query is used in a Check' : 'Delete Metric Query'}>
												<Button
													variant="secondary"
													onClick={() => deleteQuery(query)}
													px="xSmall"
													py="xSmall"
													disabled={isQueryUsed}
													minWidth={40}
												>
													<IconDelete minWidth={22} minHeight={22} />
												</Button>
											</Tooltip>
										</Container>
									</Li>
								);
							})}
						</Ul>
					</Stack>
				)}
				{queries.length > 0 && <Divider />}
			</Stack>
		</>
	);
}

function createEmptyQuery(): MetricQueryVO {
	return {
		id: uuidv4(),
		label: '',
		parameters: { query: '' },
	};
}

function getAllUsedQueryIds(checks: MetricCheckVO[]): Set<string> {
	const ids: Set<string> = new Set();
	for (let i = 0; i < checks.length; i++) {
		const { a, b } = checks[i];
		ids.add(a.metric.steadybit_metric_query_id);
		if (b && b.type === 'metric') {
			ids.add(b.metric.steadybit_metric_query_id);
		}
	}
	return ids;
}
