/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { Li, StyleProp, Text, Ul } from 'components';
import { ReactElement, ReactNode } from 'react';
import { theme } from 'styles.v2/theme';

const strictLiStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: 'xSmall',
	'& + li': {
		borderTop: '1px solid ' + theme.colors.neutral200,
	},
};

const strictLiStyleWithHover = {
	...strictLiStyle,
	':hover': {
		bg: 'neutral100',
		cursor: 'pointer',
	},
};

const looseLiStyle = {
	py: 'xSmall',
	px: 'xSmall',
	mx: 'xSmall',
	mb: 'xxxSmall',
	borderRadius: 4,
	bg: 'neutral000',
	color: 'neutral600',
	cursor: 'pointer',
};

const looseLiStyleWithHover = {
	...looseLiStyle,
	'&:hover': {
		bg: 'purple100',
		color: 'slate',
		textDecoration: 'none',
	},
};

const MAX_ITEMS = 200;

interface ItemListProps {
	highlightedIndices: (number | undefined)[];
	onHover?: (index: number) => void;
	onClick: (index: number) => void;
	onLoadMoreClick?: () => void;
	type: 'loose' | 'strict';
	width?: number | string;
	children: ReactNode[];
	totalItems?: number;
	maxItems?: number;
	sx?: StyleProp;
}

export default function ItemList({
	maxItems = MAX_ITEMS,
	highlightedIndices,
	onLoadMoreClick,
	totalItems,
	children,
	onClick,
	onHover,
	width,
	type,
	sx,
}: ItemListProps): ReactElement {
	totalItems = totalItems ?? children.length;
	return (
		<Ul width={width} maxWidth="100%">
			{children.slice(0, maxItems).map((child, i) => {
				const isHighlighted = highlightedIndices.includes(i);

				return (
					<Li
						key={i}
						id={`item-${i}`}
						onClick={() => onClick(i)}
						sx={{
							...(type === 'strict' ? strictLiStyleWithHover : looseLiStyleWithHover),
							...sx,
							...(type === 'loose'
								? {
										bg: isHighlighted ? 'purple100' : 'neutral000',
										':nth-of-type(even)': {
											bg: isHighlighted ? 'purple100' : 'neutral100',
											'&:hover': {
												bg: 'purple100',
												color: 'slate',
												textDecoration: 'none',
											},
										},
									}
								: {
										bg: isHighlighted ? 'neutral100' : 'neutral000',
									}),
						}}
						onMouseEnter={() => onHover?.(i)}
					>
						{child}
					</Li>
				);
			})}
			{totalItems > maxItems && (
				<Li
					sx={
						type === 'strict'
							? onLoadMoreClick
								? strictLiStyleWithHover
								: strictLiStyle
							: onLoadMoreClick
								? looseLiStyleWithHover
								: looseLiStyle
					}
					onClick={() => onLoadMoreClick?.()}
				>
					<Text color="neutral800" variant="small">
						{onLoadMoreClick ? 'Load more' : `${Math.min(maxItems, totalItems - maxItems)} more`}
					</Text>
				</Li>
			)}
		</Ul>
	);
}
