/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { EnvironmentSummaryVO } from 'ui-api';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useMemo } from 'react';
import { set } from 'lodash';

import { ExperimentFormValues } from '../types';

type CreateFunction = () => Promise<unknown>;

export default function useCreateExperimentFromScratch(newExperimentTags?: string[]): CreateFunction {
	const team = useTeam();

	const teamVOResult = usePromise(async () => await Services.teams.fetchTeam(team.id), [team.id]);

	const environmentResult = usePromise(async () => (await Services.environments.fetchEnvironments()).content, []);
	const environments = environmentResult.value || [];

	const initialValues: ExperimentFormValues = useMemo(() => {
		let selectableEnvironments: EnvironmentSummaryVO[] = [];
		if (!environmentResult.loading && environmentResult.value && !teamVOResult.loading && teamVOResult.value) {
			const isEnvironmentPermitted = (environmentId: string): boolean =>
				!!teamVOResult.value && teamVOResult.value.allowedEnvironments.includes(environmentId);
			selectableEnvironments = environments.filter((environment) => isEnvironmentPermitted(environment.id));
		}

		return {
			experimentKey: '',
			externalId: '',
			hypothesis: '',
			templateId: '',
			templateTitle: '',
			name: '',
			teamId: team.id,
			// Preselect environment if only one available
			environmentId: selectableEnvironments.length === 1 ? selectableEnvironments[0].id : '',
			lanes: [],
			webhookIds: [],
			variables: [],
			experimentVariables: [],
			tags: newExperimentTags ? newExperimentTags : [],
			creationMethod: 'UI_FROM_SCRATCH',
			actions: ['save', 'run-by-team'],
		};
	}, [environments, teamVOResult.value?.id]);

	return async () => {
		const violations = await Services.experiments.validateExperiment(initialValues);
		const initialErrors = {};
		violations.forEach(({ field, message }) => set(initialErrors, field, message));
		return {
			preparedFormData: {
				actions: ['save'],
				initialValues,
				initialErrors,
			},
		};
	};
}
