/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, ButtonIcon, CollapsibleV2, Snackbar, Spinner, Stack, Text, Toggle, userConfirmV2 } from 'components';
import { IconCalendar, IconDelete, IconDuplicate, IconRefresh } from 'components/icons';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import { ReactElement, useMemo, useState } from 'react';
import { isUserAdmin } from 'utils/user';

import { FilterDropDown, FilterDropDownListWrapper } from '../../../experiments/runs/FilterDropDown';
import { useEventEffect } from '../../../../utils/hooks/useEventEffect';
import { useAsyncState } from '../../../../utils/hooks/useAsyncState';
import { usePromise } from '../../../../utils/hooks/usePromise';
import { PageParams } from '../../../../utils/hooks/usePage';
import { useTenant } from '../../../../tenancy/useTenant';
import { Services } from '../../../../services/services';
import { Separator } from '../../../../hocs/Separator';
import { theme } from '../../../../styles.v2/theme';
import Teams from '../../../experiments/runs/Teams';
import { TeamVO } from '../../../../ui-api';

async function toggleIcalAccess(checked: boolean): Promise<void> {
	if (checked) {
		try {
			await Services.settingsApi.setIcalAccessEnabled(checked);
		} catch {
			Snackbar.error('Failed to toggle support access');
		}
	} else {
		await userConfirmV2({
			title: 'Disable iCal Access',
			message:
				'Do you really want to disable iCal Access? Existing integration will no longer be able to access the data. Disabling and enabling the access will change the URL. Make sure to update your calendar integrations.',
			actions: [
				{
					value: 'confirm',
					label: 'Disable',
					icon: <IconDelete mr="xSmall" />,
					variant: 'primary',
					action: async () => {
						await Services.settingsApi.setIcalAccessEnabled(checked);
					},
				},
			],
			secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
			width: '700px',
		});
	}
}

async function rotateIcalAccessUrl(): Promise<void> {
	await userConfirmV2({
		title: 'Rotate your iCal URL',
		message: (
			<Text variant="small">
				Are you sure you want to rotate your iCal URL?
				<br />
				<b>This will generate a new URL, breaking the connection for anyone who is using the old link.</b>
				<br />
				<br />
				This action cannot be undone.
			</Text>
		),
		actions: [
			{
				value: 'confirm',
				label: 'Rotate iCal URL',
				icon: <IconRefresh mr="xSmall" />,
				variant: 'primary',
				action: async () => {
					await Services.settingsApi.setIcalAccessEnabled(false);
					await Services.settingsApi.setIcalAccessEnabled(true);
				},
			},
		],
		secondaryActions: [{ value: 'cancel', label: 'Cancel' }],
		width: '700px',
	});
}

export default function IcalSettings(): ReactElement {
	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);
	const [icalAccessUrl, reloadIcalAccessUrl] = useAsyncState(() => Services.settingsApi.getIcalAccessUrl());
	const teamsResult = usePromise(() => Services.teams.fetchTeams(new PageParams(0, 1_000)), []);
	const teams = teamsResult.value?.content || [];
	const [includeExecutions, setIncludeExecutions] = useState(true);
	const [includeSchedules, setIncludeSchedules] = useState(true);
	const [teamIds, setTeamIds] = useState<string[] | undefined>(undefined);

	const defaultUrl = useMemo(() => {
		const params = new URLSearchParams();
		params.append('tenantKey', tenant.key);
		params.append('includeExecutions', 'true');
		params.append('includeSchedules', 'true');
		return `${icalAccessUrl.value}?${params.toString()}`;
	}, [icalAccessUrl.value]);
	const customisedUrl = useMemo(() => {
		if (!includeExecutions && !includeSchedules) {
			return 'You need to select executions, schedules or both';
		}
		if (teamIds != undefined && teamIds.length === 0) {
			return 'You need to select at least one team or deselect the team filter';
		}

		const params = new URLSearchParams();
		params.append('tenantKey', tenant.key);
		if (includeExecutions) {
			params.append('includeExecutions', 'true');
		}
		if (includeSchedules) {
			params.append('includeSchedules', 'true');
		}
		if (teamIds != undefined && teamIds.length > 0) {
			teamIds.forEach((teamId) => {
				const team = teams.find((team) => team.id === teamId);
				if (team) {
					params.append('team', team.key);
				}
			});
		}
		return `${icalAccessUrl.value}?${params.toString()}`;
	}, [icalAccessUrl.value, includeSchedules, includeExecutions, teamIds]);

	useEventEffect(reloadIcalAccessUrl, ['tenant.settings.updated'], () => {}, [reloadIcalAccessUrl]);

	return (
		<>
			<Stack minHeight={'100%'} size={'large'} m={'xLarge'}>
				<Stack size="small">
					<Stack direction="horizontal" size="xSmall" alignItems="center">
						<IconCalendar variant="large" color="slate" />
						<Text variant="mediumStrong">iCal Access</Text>
					</Stack>
					<Text variant="medium" color="neutral600">
						Allow access to your experiment executions and/or experiment schedules via iCal. This will create a unique
						URL providing a read-only iCal feed which you can use to sync external calendars. Once you activated that
						feature, you can use the link generator to configure your feed.
					</Text>
					<Stack
						direction="vertical"
						sx={{
							backgroundColor: 'neutral100',
							borderRadius: '8px',
							p: 'small',
						}}
					>
						<Stack direction="horizontal" size="xSmall" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
							<Text variant="mediumStrong" color="neutral800">
								Enable iCal Access
							</Text>
							{icalAccessUrl.loading ? (
								<Spinner variant="medium" color="neutral300" alignSelf={'flex-start'} />
							) : (
								<Toggle
									disabled={!isAdmin}
									checked={!!icalAccessUrl.value?.length}
									onChange={(e) => toggleIcalAccess(e.target.checked)}
								/>
							)}
						</Stack>
						{icalAccessUrl.value && (
							<>
								<Stack direction="horizontal" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
									<Text variant="smallStrong" color="neutral600">
										Default iCal URL
									</Text>
									<ReactCopyToClipboard text={defaultUrl} onCopy={() => Snackbar.dark('Copied!')}>
										<ButtonIcon
											variant="small"
											tooltip="Copy to clipboard"
											sx={{ background: 'neutral100', width: '110px' }}
										>
											<IconDuplicate mr={'xxSmall'} />
											<Text variant="small"> Copy URL</Text>
										</ButtonIcon>
									</ReactCopyToClipboard>
								</Stack>

								<Text variant="smallCode" color="neutral600" sx={{ lineBreak: 'anywhere' }}>
									{defaultUrl}
								</Text>
								<CollapsibleV2
									title="Customize URL"
									backgroundColor={theme.colors.neutral000}
									backgroundColorExpanded={theme.colors.neutral000}
									borderless
								>
									<Stack>
										<Stack
											direction="horizontal"
											size="xSmall"
											sx={{
												alignItems: 'center',
												justifyContent: 'space-between',
												backgroundColor: 'neutral100',
												borderRadius: '8px',
												p: 'small',
											}}
										>
											<Text variant="smallStrong" color="neutral800">
												Include past experiment executions (1 month)
											</Text>
											<Toggle checked={includeExecutions} onChange={(e) => setIncludeExecutions(e.target.checked)} />
										</Stack>
										<Stack
											direction="horizontal"
											size="xSmall"
											sx={{
												alignItems: 'center',
												justifyContent: 'space-between',
												backgroundColor: 'neutral100',
												borderRadius: '8px',
												p: 'small',
											}}
										>
											<Text variant="smallStrong" color="neutral800">
												Include future experiment schedules (1 month)
											</Text>
											<Toggle checked={includeSchedules} onChange={(e) => setIncludeSchedules(e.target.checked)} />
										</Stack>
										<Stack sx={{ backgroundColor: 'neutral100', p: 'small' }}>
											<Stack
												direction="horizontal"
												size="xSmall"
												sx={{ alignItems: 'center', justifyContent: 'space-between' }}
											>
												<Text variant="smallStrong" color="neutral800">
													Filter per Team
												</Text>
												<Toggle
													checked={teamIds !== undefined}
													onChange={(e) => setTeamIds(e.target.checked ? [] : undefined)}
												/>
											</Stack>
											{teamIds && (
												<FilterDropDown
													placeholder="Type to search"
													loading={teamsResult.loading}
													name="Team"
													label={teamIds
														.map((teamId) => teams.find((team) => team.id === teamId))
														.filter((team): team is TeamVO => !!team)
														.sort((a, b) => a.name.localeCompare(b.name))
														.map((team) => team.name)
														.concat(teamIds.filter((teamId) => teams.find((team) => team.id === teamId) === undefined))
														.join(', ')}
													clearAll={() => setTeamIds([])}
												>
													{({ value, width }) => (
														<FilterDropDownListWrapper width={width}>
															<Teams
																selectedTeamIds={teamIds}
																teams={teams}
																value={value}
																width={width}
																toggleId={(_teamId) => {
																	setTeamIds(
																		teamIds.indexOf(_teamId) !== -1
																			? teamIds.filter((id) => id !== _teamId)
																			: [...teamIds, _teamId],
																	);
																}}
																selectIds={(_teamIds) => {
																	setTeamIds(Array.from(new Set([...teamIds, ..._teamIds])));
																}}
																deselectIds={(_teamIds) => {
																	setTeamIds(teamIds.filter((id) => !_teamIds.includes(id)));
																}}
															/>
														</FilterDropDownListWrapper>
													)}
												</FilterDropDown>
											)}
										</Stack>
										<Stack direction="horizontal" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
											<Text variant="smallStrong" color="neutral600">
												New customised iCal URL
											</Text>
											<ReactCopyToClipboard text={customisedUrl} onCopy={() => Snackbar.dark('Copied!')}>
												<ButtonIcon
													variant="small"
													tooltip="Copy to clipboard"
													sx={{ background: 'neutral100', width: '110px' }}
													disabled={!customisedUrl.startsWith('http')}
												>
													<IconDuplicate mr={'xxSmall'} />
													<Text variant="small"> Copy URL</Text>
												</ButtonIcon>
											</ReactCopyToClipboard>
										</Stack>
										<Text
											variant="smallCode"
											color={customisedUrl.startsWith('http') ? 'neutral600' : 'errorDark'}
											sx={{ lineBreak: 'anywhere' }}
										>
											{customisedUrl}
										</Text>
									</Stack>
								</CollapsibleV2>
								<Separator />
								<Text variant="smallStrong" color="neutral800">
									Rotate your iCal URL
								</Text>
								<Text variant="medium" color="neutral600">
									To prevent unauthorised access, you can rotate the iCal URL. This will generate a new URL,{' '}
									<b>breaking the connection for anyone who is using the old link</b>. Use this feature only if you want
									to start a new calendar cycle or limit access.
								</Text>
								<Button
									variant={'secondarySmall'}
									width={160}
									disabled={!isAdmin}
									onClick={async () => await rotateIcalAccessUrl()}
								>
									<IconRefresh mr="xSmall" ml="-xxSmall" />
									<Text variant="smallStrong" sx={{ whiteSpace: 'nowrap' }}>
										Rotate iCal URL
									</Text>
								</Button>
							</>
						)}
					</Stack>
				</Stack>
			</Stack>
		</>
	);
}
