/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { IconCheck, IconClose, IconComponent, IconMessage, IconWarning, IconWarningCircle } from 'components/icons';
import { ButtonIcon, StyleProp, Tooltip } from 'components';
import { formatDateWithTime } from 'utils/dateFns';
import { Container } from 'components/Container';
import { ReactElement, useState } from 'react';
import { get, set } from 'utils/localStorage';
import { useTenant } from 'tenancy/useTenant';
import { Stack } from 'components/Stack';
import { theme } from 'styles.v2/theme';
import { Text } from 'components/Text';
import { TenantVO } from 'ui-api';

import { customPropertyName } from './BannerAccent';

export type BannerVariant = 'warning' | 'danger' | 'info' | 'success';

interface BannerSnoozingConfig {
	id: string;
	end: Date;
}

interface BannerProps {
	variant: BannerVariant;
	Icon?: IconComponent;
	title: string;
	description: string | ReactElement;
	snooze?: BannerSnoozingConfig;
	dismissible?: boolean;
	sx?: StyleProp;
	id?: string;
	leftAligned?: boolean;
	onSnoozeCallback?: () => void;
}

interface BannerVariantTheme {
	accent: string;
	backgroundColor: string;
	Icon: IconComponent;
}

interface DismissButtonProps {
	tenant: TenantVO;
	snooze?: BannerSnoozingConfig;
	onSnoozeCallback?: () => void;
}

export const bannerVariantThemes: Record<BannerVariant, BannerVariantTheme> = {
	warning: {
		accent: theme.colors.experimentWarning,
		backgroundColor: theme.colors.feedbackWarningLightPill,
		Icon: IconWarning,
	},
	danger: {
		accent: theme.colors.feedbackErrorDark,
		backgroundColor: theme.colors.feedbackErrorLight,
		Icon: IconWarningCircle,
	},
	info: {
		accent: theme.colors.neutral700,
		backgroundColor: theme.colors.neutral200,
		Icon: IconMessage,
	},
	success: {
		accent: theme.colors.successDark,
		backgroundColor: theme.colors.successLight,
		Icon: IconCheck,
	},
};

export function Banner({
	id,
	Icon,
	title,
	description,
	snooze,
	variant,
	onSnoozeCallback,
	leftAligned,
	sx = {},
	dismissible = snooze !== undefined,
}: BannerProps): ReactElement | null {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, refresh] = useState(Date.now());

	const tenant = useTenant();
	const variantTheme = bannerVariantThemes[variant];

	if (snooze && isSnoozed(tenant.key, snooze)) {
		return null;
	}

	Icon = Icon ?? variantTheme.Icon;

	return (
		<Container
			id={id}
			as="section"
			sx={{
				position: 'relative',

				display: 'flex',
				justifyContent: leftAligned ? 'flex-start' : 'center',
				alignItems: 'center',

				minHeight: '40px',
				px: 'small',
				minWidth: 'fit-content',
				overflow: 'hidden',

				backgroundColor: variantTheme.backgroundColor,
				borderRightWidth: '8px',
				borderRightStyle: 'solid',
				borderColor: variantTheme.accent,
				color: theme.colors.neutral800,

				...sx,
			}}
		>
			<Stack
				direction="horizontal"
				size="xSmall"
				alignItems="center"
				style={{ [customPropertyName]: variantTheme.accent } as React.CSSProperties}
			>
				<Icon
					color={variantTheme.accent}
					sx={{
						minWidth: '20px',
						minHeight: '20px',
					}}
				/>
				{title && (
					<Text as="span" variant="mediumStrong" color={variantTheme.accent} sx={{ whiteSpace: 'nowrap' }}>
						{title}
					</Text>
				)}
				{title && (
					<Text as="span" sx={{ whiteSpace: 'nowrap' }}>
						-
					</Text>
				)}
				<Text as="span" sx={{ whiteSpace: 'nowrap' }}>
					{description}
				</Text>
			</Stack>

			{dismissible && (!snooze || snooze.end.getTime() > Date.now()) && (
				<DismissButton
					tenant={tenant}
					snooze={snooze}
					onSnoozeCallback={() => {
						onSnoozeCallback?.();
						refresh(Date.now());
					}}
				/>
			)}
		</Container>
	);
}

function DismissButton({ tenant, snooze, onSnoozeCallback }: DismissButtonProps): ReactElement {
	const dismissButton = (
		<ButtonIcon
			sx={{ position: 'absolute', top: 5, right: 5 }}
			variant="xSmall"
			color="neutral900"
			onClick={() => {
				if (snooze) {
					onSnooze(tenant.key, snooze);
				}
				onSnoozeCallback?.();
			}}
		>
			<IconClose />
		</ButtonIcon>
	);
	if (snooze) {
		return <Tooltip content={`Dismiss until ${formatDateWithTime(snooze.end)}`}>{dismissButton}</Tooltip>;
	}
	return dismissButton;
}

function onSnooze(tenantKey: string, snooze: BannerSnoozingConfig): void {
	set(tenantKey, `snooze-${snooze.id}`, snooze.end.toISOString());
}

function isSnoozed(tenantKey: string, snooze: BannerSnoozingConfig): boolean {
	const value = get(tenantKey, `snooze-${snooze.id}`);

	if (value == null) {
		return false;
	}

	return new Date(value).getTime() > Date.now();
}
