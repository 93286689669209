/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { localeCompareIgnoreCase } from 'utils/string';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';

interface TeamSelectionProps {
	myTeamsOnly?: boolean;
	includeAny?: boolean;
	disabled?: boolean;
	small?: boolean;
	value?: string;
	onChange: (value: string) => void;
}

export default function TeamSelection({
	myTeamsOnly = false,
	includeAny = false,
	disabled = false,
	value,
	small,
	onChange,
}: TeamSelectionProps): ReactElement {
	const teamsResult = usePromise(() => Services.teams.findTeams('', myTeamsOnly), [myTeamsOnly]);
	const teams =
		teamsResult.value
			?.sort((a, b) => localeCompareIgnoreCase(a.name, b.name))
			.map((t) => ({
				id: t.id,
				label: `${t.name} (${t.key})`,
				isSelected: t.id === value,
			})) ?? [];
	if (includeAny) {
		teams.unshift({ id: '', label: 'Any', isSelected: !value });
	}
	const selectedTeam = teams.find((t) => t.id === value);

	return (
		<presets.dropdown.SingleChoiceButton
			size={small ? 'small' : 'medium'}
			maxContentHeight="400px"
			disabled={disabled}
			selectedId={value}
			items={teams}
			onSelect={onChange}
			style={{ width: '100%' }}
			data-cy="team-select"
		>
			{selectedTeam?.label || 'none'}
		</presets.dropdown.SingleChoiceButton>
	);
}
