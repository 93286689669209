/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, presets } from '@steadybit/ui-components-lib';
import { TargetTypeDescriptionVO } from 'ui-api';
import { Text, Tooltip } from 'components';
import { ReactElement } from 'react';

interface TargetsProps {
	targetDefinitions: TargetTypeDescriptionVO[];
	targets: string[];
}

export default function Targets({ targets, targetDefinitions }: TargetsProps): ReactElement | null {
	if (targets.length === 0) {
		return null;
	}

	return (
		<div
			style={{
				display: 'grid',
				gridTemplateColumns: '47px calc(100% - 47px)',
				alignItems: 'center',
			}}
		>
			<Text variant="xSmallStrong" color="neutral600" mt="4px">
				Targets:
			</Text>

			<Flex direction="horizontal" spacing="xSmall">
				{targets.map((target, i) => {
					const resolvedTargetDefinition = targetDefinitions.find((targetDefinition) => targetDefinition.id === target);
					return (
						<Tooltip key={i} content={resolvedTargetDefinition?.label.one || ''}>
							<div>
								<presets.pill.TargetTypeTag key={i} icon={resolvedTargetDefinition?.icon}>
									{''}
								</presets.pill.TargetTypeTag>
							</div>
						</Tooltip>
					);
				})}
			</Flex>
		</div>
	);
}
