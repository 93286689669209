/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	LandscapeTargetDescriptionVO,
	LandscapeViewGroupConfigVO,
	LandscapeViewMappedGroupingVO,
	LandscapeViewVO,
} from 'ui-api';

export type Environment = string;

export type Filtering = string;

export type Sizing = 'none' | 'attributes' | string;

export type Coloring = string;

export type Group = string;
export type Grouping = Array<Group>;

export type GroupConfig = LandscapeViewGroupConfigVO;
export type ColorConfig = {
	mappings: LandscapeViewMappedGroupingVO[];
};

export type ColorOverrides = { [index: string]: string };

export interface Landscape {
	targets: LandscapeTargetBase[];
	icons: LandscapeTargetDescriptionVO[];
}
export interface LandscapeTargetAttribute {
	key: string;
	value: string;
}

export interface LandscapeTargetBase {
	type: string;
	name: string;
	attributes: LandscapeTargetAttribute[];
	adviceDone: number;
	adviceRequireAction: number;
	adviceRequireValidation: number;
}

export interface LandscapeTarget extends LandscapeTargetBase {
	id: string;
	color: [number, number, number];
	size: number;
	parent?: LandscapeGroupWithTargets;
	colorByValue?: string;
	resolvedTypeLabel?: string;
	duplicatedByGroupings?: LandscapeTargetAttribute[];
	assignedGrouping?: LandscapeTargetAttribute;
	duplicatedByColor?: boolean;
}

interface LandscapeGroupBase {
	id: string;
	label: string;
	depth: number;
	color: [number, number, number];
	parent: LandscapeGroup | null;
	adviceRequireValidation: number;
	adviceRequireAction: number;
	adviceDone: number;
}
interface LandscapeGroupWithSubgroups extends LandscapeGroupBase {
	groups: LandscapeGroup[];
	targets?: never;
}
export interface LandscapeGroupWithTargets extends LandscapeGroupBase {
	groups?: never;
	targets: LandscapeTarget[];
	totalUniqueTargets?: number;
}
export type LandscapeGroup = LandscapeGroupWithSubgroups | LandscapeGroupWithTargets;
export function isLandscapeGroupWithTargets(entity: LandscapeGroup): entity is LandscapeGroupWithTargets {
	return !!entity && (entity as LandscapeGroupWithTargets).totalUniqueTargets !== undefined;
}

type ViewAction = 'create' | 'update' | 'delete';

export interface LandscapeConfig extends LandscapeViewVO {
	colorMapping: ColorMapping | null;
	latestView: LandscapeViewVO;
	reloadSignal: number;
	action?: ViewAction;
	showAdvice: boolean;
	isNew: boolean;
}

export interface ColorListEntry {
	label: string;
	color: [number, number, number];
}
export interface ColorMapping {
	colorList: Array<ColorListEntry>;
	colorAsMap: { [index: string]: [number, number, number] };
	attributeValueToBucketName: { [index: string]: string };
}

export interface NumberOfItems {
	targets: number;
	groups: number;
}
