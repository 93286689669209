/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import PredicateEditor from 'components/PredicateEditor/PredicateEditor';
import { IconEnvironment } from 'components/icons';
import { Message, Stack, Text } from 'components';
import { TargetPredicateVO } from 'ui-api';
import { ReactElement } from 'react';
import { useField } from 'formik';

interface SidebarPredicateModuleProps {
	readonly?: boolean;
	isGlobalEnvironment?: boolean;
}

export default function SidebarPredicateModule({
	readonly,
	isGlobalEnvironment = false,
}: SidebarPredicateModuleProps): ReactElement {
	const [field, , { setValue, setTouched }] = useField<TargetPredicateVO>('predicate');

	return (
		<SidebarPredicateModuleRenderer
			predicate={field.value}
			readonly={readonly}
			isGlobalEnvironment={isGlobalEnvironment}
			setPredicate={(_predicate) => {
				setValue(
					_predicate || {
						predicates: [],
						operator: 'AND',
					},
				);
				setTouched(true);
			}}
		/>
	);
}

interface SidebarPredicateModuleRendererProps {
	setPredicate: (predicate: TargetPredicateVO | undefined) => void;
	predicate: TargetPredicateVO | undefined;
	disabled?: boolean;
	readonly?: boolean;
	isGlobalEnvironment: boolean;
}

function SidebarPredicateModuleRenderer({
	setPredicate,
	predicate,
	readonly,
	isGlobalEnvironment,
	disabled,
}: SidebarPredicateModuleRendererProps): ReactElement {
	return (
		<Stack>
			<Stack direction="horizontal" size="xxSmall">
				<IconEnvironment />
				<Text variant="mediumStrong">
					{readonly || isGlobalEnvironment ? 'Your Environment Scope' : 'Define your Environment Scope'}
				</Text>
			</Stack>
			{readonly || isGlobalEnvironment ? (
				<Text variant="small" color="neutral800">
					The environment scope defines the Targets that will be available for an Experiment or the Explorer.
				</Text>
			) : (
				<Text variant="small" color="neutral800">
					Define the Targets that will be available for an Experiment or the Explorer.
				</Text>
			)}

			{isGlobalEnvironment ? (
				<Message variant={'info'}>
					The scope of the &apos;Global&apos; environment always includes all discovered targets.
					<br />
					If you want to limit the permitted targets, create a new environment.
				</Message>
			) : (
				<Text variant="small" color="neutral800">
					The table on the right shows the included Targets accordingly to your Query.
				</Text>
			)}

			{!isGlobalEnvironment && (
				<PredicateEditor
					predicate={predicate}
					setPredicate={setPredicate}
					disabled={disabled || readonly}
					autoFocus={false}
				/>
			)}
		</Stack>
	);
}
