/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	ButtonIcon,
	Heading,
	Link,
	ModalContentV2,
	ModalFooterV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TextField,
	Tooltip,
} from 'components';
import {
	IconDelete,
	IconEnvironment,
	IconExperiment,
	IconInformation,
	IconSettings,
	IconWarningCircle,
} from 'components/icons';
import { replaceLaneMarker, wrapWithEnvironmentVariableMarkers } from 'pages/templates/utils';
import { KeyValuePair } from 'components/KeyValueListInput/KeyValueListInput';
import { ErrorMessage, Flex, Grid, Text } from '@steadybit/ui-components-lib';
import PlaceholderMarker from 'pages/templates/components/PlaceholderMarker';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { ExperimentError } from 'pages/experimentsV2/types';
import { localeCompare } from 'utils/string';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { VariableVO } from 'ui-api';
import { ampli } from 'ampli';

import { ExperimentFormValues } from '../../experiment';

interface ExperimentAndEnvironmentVariablesProps {
	environmentVariables: VariableVO[];
	setIsDeletingVariable: (isDeleting: boolean) => void;
}

interface VariableError {
	key: ExperimentError | undefined;
	value: ExperimentError | undefined;
}

export default function ExperimentAndEnvironmentVariables({
	environmentVariables,
	setIsDeletingVariable,
}: ExperimentAndEnvironmentVariablesProps): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();

	const experimentVariables = formik.values.experimentVariables;
	const experimentVariablesErrors = formik.errors.experimentVariables as Array<VariableError> | undefined;
	const experimentVariablesError = formik.errors.overallExperimentVariables as ExperimentError | undefined;

	useEffect(() => {
		ampli.experimentVariableListViewed({
			experiment_key: formik.values.experimentKey,
			experiment_variables: experimentVariables.map((v) => v.key),
			environment_variables: environmentVariables.map((v) => v.key),
		});
	}, []);

	return (
		<Flex
			spacing="xSmall"
			style={{ p: 'small', minWidth: '600px', maxHeight: 'calc(100vh - 180px)', overflowY: 'auto' }}
		>
			<Heading variant="small">Variables</Heading>

			<Flex direction="horizontal" align="center" spacing="xSmall" style={{ paddingTop: '12px' }}>
				<IconExperiment variant="small" />
				<Text type="small">Experiment variables</Text>
			</Flex>

			<Flex spacing="xSmall">
				{experimentVariables.map((variable, index) => (
					<EnvironmentVariable
						error={experimentVariablesErrors?.[index]}
						experimentVariables={experimentVariables.filter((_, i) => i !== index)}
						environmentVariables={environmentVariables}
						variable={variable}
						key={variable.key + index}
						setVariable={(_variable) => {
							const copied = [...experimentVariables];
							const oldVariable = copied[index];
							copied[index] = _variable;

							formik.setFieldValue('experimentVariables', copied);
							formik.setFieldTouched('experimentVariables', true);

							if (oldVariable.key !== _variable.key) {
								const oldKey = wrapWithEnvironmentVariableMarkers(oldVariable.key);
								const newKey = wrapWithEnvironmentVariableMarkers(_variable.key);
								const newLanes = replaceLaneMarker(formik.values.lanes, oldKey, newKey);
								formik.setFieldValue('lanes', newLanes);
							}
						}}
						onDelete={({ shadowsEnvironmentVariable }) => {
							formik.setFieldValue(
								'experimentVariables',
								experimentVariables.filter((v) => v.key !== variable.key),
							);
							formik.setFieldTouched('experimentVariables', true);

							if (!shadowsEnvironmentVariable) {
								const key = wrapWithEnvironmentVariableMarkers(variable.key);
								const newLanes = replaceLaneMarker(formik.values.lanes, key, variable.value);
								formik.setFieldValue('lanes', newLanes);
								formik.setFieldTouched('lanes', true);
							}

							ampli.experimentVariableDeleted({
								experiment_key: formik.values.experimentKey,
								experiment_variable: variable.key,
								variable_shadowing: shadowsEnvironmentVariable,
							});
						}}
						setIsDeletingVariable={setIsDeletingVariable}
					/>
				))}
				<EnvironmentVariable
					environmentVariables={environmentVariables}
					experimentVariables={experimentVariables}
					key={'new-' + experimentVariables.length}
					variable={{ key: '', value: '' }}
					setVariable={(_variable) => {
						const copied = experimentVariables.slice();
						copied.push(_variable);
						formik.setFieldValue('experimentVariables', copied);
						formik.setFieldTouched('experimentVariables', true);
					}}
					setIsDeletingVariable={setIsDeletingVariable}
				/>
				{experimentVariablesError && (
					<ErrorMessage withIcon type="small" level={experimentVariablesError.level}>
						{experimentVariablesError.message}
					</ErrorMessage>
				)}
			</Flex>

			<Flex
				direction="horizontal"
				align="center"
				spacing="xSmall"
				justify="spread"
				style={{ width: '100%', paddingTop: '24px' }}
			>
				<Flex direction="horizontal" align="center" spacing="xSmall">
					<IconEnvironment variant="small" />
					<Text type="small">Environment variables</Text>
				</Flex>
				<Link href={`/settings/environments/${formik.values.environmentId}/variables`} external>
					<Button variant="chromeless" color="neutral600">
						<IconSettings mr="xxSmall" />
						Manage variables for this environment
					</Button>
				</Link>
			</Flex>

			<Table width="100%">
				<TableHead>
					<TableRow>
						<TableHeadCell width="auto">Key</TableHeadCell>
						<TableHeadCell width="auto">Value</TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{environmentVariables
						.slice()
						.sort((v1, v2) => localeCompare(v1.key, v2.key))
						.map((variable) => (
							<TableRow key={variable.key}>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.key}
									</Text>
								</TableDataCell>
								<TableDataCell sx={{ minHeight: '32px !important' }}>
									<Text textEllipsis type="small">
										{variable.value}
									</Text>
								</TableDataCell>
							</TableRow>
						))}
					{environmentVariables.length === 0 && (
						<TableRow>
							<TableDataCell colSpan={3} sx={{ minHeight: '42px !important' }}>
								<Text style={{ color: theme.colors.neutral600 }}>No variables found.</Text>
							</TableDataCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Flex>
	);
}

interface EnvironmentVariableProps {
	environmentVariables: VariableVO[];
	experimentVariables: VariableVO[];
	variable: KeyValuePair;
	error?: VariableError;
	onDelete?: (p: { shadowsEnvironmentVariable: boolean }) => void;
	setIsDeletingVariable: (isDeleting: boolean) => void;
	setVariable: (v: KeyValuePair) => void;
}

function EnvironmentVariable({
	environmentVariables,
	experimentVariables,
	variable,
	error,
	setIsDeletingVariable,
	setVariable,
	onDelete,
}: EnvironmentVariableProps): ReactElement {
	const [key, setKey] = useState(variable.key);
	const [value, setValue] = useState(variable.value);
	const [isKeyEditing, setIsKeyEditing] = useState(false);
	const [isValueEditing, setIsValueEditing] = useState(false);

	const isKeyErrorneous: boolean = !!error?.key;
	const isValueErrorneous: boolean = !!error?.value || (key && !value ? true : false);

	const shadowsEnvironmentVariable = environmentVariables.find((v) => v.key === key);
	const shadowsExperimentVariable = !!experimentVariables.find((v) => v.key === key);

	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		return () => {
			setIsDeletingVariable(false);
		};
	}, []);

	return (
		<Flex spacing="xSmall">
			{isDeleting && onDelete && (
				<DeleteShadowingVariableMessage
					variable={variable}
					evironmentVariableValue={shadowsEnvironmentVariable?.value || ''}
					onClose={() => {
						setIsDeleting(false);
						setIsDeletingVariable(false);
					}}
					onDelete={() => onDelete({ shadowsEnvironmentVariable: !!shadowsEnvironmentVariable })}
				/>
			)}

			<Grid cols="2fr 3fr 40px" spacing="xSmall" align="center">
				{/* Key */}
				<Flex direction="horizontal" style={{ width: '100%' }}>
					<PlaceholderMarker marker="{{" left small />
					<Tooltip
						content={shadowsExperimentVariable ? 'This key is already used.' : variable.key}
						onlyShowOnEllipsis={shadowsExperimentVariable ? false : true}
					>
						<TextField
							value={key}
							type="text"
							placeholder="Key"
							hasError={isKeyErrorneous}
							iconRight={
								shadowsEnvironmentVariable && !isKeyEditing && !isValueEditing
									? IconInformation
									: shadowsExperimentVariable
										? IconWarningCircle
										: undefined
							}
							iconRightTooltip={
								shadowsExperimentVariable
									? 'This key is already used.'
									: 'This experiment variable will shadow an environment variable withg the same key.'
							}
							iconRightColor={shadowsExperimentVariable ? 'coral' : 'slate'}
							onChange={(e) => setKey(e.target.value)}
							onFocus={() => setIsKeyEditing(true)}
							onBlur={() => {
								setIsKeyEditing(false);
								if (value && !shadowsExperimentVariable) {
									setVariable({ key, value });
								}
							}}
							wrapperSx={{
								height: '32px',
							}}
							sx={{
								borderRadius: 0,
								borderLeft:
									isKeyErrorneous || shadowsExperimentVariable ? '1px solid ' + theme.colors.coral : '1px solid white',
								borderRight:
									isKeyErrorneous || shadowsExperimentVariable ? '1px solid ' + theme.colors.coral : '1px solid white',
								borderTopColor: isKeyErrorneous || shadowsExperimentVariable ? 'coral' : 'neutral300',
								borderBottomColor: isKeyErrorneous || shadowsExperimentVariable ? 'coral' : 'neutral300',
								px: '6px',
							}}
							style={{
								fontSize: '14px',
							}}
						/>
					</Tooltip>
					<PlaceholderMarker marker="}}" small />
				</Flex>

				{/* Value */}
				<Tooltip content={variable.value} onlyShowOnEllipsis>
					<TextField
						hasError={isValueErrorneous}
						value={value}
						type="text"
						placeholder="Value"
						iconRight={isValueErrorneous ? IconWarningCircle : undefined}
						iconRightColor="coral"
						iconRightTooltip="The value cannot be empty"
						onChange={(e) => setValue(e.target.value)}
						onFocus={() => setIsValueEditing(true)}
						onBlur={() => {
							setIsValueEditing(false);
							if (key && value && !shadowsExperimentVariable) {
								setVariable({ key, value });
							}
						}}
						wrapperSx={{
							height: '32px',
						}}
						sx={{
							px: '6px',
						}}
						style={{
							fontSize: '14px',
						}}
					/>
				</Tooltip>

				{onDelete && (
					<ButtonIcon
						variant="small"
						muted
						color="neutral600"
						onClick={() => {
							if (shadowsEnvironmentVariable) {
								setIsDeleting(true);
								setIsDeletingVariable(true);
							} else {
								onDelete({ shadowsEnvironmentVariable: false });
							}
						}}
						tooltip="Delete experiment variable"
					>
						<IconDelete />
					</ButtonIcon>
				)}
			</Grid>
			{shadowsExperimentVariable && isKeyEditing && (
				<ErrorMessage type="small" withIcon>
					This key is already used.
				</ErrorMessage>
			)}
			{isValueErrorneous && isValueEditing && (
				<ErrorMessage type="small" withIcon>
					Enter a value for the variable.
				</ErrorMessage>
			)}
			{error && !isKeyEditing && !isValueEditing && (
				<ErrorBoundary>
					{error.key && (
						<ErrorMessage type="small" withIcon>
							{error.key.message}
						</ErrorMessage>
					)}
					{error.value && (
						<ErrorMessage type="small" withIcon>
							{error.value.message}
						</ErrorMessage>
					)}
				</ErrorBoundary>
			)}
			{shadowsEnvironmentVariable && (isKeyEditing || isValueEditing) && (
				<Flex
					direction="horizontal"
					spacing="xSmall"
					align="center"
					style={{
						p: 'xSmall',
						color: theme.colors.slate,
						backgroundColor: theme.colors.purple100,
						borderRadius: 'xxSmall',
						width: 'calc(100% - 48px)',
					}}
				>
					<IconInformation />
					<Text type="small">This experiment variable will shadow an environment variable withg the same key.</Text>
				</Flex>
			)}
		</Flex>
	);
}

interface AddMessageProps {
	evironmentVariableValue: string;
	variable: KeyValuePair;
	onClose: () => void;
	onDelete: () => void;
}

function DeleteShadowingVariableMessage({
	evironmentVariableValue,
	variable,
	onDelete,
	onClose,
}: AddMessageProps): ReactElement {
	return (
		<ModalOverlay open onClose={onClose} zIndex={42}>
			{({ close }) => {
				return (
					<ModalV2 width="100%" maxWidth="864px" slick centered withFooter>
						<ModalHeaderV2 title="Delete experiment variable and use environment variable?" onClose={close} />
						<ModalContentV2>
							<Flex spacing="small" style={{ color: theme.colors.neutral600 }}>
								<Text type="medium">
									There is an <Text type="mediumStrong">environment</Text> variable with the same key as the experiment
									variable that you are about to delete.
								</Text>

								<Text type="medium">
									After deleting the experiment variable, any reference to the key{' '}
									<Text type="mediumStrong">{variable.key}</Text> will resolve to the value defined for the selected
									environment (<Text type="mediumStrong">{evironmentVariableValue}</Text>).
								</Text>

								<Text type="medium">Do you want to continue?</Text>
							</Flex>
						</ModalContentV2>
						<ModalFooterV2>
							<Flex direction="horizontal" justify="spread" style={{ width: '100%', px: 'small', pb: 'small' }}>
								<Button onClick={onClose} variant="secondary">
									Cancel
								</Button>
								<Button onClick={onDelete}>Delete experiment variable</Button>
							</Flex>
						</ModalFooterV2>
					</ModalV2>
				);
			}}
		</ModalOverlay>
	);
}
