/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useAsyncState } from 'utils/hooks/useAsyncState';
import { presets } from '@steadybit/ui-components-lib';
import { Services } from 'services/services';
import { ReactElement } from 'react';

export function TenantMenu({ onSelect }: { onSelect: (key: string) => void }): ReactElement | null {
	const [tenants] = useAsyncState(() => Services.tenants.fetchTenants(), [], []);

	if (tenants.loading) {
		return null;
	}

	return (
		<presets.dropdown.DropdownContentFrame>
			<presets.dropdown.SingleChoiceList
				items={tenants.value.map((tenant) => ({ id: tenant.key, label: tenant.name }))}
				onSelect={onSelect}
			/>
		</presets.dropdown.DropdownContentFrame>
	);
}
