/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	Container,
	Link,
	Pagination,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
} from 'components';
import ListHeaderSearch from 'components/List/presets/ListHeaderSearch';
import EmptyListContent from 'components/List/EmptyListContent';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { TargetAttributeDescriptionVO } from 'ui-api';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { IconTags } from 'components/icons';

import { useTrackExtensionsListViewed } from './ExtensionList';
import { usePromise } from '../../../utils/hooks/usePromise';
import HelpText from '../components/HelpText';
import { sort } from './utils';

export default function AttributeDefinitions(): ReactElement {
	const attributeDefinitionsResult = usePromise(() => Services.targets.fetchTargetAttributeDefinitions(), []);

	const [query, setQuery] = useState<string>('');
	const pageSize = 10;
	const [page, setPage] = useState(0);
	const sortedItems = attributeDefinitionsResult.value?.content
		? sort(search(attributeDefinitionsResult.value.content, query))
		: [];

	const items = sortedItems.slice(page * pageSize, (page + 1) * pageSize);
	const totalElements = sortedItems.length;
	const totalPages = Math.ceil(totalElements / pageSize);

	useTrackExtensionsListViewed(
		attributeDefinitionsResult.loading ? undefined : 'target_attributes',
		page,
		totalElements,
		totalPages,
	);

	return (
		<Stack m="xLarge">
			<HelpText>
				Attributes are used to describe the target. For example, Kubernetes deployment targets have a{' '}
				<Code inline>k8s.namespace</Code> attribute to describe in which namespace it is deployed. Attributes are
				defined through extensions leveraging our{' '}
				<Link
					href="https://github.com/steadybit/discovery-kit/blob/main/docs/discovery-api.md#target-attribute-description"
					external
				>
					DiscoveryKit
				</Link>
				.
			</HelpText>

			<Stack size={'xxSmall'}>
				<Container display="flex" alignItems="center" flexDirection="row-reverse" justifyContent="space-between">
					<ListHeaderSearch
						title="Search attribute"
						value={query ?? ''}
						setValue={(v) => {
							setQuery(v);
							setPage(0);
						}}
					/>
				</Container>

				<Table width="100%">
					<TableHead>
						<TableRow>
							<TableHeadCell>Attribute Name</TableHeadCell>
							<TableHeadCell>Label</TableHeadCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{attributeDefinitionsResult.loading && (
							<>
								<TableLoadingRow numColumns={2} />
								<TableLoadingRow numColumns={2} />
								<TableLoadingRow numColumns={2} />
							</>
						)}

						{items.map((definition) => (
							<TableRow hoverable key={definition.attribute}>
								<TableDataCell>{definition.attribute}</TableDataCell>
								<TableDataCell>{definition.label.one}</TableDataCell>
							</TableRow>
						))}
					</TableBody>
				</Table>

				{attributeDefinitionsResult.value && items.length === 0 && (
					<EmptyListContent
						icon={<IconTags variant="xxLarge" color="purple700" />}
						title={query ? 'No attributes matching your filter found' : 'No attributes found'}
					/>
				)}
			</Stack>

			<Pagination activePage={page} totalPages={totalPages} onClick={setPage} />
		</Stack>
	);
}

function search(items: TargetAttributeDescriptionVO[], query: string): TargetAttributeDescriptionVO[] {
	query = query.trim().toLowerCase();
	return items.filter((item) => {
		return item.attribute.toLowerCase().includes(query) || item.label.one.toLowerCase().includes(query);
	});
}
