/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

export const codeTypeOptions = [
	{ id: 'HTML', label: 'HTML' },
	{ id: 'MARKDOWN', label: 'Markdown' },
	{ id: 'IMAGE', label: 'Image only' },
];

interface CodeTypeSelectionProps {
	selectedCodeType: string;
	selectCodeType: (codeType: string) => void;
}

export default function CodeTypeSelection({ selectedCodeType, selectCodeType }: CodeTypeSelectionProps): ReactElement {
	return (
		<presets.dropdown.SingleChoiceButton
			selectedId={selectedCodeType}
			items={codeTypeOptions}
			size="small"
			onSelect={selectCodeType}
		>
			{codeTypeOptions.find((_codeType) => _codeType.id === selectedCodeType)?.label || 'Format'}
		</presets.dropdown.SingleChoiceButton>
	);
}
