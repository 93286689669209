/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Box, Colors, Dropdown, Flex, presets, Text, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement, useState } from 'react';

import Skeletons from '../../../components/Skeleton/Skeletons';

interface FilterDropDownProps {
	placeholder: string;
	loading: boolean;
	label: string;
	name: string;
	children: (p: {
		width: string | number | undefined;
		value: string;
		selectItem: (value: string) => void;
	}) => ReactElement;
	clearAll?: () => void;
}

export function FilterDropDown({
	placeholder,
	loading,
	label,
	name,
	clearAll,
	children,
}: FilterDropDownProps): ReactElement {
	const [groupFilter, setGroupFilter] = useState<string | null>(null);

	let content;
	if (loading) {
		content = (
			<Box
				align="center"
				style={{
					width: '100%',
					height: '37px',
					px: 'xSmall',
					border: '1px solid ' + Colors.neutral300,
					borderRadius: 'xxSmall',
				}}
			>
				<Skeletons height={24} widths={[100]} />
			</Box>
		);
	} else {
		content = (
			<Dropdown<HTMLInputElement>
				placement="bottom-start"
				renderDropdownContent={({ width, close }) => {
					return (
						<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="600px">
							{children({ value: groupFilter || '', width, selectItem: close })}
						</presets.dropdown.DropdownContentFrame>
					);
				}}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<TextInput
							ref={setRefElement}
							value={isOpen ? groupFilter || '' : label || ''}
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							onClear={
								clearAll
									? () => {
											clearAll();
											setGroupFilter(null);
										}
									: undefined
							}
							placeholder={placeholder}
							onClick={() => {
								setGroupFilter(null);
								setOpen(true);
							}}
							onChange={setGroupFilter}
						/>
					);
				}}
			</Dropdown>
		);
	}

	return (
		<Flex spacing="xxSmall" style={{ width: '100%' }}>
			<Text type="small" style={{ color: Colors.neutral600 }}>
				{name}
			</Text>
			{content}
		</Flex>
	);
}

export function FilterDropDownListWrapper({
	width,
	children,
}: {
	width: string | number | undefined;
	children: ReactElement;
}): ReactElement {
	return (
		<Flex
			style={{
				minWidth: width,
				py: 'xSmall',
				width: '100%',
				px: 'small',
			}}
		>
			{children}
		</Flex>
	);
}
