/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import axios from 'axios';

import { TenantsApi } from './tenantsApi';
import { ListResponse } from '../ui-api';

export class ExportApi {
	tenantApi: TenantsApi;

	constructor(tenantApi: TenantsApi) {
		this.tenantApi = tenantApi;
	}

	async fetchDatabaseTables(): Promise<ListResponse<string>> {
		return (await axios.get<ListResponse<string>>('/db/tables')).data;
	}

	async isDbExportAvailable(): Promise<boolean> {
		return Boolean((await axios.get<boolean>('/db/export-available')).data);
	}

	async isAmplitudeExportAvailable(): Promise<boolean> {
		return Boolean((await axios.get<boolean>('/ui/user-analytics/export-available')).data);
	}
}
