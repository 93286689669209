/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { presets } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import Labeled from './Labeled';

interface TagsProps {
	tags: string[];
}

export default function Tags({ tags }: TagsProps): ReactElement | null {
	if (!tags || tags.length === 0) {
		return null;
	}

	return (
		<Labeled title="Tags">
			<presets.pill.Tags appearance="template" tags={tags} />
		</Labeled>
	);
}
