/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import * as Sentry from '@sentry/react';

declare global {
	interface Window {
		INJECTED_SENTRY_ENVIRONMENT?: string;
		INJECTED_SENTRY_DSN?: string;
	}
}

export function init(): void {
	const environment = window.INJECTED_SENTRY_ENVIRONMENT;
	const dsn = window.INJECTED_SENTRY_DSN;

	if (environment && dsn) {
		Sentry.init({
			environment,
			dsn,
			release: `com.steadybit:platform-ui@${process.env.BUILD_VERSION}`,
			beforeSend(event) {
				const firstException = event.exception?.values?.[0];
				if (
					firstException?.type === 'AxiosError' &&
					firstException.value?.match(/Request failed with status code 40[13]/)
				) {
					return null;
				}

				// Sentry reports AxiosErrors from setTimeout instrumentation as unhandled, but they are returned as rejected promises
				// So we ignore them
				if (
					firstException?.type === 'AxiosError' &&
					firstException?.mechanism?.type === 'instrument' &&
					firstException?.mechanism?.data?.function === 'setTimeout'
				) {
					return null;
				}

				return event;
			},
		});
	}
}

export function track(event: string, data?: Record<string, unknown>): void {
	Sentry.withScope((scope) => {
		if (data) {
			scope.setExtras(data);
		}
		Sentry.captureMessage(event);
	});
}
