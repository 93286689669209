/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Colors, Dropdown, presets, TextInput } from '@steadybit/ui-components-lib';
import { CSSProperties, ReactElement, Ref } from 'react';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface TextProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Text({ value = '', setValue, ...props }: TextProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(value) => {
				setValue(value);
			}}
			{...props}
		/>
	);
}

interface InputProps {
	placeholder?: string;
	hasErrors?: boolean;
	sx?: CSSProperties;
	disabled: boolean;
	value: string;
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

export function Input({
	placeholder,
	hasErrors,
	disabled,
	value,
	sx,
	onChange,
	onFocus,
	onBlur,
}: InputProps): ReactElement {
	return (
		<Dropdown<HTMLDivElement>
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							onChange(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				let component;
				if (!isOpen) {
					component = (
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setOpen(true)}
						/>
					);
				} else {
					component = (
						<TextInput
							value={value}
							placeholder={placeholder}
							style={
								!hasErrors
									? {
											border: '1px solid ' + Colors.coral,
											outline: '1px solid ' + Colors.coral,
										}
									: {}
							}
							disabled={disabled}
							autoFocus
							onChange={onChange}
						/>
					);
				}

				return (
					<FocussableWrapper
						ref={setRefElement as Ref<HTMLDivElement>}
						onFocus={() => {
							setOpen(true);
							onFocus?.();
						}}
						onBlur={() => {
							onBlur?.();
						}}
						sx={sx}
						disabled={disabled}
					>
						{component}
					</FocussableWrapper>
				);
			}}
		</Dropdown>
	);
}
