/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { IconEnvironment, IconExperiment } from 'components/icons';
import { Container, Pill, Tooltip } from 'components';
import { Stack } from 'components/Stack';
import { ReactElement } from 'react';
import { VariableVO } from 'ui-api';

interface VariablesProps {
	scope: 'environment' | 'experiment';
	variables: VariableVO[];
	withHelpText?: boolean;
	onVariableClick: (variableKey: string) => void;
}

export default function Variables({ scope, variables, onVariableClick }: VariablesProps): ReactElement | null {
	if (variables.length === 0) {
		return null;
	}

	const Icon = scope === 'environment' ? IconEnvironment : IconExperiment;

	return (
		<Container px={'small'}>
			<Stack direction="vertical" size="xSmall" alignItems="flex-start">
				{variables.map((variable) => {
					return (
						<Tooltip key={variable.key} content={variable.value}>
							<div>
								<Pill
									backgroundColor="slateMidLight40p"
									backgroundColorOnHover="slateMidLight"
									color="neutral800"
									onClick={() => onVariableClick(variable.key)}
								>
									<Icon variant="small" mr="xxSmall" />
									{`{{${variable.key}}}`}
								</Pill>
							</div>
						</Tooltip>
					);
				})}
			</Stack>
		</Container>
	);
}
