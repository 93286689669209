/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { Container, Stack, hasError } from 'components';
import { useField, useFormikContext } from 'formik';
import { getGlobal } from 'utils/localStorage';
import { useUrlState } from 'url/useUrlState';
import { useTeam } from 'services/useTeam';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { ReactElement } from 'react';

import {
	adviceParam,
	colorConfigParam,
	colorOverridesParam,
	coloringParam,
	descriptionParam,
	environmentParam,
	groupingConfigsParam,
	groupingParam,
	nameParam,
	pathSegment,
	sizingParam,
} from '../urlParams';
import ArcadeHelpButton from '../ArcadeHelpButton';
import { LandscapeConfig } from '../types';
import ViewControls from './ViewControls';
import Description from './Description';

interface ConfigHeaderProps {
	onNeedHelpClick: () => void;
}

export default function ConfigHeader({ onNeedHelpClick }: ConfigHeaderProps): ReactElement {
	const showHelpButton = getGlobal('showArcadeOverlay') !== 'never';
	const team = useTeam();
	const [, meta] = useField('name');

	const { location } = useHistory();
	const formik = useFormikContext<LandscapeConfig>();
	const { environmentId, description, teamId, name, latestView } = formik.values;

	const readonly = teamId !== team.id;

	const formIsTouched = Object.keys(location.matrix[pathSegment]).length > 0;
	const renderViewControls: boolean =
		!team.userIsTeamMember ||
		readonly ||
		latestView.template ||
		!formik.values.isNew ||
		(formik.values.isNew && formIsTouched);

	const [, , updateUrlWithState] = useUrlState([
		groupingConfigsParam,
		colorOverridesParam,
		colorConfigParam,
		environmentParam,
		descriptionParam,
		coloringParam,
		groupingParam,
		adviceParam,
		sizingParam,
		nameParam,
	]);

	return (
		<Container
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				px: 'medium',
				py: 'xSmall',
				borderBottom: '1px solid ' + theme.colors.neutral300,
				height: '56px',
			}}
		>
			<Stack direction="horizontal" size="small" alignItems="center">
				<EditableLabel
					withTooltip={name.length > 27}
					disabled={readonly}
					value={name}
					placeholder="Name your view"
					hasError={hasError(meta) && !name}
					onChange={(value) => {
						formik.setFieldValue('name', value);
						updateUrlWithState({ name });
					}}
					width="100%"
				/>

				<Separator />

				<div style={{ maxWidth: 260 }}>
					<EnvironmentSelector
						selectedEnvironmentId={environmentId}
						isLandscapeView
						teamId={teamId}
						selectEnvironmentId={(id) => {
							formik.setFieldValue('environmentId', id);
							updateUrlWithState({ environment: id });
						}}
					/>
				</div>

				<Separator />
				<div style={{ maxWidth: 260 }}>
					<Description
						readonly={readonly}
						description={description}
						setDescription={(value) => {
							formik.setFieldValue('description', value);
							updateUrlWithState({ description: value });
						}}
					/>
				</div>
			</Stack>

			<Stack direction="horizontal" size="small" alignItems="center" minWidth="fit-content">
				<ArcadeHelpButton onClick={onNeedHelpClick} />
				{showHelpButton && renderViewControls && <Separator />}
				<ViewControls saveCopy={readonly || latestView.template} />
			</Stack>
		</Container>
	);
}

function Separator(): ReactElement {
	return <div style={{ width: 1, height: 24, backgroundColor: theme.colors.neutral300 }} />;
}
