/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, useState } from 'react';
import { Stack, Text } from 'components';
import { theme } from 'styles.v2/theme';

interface ScheduleCronTabProps {
	cronValid: boolean | undefined;
	cronError: string | undefined;
	disabled: boolean;
	cron: string;
	setCron: (cron: string) => void;
}

export default function ScheduleCronTab({
	cron,
	cronValid,
	cronError,
	disabled,
	setCron,
}: ScheduleCronTabProps): ReactElement {
	const [cronParts, setCronParts] = useState<string[]>(cron.split(' '));

	const widths: number[] = [0, 1, 2, 3, 4, 5, 6].map((i) => {
		const part = cronParts[i] || '';
		return Math.min(80, Math.max(44, 16 + 14 * part.length));
	});

	return (
		<Stack size="small" alignItems="center">
			<Stack alignItems="center">
				<Stack
					size="none"
					alignItems="center"
					sx={{
						bg: 'neutral100',
						borderRadius: 8,
						width: 444,
					}}
				>
					<Stack direction="horizontal" size="xSmall" alignItems="center">
						{[0, 1, 2, 3, 4, 5, 6].map((i) => {
							const part = cronParts[i] || '';

							return (
								<input
									key={i}
									id={`cron-part-${i}`}
									type="text"
									value={part}
									disabled={disabled}
									onChange={(e) => {
										let newValue = e.target.value;
										if (newValue.endsWith(' ')) {
											const nextInput = document.getElementById(`cron-part-${i + 1}`);
											if (nextInput) {
												nextInput.focus();
											}
										} else if (!newValue) {
											const nextInput = document.getElementById(`cron-part-${i - 1}`);
											if (nextInput) {
												nextInput.focus();
											}
										}

										newValue = newValue.trim();

										const newCronParts = [...cronParts];
										newCronParts[i] = newValue;
										setCronParts(newCronParts);
										setCron(newCronParts.join(' '));
									}}
									onKeyDown={(e) => {
										const caretPosition = e.currentTarget.selectionStart;
										if (caretPosition != null && e.key === 'ArrowLeft') {
											const cursorPositionAfterKey = caretPosition - 1;
											if (cursorPositionAfterKey === -1) {
												const prevInput = document.getElementById(`cron-part-${i - 1}`) as HTMLInputElement | null;
												if (prevInput) {
													prevInput.focus();
													prevInput.setSelectionRange(prevInput.value.length, prevInput.value.length);
												}
											}
										} else if (caretPosition != null && e.key === 'ArrowRight') {
											const cursorPositionAfterKey = caretPosition + 1;
											if (cursorPositionAfterKey > part.length) {
												const nextInput = document.getElementById(`cron-part-${i + 1}`) as HTMLInputElement | null;
												if (nextInput) {
													nextInput.focus();
													nextInput.setSelectionRange(0, 0);
												}
											}
										}

										if (e.key === 'Backspace') {
											const prevInput = document.getElementById(`cron-part-${i - 1}`);
											if (!part && prevInput) {
												prevInput.focus();
											}
										}
									}}
									onPaste={(e) => {
										try {
											const newParts = e.clipboardData.getData('text/plain').split(' ');
											const newCronParts = [...cronParts];
											newCronParts.splice(i, newParts.length, ...newParts);
											setCronParts(newCronParts);
											setCron(newCronParts.join(' '));

											e.preventDefault();
										} catch {
											// ignore
										}
									}}
									style={{
										maxWidth: widths[i],
										fontFamily: 'monospace !important',
										backgroundColor: 'transparent',
										border: '1px solid ' + (part ? theme.colors.slate : theme.colors.neutral300),
										color: theme.colors.neutral800,
										textAlign: 'center',
										fontSize: '24px',
										fontWeight: 'regular',
										paddingLeft: '0px',
										paddingRight: '0px',
										paddingTop: '12px',
										paddingBottom: '12px',
										borderRadius: '4px',
										outline: 'none',
									}}
								/>
							);
						})}
					</Stack>

					<Stack direction="horizontal" size="xSmall" alignItems="flex-start">
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[0] + 2}>
							second
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[1] + 2}>
							minute
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[2] + 2}>
							hour
						</Text>
						<Text variant="small" as="span" color="neutral600" textAlign="center" width={widths[3] + 2}>
							<Text variant="small" color="neutral600">
								day
							</Text>
							<Text variant="small" color="neutral600">
								(month)
							</Text>
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[4] + 2}>
							month
						</Text>
						<Text variant="small" as="span" color="neutral600" textAlign="center" width={widths[5] + 2}>
							<Text variant="small" color="neutral600">
								day
							</Text>
							<Text variant="small" color="neutral600">
								(week)
							</Text>
						</Text>
						<Text variant="smallCode" as="span" color="neutral600" textAlign="center" width={widths[6] + 2}>
							year
						</Text>
					</Stack>
				</Stack>
				<Text variant="mediumCode" textAlign="center" color="neutral600">
					{cronValid === true || (cronValid === false && cron === '') ? (
						<>&nbsp;</>
					) : cronValid === undefined ? (
						'validating...'
					) : cronError ? (
						cronError
					) : (
						'Invalid CronTab expression'
					)}
				</Text>
			</Stack>
		</Stack>
	);
}
