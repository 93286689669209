/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { from, interval, map, startWith, switchMap } from 'rxjs';

import { useObservable } from '../../../utils/hooks/useObservable';
import { ServerInfo } from '../../../services/serverInfoApi';
import { Services } from '../../../services/services';

const isUiOutdated$ = interval(1000 * 60).pipe(
	startWith(-1),
	switchMap(() => from(Services.serverInfo.fetchServerInfo())),
	map(isOutdated),
);

function isOutdated(serverInfo: ServerInfo | null): boolean {
	if (!process.env.BUILD_TIME || !process.env.BUILD_VERSION || !serverInfo?.build) {
		return false;
	}

	return serverInfo.build.version !== process.env.BUILD_VERSION || serverInfo.build.time !== process.env.BUILD_TIME;
}

export function useShouldUpdateUI(): boolean {
	const result = useObservable(() => isUiOutdated$, []);
	return Boolean(result?.value);
}
