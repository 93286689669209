/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { saveTemplate } from 'templates/components/formUtils';
import { suppressSubmitOnEnter } from 'utils/form';
import { useHistory } from 'url/hooks';
import { Form, Formik } from 'formik';

import PlaceholderExtractionAndCleanupJob from './PlaceholderExtractionAndCleanupJob';
import { PreparedFormData } from './TemplateEditorLoader';
import MetadataEnrichment from './MetadataEnrichment';
import ValidationHandler from './ValidationHandler';
import { TemplateFormValues } from './types';

interface TemplateEditorFormProps {
	formData: PreparedFormData;
	children: ReactNode;
}

export default function TemplateEditorForm({ formData, children }: TemplateEditorFormProps): ReactElement {
	const history = useHistory();

	// Use an effect to handle navigating back to the templates list after save,
	// as formic fields are still touched and need another react update cycle,
	// so that the unsaved changes prompt will not intervene.
	const [shouldRedirect, setShouldRedirect] = useState(false);
	useEffect(() => {
		if (shouldRedirect) {
			setShouldRedirect(false);
			history.replace('/settings/templates');
		}
	}, [shouldRedirect]);

	return (
		<Formik<TemplateFormValues>
			initialValues={{ ...formData, ...formData.initialValues }}
			initialErrors={{}}
			// see <ValidationHandler /> for docs
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			onSubmit={async (template: TemplateFormValues, { setValues, setTouched }) => {
				const savedTemplate = await saveTemplate(template);
				if (savedTemplate) {
					await setValues(savedTemplate);
					await setTouched({}, false);
					setShouldRedirect(true);
				}
			}}
		>
			{({ values }) => (
				<Form key={values.id} onKeyDown={suppressSubmitOnEnter} noValidate>
					<MetadataEnrichment />
					<ValidationHandler />
					<PlaceholderExtractionAndCleanupJob />
					{children}
				</Form>
			)}
		</Formik>
	);
}
