/*
 * Copyright 2022 steadybit GmbH. All rights reserved.
 */

import { ActionVO, ExperimentStepActionVO, MetricCheckVO, MetricQueryVO } from 'ui-api';
import { ExperimentFormValues } from 'pages/experiments/experiment';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from 'components';

import MetricQueries from './MetricQueries';
import MetricChecks from './MetricChecks';

interface MetricQuerySectionProps {
	step: ExperimentStepActionVO;
	disabled: boolean;
	stepPath: string;
	action: ActionVO;
}

export default function MetricQuerySection({
	disabled,
	stepPath,
	action,
	step,
}: MetricQuerySectionProps): ReactElement | null {
	const formik = useFormikContext<ExperimentFormValues>();
	const { setFieldValue } = formik;

	const saveQuery = (query: MetricQueryVO): void => {
		const currentQueries = step.metricQueries.slice();
		const match = currentQueries.find((q) => q.id === query.id);
		if (match) {
			currentQueries[currentQueries.indexOf(match)] = query;
		} else {
			currentQueries.push(query);
		}
		setFieldValue(`${stepPath}.metricQueries`, currentQueries, false);
	};

	const deleteQuery = (query: MetricQueryVO): void => {
		setFieldValue(
			`${stepPath}.metricQueries`,
			step.metricQueries.filter((q) => q.id !== query.id),
			false,
		);
	};

	const saveCheck = (check: MetricCheckVO): void => {
		const currentChecks = step.metricChecks.slice();
		const match = currentChecks.find((c) => c.id === check.id);
		if (match) {
			currentChecks[currentChecks.indexOf(match)] = check;
		} else {
			currentChecks.push(check);
		}
		setFieldValue(`${stepPath}.metricChecks`, currentChecks, false);
	};

	const deleteCheck = (check: MetricCheckVO): void => {
		setFieldValue(
			`${stepPath}.metricChecks`,
			step.metricChecks.filter((c) => c.id !== check.id),
			false,
		);
	};

	const metricQueryParameterDefinitionsResult = usePromise(
		() => Services.experiments.fetchMetricQueryParameters(action.id, step.parameters),
		[step.actionId],
	);

	const metricQueryParameterDefinitions = metricQueryParameterDefinitionsResult.value;
	if (!metricQueryParameterDefinitions) {
		return null;
	}

	return (
		<Stack size="medium" mt="small" mb="xxxLarge" px="medium">
			<MetricQueries
				metricQueryParameterDefinitions={metricQueryParameterDefinitions}
				queries={step.metricQueries}
				checks={step.metricChecks}
				saveQuery={saveQuery}
				deleteQuery={deleteQuery}
				disabled={disabled}
			/>
			<MetricChecks
				stepPath={stepPath}
				checks={step.metricChecks}
				queries={step.metricQueries}
				addNewCheck={() => saveCheck(createNewCheck(step.metricQueries))}
				saveCheck={saveCheck}
				deleteCheck={deleteCheck}
				disabled={disabled}
			/>
		</Stack>
	);
}

function createNewCheck(queries: MetricQueryVO[]): MetricCheckVO {
	return {
		id: uuidv4(),
		a: {
			type: 'metric',
			metric: {
				steadybit_metric_query_id: queries[0].id,
			},
		},
		condition: 'GTE',
		b: { type: 'scalar', value: 0 },
	};
}
