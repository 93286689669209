/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ReactComponent as Brand } from 'images/brand.svg';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';

declare global {
	interface Window {
		CUSTOM_IMAGE_LOADING?: string;
	}
}

export default function SplashLogo(): ReactElement {
	const customImage = window.CUSTOM_IMAGE_LOADING;
	if (customImage) {
		return (
			<Flex spacing="small" align="center">
				<img
					src={customImage}
					style={{
						width: 'fit-content',
						height: 'fit-content',
						maxWidth: '600px',
						maxHeight: '122px',
					}}
				/>

				<Flex spacing="none" align="center">
					<Text
						style={{
							color: theme.colors.slate,
							fontFamily: 'arial', //avoid flickering due to font loading
						}}
					>
						Powered by
					</Text>
					<Brand color="#0F054C" style={{ width: '134px' }} />
				</Flex>
			</Flex>
		);
	}

	return <Brand color="#0F054C" />;
}
