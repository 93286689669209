/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { IconClock, IconInformationCircleFilled, IconTarget, IconWarningCircle } from 'components/icons';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { ActionVO, BaseExperimentStepVOUnion, ExperimentStepActionVO } from 'ui-api';
import { convertDurationToSeconds, parseDuration, SECONDS } from 'utils/duration';
import { useBlastRadiusCount } from 'pages/experiments/components/utils';
import { UnitFieldUnit } from 'components/UnitField/UnitField';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { CSSProperties, ReactElement } from 'react';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { Tooltip } from 'components';

import { correctDropAnimation, WorkspaceStepMaker } from '../DragAndDropHandler';
import { ExperimentError, ExperimentFormValues } from '../types';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import { selectedStepIdParam, UrlState } from '../urlParams';
import useWorkspaceSettings from '../useWorkspaceSettings';
import { useEditorSettings } from '../useEditorSettings';
import StepTooltipContent from './StepTooltipContent';
import { ACTION_STYLES } from '../utils';
import useActions from '../useActions';
import './styles.css';

interface StepProps {
	stepIdToError: Map<string, ExperimentError[]>;
	step: BaseExperimentStepVOUnion;
	index: number;
}

export default function Step({ step, index, stepIdToError }: StepProps): ReactElement {
	const disabled = useIsExperimentDisabled();

	return (
		<Draggable key={step.id} draggableId={WorkspaceStepMaker + step.id} index={index} isDragDisabled={disabled}>
			{(dragProvided: DraggableProvided, dragSnapshot: DraggableStateSnapshot) => {
				return (
					<StepContent
						stepIdToError={stepIdToError}
						dragProvided={dragProvided}
						dragSnapshot={dragSnapshot}
						index={index}
						step={step}
					/>
				);
			}}
		</Draggable>
	);
}

function StepContent({
	stepIdToError,
	dragProvided,
	dragSnapshot,
	step,
}: StepProps & {
	dragProvided: DraggableProvided;
	dragSnapshot: DraggableStateSnapshot;
}): ReactElement {
	const { pxPerSecond } = useWorkspaceSettings();
	const { mode } = useEditorSettings();
	const stepErrors = stepIdToError.get(step.id) || [];

	const [{ selectedStepId }, , updateUrlWithState] = useUrlState<UrlState>([selectedStepIdParam]);
	const { actions } = useActions();

	let unit: UnitFieldUnit = SECONDS;
	let duration: number | string = 0;
	try {
		const result = parseDuration(step.parameters.duration);
		duration = result.value;
		unit = result.unit;
	} catch {
		// ignore
	}

	const durationInSeconds = convertDurationToSeconds(step.parameters?.duration) || 10;
	const width = `${Math.max(0, pxPerSecond * durationInSeconds - 2)}px`;

	const hasErrors = stepErrors.filter((err) => err.level === 'error').length > 0;
	const hasInfos = stepErrors.filter((err) => err.level === 'info').length > 0;

	const style = getStepStyle(step, hasErrors, hasInfos, actions);

	return (
		<div
			id={step.id}
			ref={dragProvided.innerRef}
			{...dragProvided.draggableProps}
			{...dragProvided.dragHandleProps}
			style={{
				...dragProvided.draggableProps.style,
				width,
				minWidth: width,
				maxWidth: width,
				paddingRight: '2px',
				...correctDropAnimation(dragSnapshot, dragProvided.draggableProps.style),
			}}
		>
			<div
				className="step"
				style={{
					...style,
					...getSelectedStyle(selectedStepId === step.id),
				}}
			>
				<Tooltip
					color="light"
					placement="top-start"
					content={<StepTooltipContent step={step} errors={stepErrors} />}
					boundedElementId="experiment.explorer.wrapper"
				>
					<div
						style={{
							position: 'relative',
							display: 'grid',
							gridTemplateRows: '1fr 1fr',
							gap: '2px',

							minHeight: '42px',
							maxHeight: '42px',
							padding: '3px 10px',

							color: theme.colors.neutral700,
						}}
						onClick={(e) => {
							// the workspace has a click listener that closes the sidebar
							e.stopPropagation();

							updateUrlWithState({ selectedStepId: step.id });
						}}
					>
						<Label step={step} color={style.color} />
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								overflow: 'hidden',
								gap: '4px',
							}}
						>
							{!!duration && <Duration duration={duration + unit.id} color={style.color} />}
							{mode === 'experiment' && <AdditionalInfo step={step} color={style.color} actions={actions} />}
						</div>
						{hasErrors && <ErrorIndicator />}
						{hasInfos && !hasErrors && <InfoIndicator />}
					</div>
				</Tooltip>
			</div>
		</div>
	);
}

export function Label({ step, color }: { step: BaseExperimentStepVOUnion; color?: string }): ReactElement {
	const actionId = step.type === 'action' ? step.actionId : undefined;
	const [actionName] = useAsyncState<string>(async () => {
		if (step.customLabel) {
			return step.customLabel;
		}
		if (actionId) {
			return await Services.actions.findActionNameWithTargetTypeIfNotUnique(actionId);
		}
		return Promise.resolve('Wait');
	}, [actionId, step.customLabel]);

	return (
		<span
			style={{
				...textEllipsis,
				fontSize: '13px',
				fontWeight: 600,
				color,
			}}
		>
			{actionName.value || ''}
		</span>
	);
}

function Duration({ duration, color }: { duration: string; color?: string }): ReactElement {
	return (
		<>
			<IconClock variant="xSmall" style={{ color }} />
			<span style={{ ...textEllipsis, fontSize: '13px', fontWeight: 500, marginRight: '4px', color }}>{duration}</span>
		</>
	);
}

function AdditionalInfo({
	step,
	color,
	actions,
}: {
	step: BaseExperimentStepVOUnion;
	color?: string;
	actions: ActionVO[];
}): ReactElement | null {
	if (step.type === 'wait') {
		return null;
	}
	const action = actions.find((a) => a.id === step.actionId);
	if (!action || !action?.target.type) {
		return null;
	}

	return <BlastRadiusCount step={step} color={color} action={action}></BlastRadiusCount>;
}

function BlastRadiusCount({
	step,
	color,
	action,
}: {
	step: ExperimentStepActionVO;
	color?: string;
	action: ActionVO;
}): ReactElement | null {
	const { environmentId, experimentVariables } = useFormikContext<ExperimentFormValues>().values;

	const blastRadius = step.blastRadius;
	const blastRadiusCount = blastRadius
		? useBlastRadiusCount(blastRadius, environmentId, experimentVariables, action.missingQuerySelection)
		: undefined;

	if (!blastRadius || !blastRadiusCount) {
		return null;
	}

	return (
		<>
			<IconTarget variant="xSmall" color={color} />
			<span style={{ ...textEllipsis, fontSize: '13px', fontWeight: 500, color }}>
				{blastRadiusCount.impacted === 1 && blastRadiusCount.total === 1 ? (
					1
				) : blastRadiusCount.impacted ? (
					<>
						{blastRadiusCount.impacted}
						{blastRadius.percentage || blastRadius.maximum ? `/${blastRadiusCount.total ?? '--'}` : null}
						{blastRadius.percentage ? ` (${blastRadius.percentage} %)` : null}
					</>
				) : (
					'--'
				)}
			</span>
		</>
	);
}

function ErrorIndicator(): ReactElement {
	return (
		<div
			style={{
				position: 'absolute',
				top: '-8px',
				left: '-8px',

				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',

				width: '16px',
				height: '16px',

				borderRadius: '50%',
				backgroundColor: 'white',
			}}
		>
			<IconWarningCircle variant="small" color="coral" />
		</div>
	);
}

function InfoIndicator(): ReactElement {
	return (
		<IconInformationCircleFilled
			color="experimentOther"
			style={{
				position: 'absolute',
				top: '-8px',
				left: '-8px',
				width: '16px',
				height: '16px',
			}}
		/>
	);
}

export function getStepStyle(
	step: BaseExperimentStepVOUnion,
	hasErrors: boolean,
	hasInfos: boolean,
	actions: ActionVO[],
): CSSProperties {
	if (step.type === 'wait') {
		return { ...ACTION_STYLES['wait'] };
	}
	const action = actions.find((a) => a.id === step.actionId);
	const type = step.type;
	const subType = step.actionId ? action?.kind : undefined;

	const styleKey = subType ? `${type}_${subType}` : type;

	const invalidStyleOverrides = hasErrors
		? ACTION_STYLES[`${styleKey}_invalid`]
		: hasInfos
			? ACTION_STYLES[`${styleKey}_info`]
			: undefined;

	return { ...ACTION_STYLES[styleKey], ...invalidStyleOverrides };
}

function getSelectedStyle(isSelected: boolean): CSSProperties {
	return isSelected
		? {
				border: '1px solid white',
				outline: `2px solid ${theme.colors.slate}`,
			}
		: {};
}
