/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { UnitFieldUnit } from 'components/UnitField/UnitField';
import { parseDuration, SECONDS } from 'utils/duration';
import { ReactElement } from 'react';

import { Options } from './Common';
import { Input } from './Integer';

interface DurationProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Duration({ value, hasErrors, disabled, setValue }: DurationProps): ReactElement {
	let unit: UnitFieldUnit = SECONDS;
	let duration: number | string = value;
	try {
		const result = parseDuration(value);
		duration = result.value;
		unit = result.unit;
	} catch {
		// ignore
	}

	let v: string = duration.toString();
	if (smellsLikeEnvVar(value) || smellsLikeTemplatePlaceholder(value)) {
		v = value;
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Input
				value={v}
				onChange={(newDuration) => {
					if (smellsLikeEnvVar(newDuration)) {
						setValue(String(newDuration));
					} else if (smellsLikeTemplatePlaceholder(newDuration)) {
						setValue(String(newDuration));
					} else {
						setValue(newDuration + unit.id);
					}
				}}
				hasErrors={hasErrors}
				disabled={disabled}
				withUnit
			/>
			<Options
				value={unit.id}
				options={[
					{ id: 'ms', label: 'MilliSec.' },
					{ id: 's', label: 'Seconds' },
					{ id: 'm', label: 'Minutes' },
				]}
				onChange={(v) => {
					setValue(duration + v);
				}}
				disabled={disabled}
			/>
		</div>
	);
}
