/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Flex, Text } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';
import { Brand } from 'images';

interface NavigationLogoProps {
	small?: boolean;
	customLogo?: string;
	customLogoSmall?: string;
}

export default function NavigationLogo({ small, customLogo, customLogoSmall }: NavigationLogoProps): ReactElement {
	if (customLogoSmall && small) {
		return (
			<div style={{ position: 'relative', width: '48px', height: '32px' }}>
				<img
					src={customLogoSmall}
					style={{
						width: 'fit-content',
						height: 'fit-content',
						maxWidth: '30px',
						maxHeight: '30px',
						padding: '0px 9px',
					}}
				/>
				<Flex
					justify="center"
					style={{
						position: 'absolute',
						top: '-6px',
						right: '-6px',
						width: '20px',
						height: '20px',
						backgroundColor: '#fff',
						borderRadius: 'large',
						overflow: 'hidden',
					}}
				>
					<Brand color="#fff" style={{ marginLeft: '3px', width: '64px' }} />
				</Flex>
			</div>
		);
	}
	if (customLogo && !small) {
		return (
			<Flex direction="horizontal" justify="spread" style={{ width: '240px', height: '32px', overflow: 'hidden' }}>
				<img
					src={customLogo}
					style={{
						width: 'fit-content',
						height: 'fit-content',
						maxWidth: '150px',
						maxHeight: '30px',
						padding: '0px 9px',
					}}
				/>
				<Flex spacing="none" align="center">
					<Text type="xSmall" style={{ color: '#fff' }}>
						Powered by
					</Text>
					<Brand color="#fff" style={{ width: '74px', height: '16px' }} />
				</Flex>
			</Flex>
		);
	}

	return (
		<Brand color={!small ? '#fff' : '#3B2F83'} width={134} style={{ padding: '0px 9px', transition: 'color 0.2s' }} />
	);
}
