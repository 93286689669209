/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, presets } from '@steadybit/ui-components-lib';
import MultiSelectList from 'components/Select/Dropdown/presets/MultiSelectList';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { UseSelectedLogLevelResult } from './useSelectedLogLevel';

export default function EventLogLevelFilter({
	selectedLogLevel,
	setSelectedLogLevel,
	availableLogLevel,
}: UseSelectedLogLevelResult): ReactElement | null {
	if (availableLogLevel.length === 0) {
		return null;
	}

	return (
		<Dropdown<HTMLButtonElement>
			renderDropdownContent={({ width }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<MultiSelectList
						selectedItemValues={selectedLogLevel}
						items={availableLogLevel.map((level) => ({ value: level, label: level }))}
						selectItem={(level) => setSelectedLogLevel([...selectedLogLevel, level.value])}
						removeSelectedItem={(level) =>
							setSelectedLogLevel(selectedLogLevel.filter((_level) => _level !== level.value))
						}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Button
						ref={setRefElement}
						type="secondary"
						size="small"
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						style={{
							width: '200px',
							outline: isOpen ? '2px solid ' + Colors.slate : undefined,
						}}
						onClick={() => setOpen(!isOpen)}
					>
						{selectedLogLevel.join(', ') || 'All'}
					</Button>
				);
			}}
		</Dropdown>
	);
}
